import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import LineTitle from '../../atom/LineTitle';
import AnimationInput from '../../molecule/AnimationInput';
import AnimationSearchInput from '../../molecule/AnimationSearchInput';
import InputGrid from '../../organism/InputGrid';
import subsidiaryStore from '../../store/subsidiaryStore';
import { MultiSearchInputStyle, PageStyle } from '../../Style';
import DragDropBox from '../../molecule/DragDropBox';
import { subsidiaryTopInputList, subsidiaryTopNoRouteInputList } from '../utility';
import txt from '../../Text';
import { getImgSource } from '../../utility/Utility';

const FlexStyle = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  position: relative;

  .halfFlex {
    width: 50%;
  }
`;
const ChangeButtonStyle = styled.div`
  height: 40px;
  width: calc(50% - 15px);
  background-color: var(--eciBlue);
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 80px;
  right: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export default function SubsidiaryInformationSection({ readOnly, noRoute }) {
  const store = subsidiaryStore();
  const {
    stateObj,
    setStateByKey,
    setsearchBoxTargetIndex,
    setsearchBoxType,
    searchBoxType,
    searchBoxTargetIndex,
  } = subsidiaryStore();
  const informationRef = useRef();
  //    제조사 인풋 관련
  const suppllierInputList =
    stateObj.produceOrganizationId &&
    stateObj.produceOrganizationId.map((item, i) => {
      return (
        <FlexStyle key={i}>
          <div className="halfFlex">
            <MultiSearchInputStyle key={i}>
              {!readOnly &&
                (i === stateObj.produceOrganizationId.length - 1 ? (
                  <span
                    className="plus"
                    onClick={() => {
                      if (
                        stateObj.produceOrganizationId[stateObj.produceOrganizationId.length - 1]
                          .id !== ''
                      ) {
                        setStateByKey('produceOrganizationId')([
                          ...stateObj.produceOrganizationId,
                          { id: '', name: '' },
                        ]);
                      }
                    }}
                  >
                    +
                  </span>
                ) : (
                  <span className="plus"> </span>
                ))}
              <AnimationSearchInput
                readOnly={readOnly}
                width="100%"
                height="40px"
                placeholder={txt.subsidiary.supplier}
                state={item.name || item.name2 || item.code1 || item.code2}
                onClick={() => {
                  setsearchBoxType('produceOrganizationId');
                  setsearchBoxTargetIndex(i);
                }}
                isNow={searchBoxType === 'produceOrganizationId' && searchBoxTargetIndex === i}
              />
            </MultiSearchInputStyle>
          </div>
          <div className="halfFlex">
            <AnimationInput
              readOnly={readOnly}
              width="100%"
              height="40px"
              placeholder={txt.subsidiary.supplierNumber}
              state={item.partNumber}
              setState={(val) => {
                const tmpArr = structuredClone(stateObj.produceOrganizationId);

                tmpArr[i].partNumber = val;
                setStateByKey('produceOrganizationId')(tmpArr);
              }}
            />
          </div>
        </FlexStyle>
      );
    });
  useEffect(() => {
    store.setinformationRef(informationRef);
  }, []);
  return (
    <PageStyle ref={informationRef}>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        Item Information
      </LineTitle>
      <InputGrid
        readOnly={readOnly}
        modulestore={store}
        inPutList={noRoute ? subsidiaryTopNoRouteInputList : subsidiaryTopInputList}
      />
      <DragDropBox
        htmlFor="itemThumb"
        preview
        width="100%"
        height="200px"
        setState={store.setStateByKey('thumbnail')}
        readOnly={readOnly}
        previewImage={
          stateObj.thumbnail.imageAddress && getImgSource(stateObj.thumbnail.imageAddress)
        }
      />
      <br />
      <br />

      <InputGrid
        readOnly={readOnly}
        modulestore={store}
        jsonDataState={stateObj.property}
        setjsonDataState={setStateByKey('property')}
      />
      <br />
      <br />
      {suppllierInputList}
    </PageStyle>
  );
}
