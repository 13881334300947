import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Title,
  Legend,
} from 'chart.js';
import PeriodSelectBox from './PeriodSelectBox';

const LineGraphStyle = styled.div`
  width: 100%;
  /* height: 100%; */
  height: 40vh;
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
`;

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip);
export default function LineGraph({ data, colorArr, periodObj = false }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'left',
        align: 'start',
      },
    },
    scale: {},
  };

  const lineData = Object.keys(data[0].val).map((key, i) => {
    const yearData = {};
    data.forEach((obj) => {
      yearData[obj.name] = obj.val[key];
    });
    return {
      label: key,
      data: yearData,
      borderColor: colorArr[i],
      backgroundColor: colorArr[i],
    };
  });
  return (
    <LineGraphStyle>
      {periodObj && (
        <PeriodSelectBox
          month={periodObj.month}
          year={periodObj.year}
          setmonth={periodObj.setmonth}
          setyear={periodObj.setyear}
          position
        />
      )}
      <Line
        width="150px"
        height="30px"
        options={options}
        data={{
          // labels: Object.keys(data[0].val),
          labels: [],
          datasets: lineData,
        }}
      />
    </LineGraphStyle>
  );
}
