import create from 'zustand';
import { defaultStoreSeed, stateObjSeed } from './defaultStore';

const PCBStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    classificationId: '',
    partNumber: '',
    name: '',
    specification: '',
    revision: '',
    masterNumber: '',
    'pcb-material': '', //  api는 material
    thickness: '',
    outerOz: '',
    plating: '', //  api는plating
    layer: '1', // 1,2,4,6,8,10,12
    innerOz1: '',
    innerOz2: '',
    innerOz3: '',
    innerOz4: '',
    innerOz5: '',
    innerOz6: '',
    innerOz7: '',
    innerOz8: '',
    innerOz9: '',
    innerOz10: '',
    psrColor: '',
    makingColor: '',

    buyerOrganizationId: '',
    moq: '',
    leadTime: '',
    grade: '',
    price: '',
    tentativePrice: '',
    approvedPrice: '',
    button: '',

    // size: "",
    width: '',
    height: '',
    arrayNumber: '',
    // arraySize: "",
    arrayWidth: '',
    arrayHeight: '',
    specialSpecification: '',
    // height: "",
    // arrayHeight: "",

    pcbProduceOrganizationId: [{ id: '', name: '' }],
    startDate: '',
    circuitDesign: '',
    pcbDesign: '',
    mpbaId: '',
  },

  isCircuitDesignValid: false,
  setisCircuitDesignValid: (val) => {
    set(() => ({ isCircuitDesignValid: val }));
  },
  isPcbDesignValid: false,
  setisPcbDesignValid: (val) => {
    set(() => ({ isPcbDesignValid: val }));
  },
  // attachment
  attachmentTagOptionList: [
    //  체크
    { id: 2, name: 'ETC' },
  ], // add default
  setattachmentTagOptionList: (val) => {
    set(() => ({ attachmentTagOptionList: val }));
  },

  PCBInformationRef: '',
  setPCBInformationRef: (val) => {
    set(() => ({ PCBInformationRef: val }));
  },

  getisConditionFullfill: (phase) => {
    let condition = true;
    const { isAttachmentValid, stateObj, isCircuitDesignValid, isPcbDesignValid } = get();
    const phaseObj = {
      1: !!stateObj.name && !!stateObj.partNumber,
      2: !!stateObj.tentativePrice && !!stateObj.approvedPrice,
      3: !!stateObj.circuitDesign && isCircuitDesignValid,
      4: !!stateObj.circuitDesign && !!stateObj.pcbDesign && isPcbDesignValid,
      5: true,
      6: true,
    };
    condition = !!isAttachmentValid && phaseObj[phase];
    return condition;
  },

  initModuleStore: () => {
    set(() => ({
      stateObj: {
        id: '',
        classificationId: '',
        partNumber: '',
        name: '',
        specification: '',
        revision: '',
        masterNumber: '',
        'pcb-material': '',
        thickness: '',
        outerOz: '',
        plating: '',
        layer: '1',
        innerOz1: '',
        innerOz2: '',
        innerOz3: '',
        innerOz4: '',
        innerOz5: '',
        innerOz6: '',
        innerOz7: '',
        innerOz8: '',
        innerOz9: '',
        innerOz10: '',
        psrColor: '',
        makingColor: '',

        buyerOrganizationId: '',
        moq: '',
        leadTime: '',
        grade: '',
        price: '',
        tentativePrice: '',
        approvedPrice: '',
        // size: "",
        width: '',
        height: '',
        // arraySize: "",
        arrayWidth: '',
        arrayHeight: '',
        arrayNumber: '',
        arraySize: '',
        specialSpecification: '',
        pcbProduceOrganizationId: [{ id: '', name: '' }],
        startDate: '',
        circuitDesign: '',
        pcbDesign: '',
        mpbaId: '',
        attachmentTagOptionList: [{ id: 2, name: 'ETC' }],
      },
      PCBInformationRef: '',
      isCircuitDesignValid: false,
      isPcbDesignValid: false,
    }));
  },
}));
export default PCBStore;

export const designAttachmentStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  attachmentTagOptionList: [
    //  체크
    { id: 2, name: 'ETC' },
  ],
  setattachmentTagOptionList: (val) => {
    set(() => ({ attachmentTagOptionList: val }));
  },
}));
