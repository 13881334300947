import React from 'react';
import styled, { keyframes } from 'styled-components';

const StatisticCircleBoxStyle = styled.div`
  width: ${(props) => props.size};
  height: calc(${(props) => props.size} + ${(props) => (props.noPadding ? 0 : 32)}px);
  background-color: white;
  position: relative;
  svg {
    background-color: transparent;
    transform: rotate(-90deg);
  }
  .background {
    stroke-dashoffset: 0;
    stroke-dasharray: 1200 1200;
  }

  .tagList {
    height: 60px;
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .total {
    width: 130px;
    height: 130px;
    padding-top: 35px;

    position: absolute;
    left: 50%;
    top: calc(50% + ${(props) => (props.noPadding ? 0 : 24)}px);
    transform: translate(-50%, -50%);
    border-radius: 1000px;
    color: ${(props) => props.centerColor};
    text-align: center;
    font-size: 20px;
    div {
      font-weight: 600;
      font-size: 24px;
    }
  }
  .title {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 14px;
    font-weight: 600;
    color: var(--textDarkGray);
  }

  padding-top: ${(props) => (props.noPadding ? '0' : '24px')};
`;
const grow = (strokeDashoffset, strokeDasharray) => keyframes`
  0%{
    stroke-dashoffset:${strokeDashoffset / 4};
    stroke-dasharray:${strokeDasharray}/2;
  }
  100%{
    stroke-dashoffset:${strokeDashoffset};
    stroke-dasharray:${strokeDasharray};
  }
`;
const OneCircle = styled.circle`
  animation: ${(props) => grow(props.strokeDashoffset, props.strokeDasharray)} 1.5s;
`;

const BadgeStyle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 1200px;
  background-color: white;
  box-shadow: 4px 8px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: ${(props) => props.y + (props.noPadding ? 0 : 24)}px;
  left: ${(props) => props.x}px;
  font-size: 20px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => props.opacity};
  .text {
    font-size: 10px;
    color: ${(props) => props.color};
  }
  .point {
    font-size: 14px;
    font-weight: 600;
  }
  transition: all 0.3s;
  :hover {
    transform: scale(110%) translate(-50%, -50%);
  }
`;
export default function StatisticCircleBox({
  dataObj,
  colorArr,
  isBadge,
  size = 300, // int
  centerText = 'Total',
  borderWeight = '14',
  centerColor = 'var(--textDarkGray)',
  title,
  noPadding,
}) {
  const pointArr = Object.values(dataObj);
  const nameArr = Object.keys(dataObj);
  const total = pointArr.reduce((acc, cum) => acc + cum);
  const sumArr = [0, ...pointArr];
  pointArr.forEach((point, i) => {
    sumArr[i + 1] = sumArr[i] + point;
  });
  const r = size / 2 - 40;
  const circleLength = r * 6.283;

  const circleList = pointArr.map((point, i) => (
    <OneCircle
      key={i}
      cx={size / 2}
      cy={size / 2}
      r={r}
      stroke={colorArr[i]}
      strokeWidth={borderWeight}
      fill="none"
      strokeDashoffset={-(circleLength * (sumArr[i] / total) + 10)}
      strokeDasharray={`${point <= 0 ? 0 : circleLength * (point / total) - 10} ${circleLength}`}
    />
  ));

  const badgeList = pointArr.map((point, i) => {
    const newR = r + borderWeight * 1;
    const zeta = (Math.PI * 2 * (sumArr[i] + point / 2)) / total;
    const pureX = Math.sin(zeta) * newR;
    const x = size / 2 + pureX;
    const minusCondition =
      (sumArr[i] + point / 2) / total < 3 / 4 && (sumArr[i] + point / 2) / total > 1 / 4;

    const y = size / 2 + (minusCondition ? 1 : -1) * Math.sqrt(newR * newR - pureX * pureX);

    return (
      <BadgeStyle
        key={i}
        x={x}
        y={y}
        color={colorArr[i]}
        noPadding={noPadding}
        opacity={point === 0 ? 0 : 1}
      >
        <div className="point text">{point}</div>
        <div className="text">{nameArr[i]}</div>
      </BadgeStyle>
    );
  });
  return (
    <StatisticCircleBoxStyle size={`${size}px`} centerColor={centerColor} noPadding={noPadding}>
      <div className="title">{title}</div>
      <div className="total">
        {centerText}
        <div>{total}</div>
      </div>

      <svg width={size} height={size}>
        <circle
          className="background"
          cx={size / 2}
          cy={size / 2}
          r={r}
          stroke="white"
          strokeWidth={borderWeight}
          fill="none"
        />
        {circleList}
      </svg>
      {isBadge && badgeList}
    </StatisticCircleBoxStyle>
  );
}
