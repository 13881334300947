import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LightGrayInputContainer, ListPageStyle } from '../../Style';

import TableIndexRow from '../../atom/TableIndexRow';
import PaginationTable from '../../molecule/PaginationTable';
import SearchBar from '../../molecule/SearchBar';
import ClassificationTreeSelector from '../../organism/ClassificationTreeSelector';
import TableRow from '../../atom/TableRow';
import usePageConditionList from '../../utility/hooks/usePageConditionList';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import { getTargetURI } from '../../utility/Utility';
import Input from '../../atom/Input';
import LockButton from '../molecule/LockButton';
import HistoryButton from '../molecule/HistoryButton';
import txt from '../../Text';

const LockItemListPageStyle = styled(ListPageStyle)`
  display: flex;
  justify-content: space-between;

  .right {
    width: 78%;
  }
`;

export default function LockItemListPage() {
  /// ---CONSt
  const tokenAxios = useTokenAxios();
  const navigate = useNavigate();
  /// ---MODEL
  const [classificationObject, setclassificationObject] = useState({
    id: '',
    name: '',
    value: '',
  });
  const [treeData, settreeData] = useState([]);

  //  리스트 가져오기
  const [pageCondition, setpageCondition, itemListData] = usePageConditionList(
    `item-classification`,
    'searchText',
    'classId'
  );
  /// ---ACTION
  /// ---VIEW

  const indexArray = txt.admin.lockPageIndex;
  const widthArray = [5, 5, 7, 5, 4, 8];
  const [reasonInputStateList, setreasonInputStateList] = useState([]);
  const itemRowList = itemListData?.map((item, i) => {
    const setreason = (val) => {
      setreasonInputStateList((state) => {
        const tmp = [...state];
        tmp[i] = val;
        return tmp;
      });
    };
    const sendLock = async () => {
      if (!reasonInputStateList[i]) {
        alert(`${item.lock ? 'Unlock' : 'Lock'} ${txt.admin.enterReason}`);
      } else {
        const formData = new FormData();
        // formData.append("lockReason", item.reasonInput);
        formData.append('lockReason', reasonInputStateList[i]);
        setreason('');
        const response = await tokenAxios.put(`lock/${item.id}`, formData);
        setpageCondition('searchText', ''); // 목록 다시 불러오기
      }
    };

    return (
      <TableRow
        key={i}
        onClick={(e) => {
          if (e.target.className === 'gridItem') {
            // 하위 아이템으로부터의 버블링 방지. 하위에서 stopPropagation이 사용 불가능한 상태라 이런 식으로 조건 걸어줌
            navigate(
              `/${getTargetURI(
                classificationObject.value,
                classificationObject.classification
              )}/detail/${item.id}?phase=4&classificationValue=${
                classificationObject.classification
              }&classificationName=${classificationObject.value}&classId=${
                classificationObject.classificationId
              }`
            );
          }
        }}
        itemArray={[
          item.number,
          item.name,
          item.classification,
          item.specification,
          item.produceOrganizationId[0]?.partNumber,
          <LightGrayInputContainer height="30px" width="200px" styleCss="cursor:pointer;">
            <div className="material-icons">edit</div>
            <Input
              width="80%"
              state={reasonInputStateList[i]}
              setState={(val) => setreason(val)}
              placeholder={txt.admin.reason}
            />
          </LightGrayInputContainer>,
          <LockButton isLock={item.lock} onClick={sendLock} />,
          <HistoryButton
            isLock={item.lock}
            historyList={item.lockReason?.map((reason, j) => ({
              lockReason: reason,
              lockTime: item.lockTime[j],
            }))}
          />,
        ]}
        widthArray={[5, 5, 7, 5, 4, 4, 2, 2]}
      />
    );
  });
  /// ---BL
  const getTreeData = async () => {
    const response = await tokenAxios.get('classification-tree');
    const tmpTree = response.data.result.data.filter(
      (obj) => obj.classification?.indexOf('3') === 0 // elec
    );
    tmpTree[tmpTree.findIndex((obj) => obj.classification === '3')].children = tmpTree[
      tmpTree.findIndex((obj) => obj.classification === '3')
    ].children.filter((obj) => obj.classification === '3/7');
    settreeData(tmpTree);
  };
  useEffect(() => {
    setpageCondition('classId', classificationObject.classificationId);
  }, [classificationObject]);

  useEffect(() => {
    getTreeData();
  }, []);
  useEffect(() => {
    if (itemListData[0]) {
      const tmpArr = new Array(itemListData.length);
      tmpArr.fill('');
      setreasonInputStateList(tmpArr);
    }
  }, [itemListData]);
  return (
    <LockItemListPageStyle>
      <ClassificationTreeSelector
        treeData={treeData}
        width="220px"
        height="60vh + 10px"
        title="Classification"
        state={classificationObject}
        setstate={setclassificationObject}
      />
      <div className="right">
        <div className="searchSection">
          <SearchBar
            width="280px"
            height="24px"
            placeholder="Search Items"
            state={pageCondition.searchText}
            setState={(val) => {
              setpageCondition('searchText', val);
            }}
          />
        </div>

        <PaginationTable
          width="100%"
          height="60vh"
          minHeight="300px"
          innerWidth="100%"
          totalPage={pageCondition.totalPage}
          rowsPerPageDefault={10}
          pageNum={pageCondition.pageNum}
          setRowsPerPage={(val) => {
            setpageCondition('rowsPerPage', val);
          }}
          rowsPerPage={pageCondition.rowsPerPage}
          setpageNum={(val) => {
            setpageCondition('pageNum', val);
          }}
          noshadow
        >
          <TableIndexRow itemArray={indexArray} widthArray={widthArray} width="100%" />
          {itemRowList}
        </PaginationTable>
      </div>
    </LockItemListPageStyle>
  );
}
