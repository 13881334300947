import create from 'zustand';
import { defaultStoreSeed, stateObjSeed } from './defaultStore';

const firmwareStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    classificationId: '',
    partNumber: '',
    name: '',
    revision: '',
  },

  phase: '1',
  setphase: (val) => {
    set(() => ({ phase: val }));
  },
  // attachment
  attachmentTagOptionList: [{ id: 2, name: 'ETC' }],
  setattachmentTagOptionList: (val) => {
    set(() => ({ attachmentTagOptionList: val }));
  },
  getisConditionFullfill: (phase) => {
    let condition = true;
    const { stateObj, isAttachmentValid } = get();

    condition = !!isAttachmentValid && !!stateObj.partNumber && (phase * 1 < 2 || !!stateObj.name);
    return condition;
  },
  initModuleStore: () => {
    set(() => ({
      stateObj: {
        classificationId: '',
        partNumber: '',
        name: '',
        revision: '',
      },
      attachmentTagOptionList: [{ id: 2, name: 'ETC' }],
      phase: '1',
    }));
  },
}));
export default firmwareStore;
