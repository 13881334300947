import React from 'react';
import styled from 'styled-components';

const DropdownContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const Select = styled.select`
  //width: 120px; /* 넓이를 조금 더 넓게 설정 */
  //height: 30px; /* 높이를 조금 더 크게 설정 */
  margin-left: 10px;
  padding: 5px 10px; /* 좌우 패딩을 추가하여 내용이 잘 보이게 설정 */
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 10px; /* 글씨 크기를 줄임 */
  line-height: 1.5; /* 줄 간격을 설정하여 가독성 향상 */
  background-color: #fff;
  color: #333;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

function Dropdown({ options, value, onChange }) {
  return (
    <DropdownContainer>
      <Select value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </DropdownContainer>
  );
}

export default Dropdown;
