import React, { useState } from 'react';
import styled from 'styled-components';
import RoundImgBox from '../atom/RoundImgBox';
import TableRow from '../atom/TableRow';
import { getImgSource } from '../utility/Utility';

const MemberRowStyle = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
`;
export default function MemberRow({
  data,
  setmemberArray,
  members,
  deletememberArray,
  isAllChecked,
}) {
  return (
    <MemberRowStyle>
      <TableRow
        widthArray={[1, 1, 2, 3, 3, 3]}
        onClick={() => {
          if ((members && members.indexOf(data.id) >= 0) || isAllChecked) {
            deletememberArray(data.id);
          } else {
            setmemberArray(data);
          }
        }}
        itemArray={[
          <input
            type="checkbox"
            value="1"
            checked={!!(members && members.indexOf(data.id) >= 0) || isAllChecked}
            // all 이라면-> 모든 members에 그 멤버가 포함되어있으면 checked
            onChange={() => {}}
          />,
          <RoundImgBox width="15px" height="15px" src={getImgSource(data.profileImage)} />,
          data.username,
          data.contact,
          data.department,
        ]}
      />
    </MemberRowStyle>
  );
}
