import create from 'zustand';
import { defaultStoreSeed, stateObjSeed } from './defaultStore';

const crStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    number: '',
    reason: '',
    reasonId: '',
    importance: '',
    sourceId: '',
    name: '',
    content: '',
    solution: '',
    affectedModuleId: {},
    classificationId: '', // init on page
  },
  isModal: false,
  setisModal: (val) => {
    set(() => ({ isModal: val }));
  },

  getisConditionFullfill: () => {
    const { stateObj, isAttachmentValid } = get();
    const result =
      !!isAttachmentValid &&
      !!stateObj.reasonId.id &&
      !!stateObj.reason &&
      !!stateObj.importance &&
      !!stateObj.sourceId &&
      !!stateObj.name &&
      !!stateObj.content &&
      !!stateObj.affectedModuleId.id;
    return result;
  },
  //  design attachment
  // attachment
  attachmentTagOptionList: [
    //  체크
    { id: 2, name: 'ETC' },
    { id: 1, name: '설계변경요청서' },
  ], // add default
  setattachmentTagOptionList: (val) => {
    set(() => ({ attachmentTagOptionList: val }));
  },

  initModuleStore: () => {
    set(() => ({
      stateObj: {
        number: '',
        reason: '',
        reasonId: '',
        importance: '',
        sourceId: '',
        name: '',
        content: '',
        solution: '',
        affectedModuleId: {},
        classificationId: '', // init on page
      },
    }));
  },
}));
export default crStore;
