import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LineTitle from '../../atom/LineTitle';
import AnimationSearchInput from '../../molecule/AnimationSearchInput';
import PCBStore from '../../store/PCBStore';
import TreeModal from '../../organism/TreeModal/TreeModal';
import CompareTable from '../molecule/CompareTable';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import ModalBox from '../../organism/ModalBox';
import Button from '../../atom/Button';

const CompareSectionStyle = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .blue {
      font-weight: 600;
      font-size: 14px;
      color: var(--eciBlue);
    }
  }
`;
export default function CompareSection() {
  const store = PCBStore();
  const [isModal, setisModal] = useState(false);
  const [isCompareModal, setisCompareModal] = useState(false);
  const [againstPCB, setagainstPCB] = useState({});
  const [compareData, setcompareData] = useState([]);
  const tokenAxios = useTokenAxios();
  const getCompareData = async () => {
    const response = await tokenAxios.get(`pcb-compare/${store.id}/${againstPCB.id}`);
    setcompareData(response.data?.result.data || []);
  };
  useEffect(() => {
    if (againstPCB.id) {
      getCompareData();
    }
  }, [againstPCB.id]);
  return (
    <CompareSectionStyle>
      <TreeModal
        url="pcb-compare"
        treeConfigure={(data) => {
          return data
            .filter((obj) => obj.name === 'Assembly')[0]
            .children[0].children.filter((obj2) => obj2.name === 'PCB');
        }}
        isActivate={isModal}
        setisActivate={setisModal}
        applyFunction={(finalItemList) => {
          setagainstPCB(finalItemList[0]);
        }}
        checkOneOnly
      />
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        Compare PCB Partlist
      </LineTitle>
      <br />
      <div className="container">
        <div className="blue">Compare</div>
        <AnimationSearchInput
          state={store.stateObj.partNumber || ''}
          width="50%"
          placeholder="Part Number"
          fontSize="14px"
          readOnly
        />
      </div>
      <div className="container">
        <div className="blue">Against</div>
        <AnimationSearchInput
          state={againstPCB.number || ''}
          width="50%"
          placeholder="Against Part Number"
          fontSize="14px"
          onClick={() => setisModal(true)}
        />
      </div>
      <br />
      <Button
        backgroundColor="var(--eciBlue)"
        width="100%"
        height="30px"
        color="white"
        onClick={() => setisCompareModal(true)}
        condition={!!againstPCB.id}
      >
        OK
      </Button>
      <ModalBox isActivate={isCompareModal} setisActivate={setisCompareModal}>
        <CompareTable
          compareData={compareData}
          name={store.stateObj.partNumber}
          compareName={againstPCB.number}
        />
      </ModalBox>
    </CompareSectionStyle>
  );
}
