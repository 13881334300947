import {
  appendAttachmentFormData,
  appendStateObj,
  deleteFormdataKey,
  setAttachmentArrays,
} from '../utility/Utility';
import useTokenAxios from '../utility/hooks/useTokenAxios';

export const appendBoardForm = (edit, store) => {
  const formData = new FormData();
  const { stateObj } = store;
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, ['classificationId', 'revision', 'thumbnail']);

  formData.append(
    'classificationId',
    stateObj.classificationId.id || stateObj.classificationId.classId
  );

  appendAttachmentFormData(formData, edit, store);

  return formData;
};
export const useGetBoardData = (id, boardstore) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/board/${id}`);
    const { data } = response.data.result;
    const { setStateObjWithData, deleteStateByKey, setStateByKey, setrouteNumber } = boardstore;
    // setstate
    setStateObjWithData(data);
    setStateByKey('partNumber')(data.number);
    setAttachmentArrays(data.attachmentList, boardstore);
    setrouteNumber(data.routeId);
  };
};
export const useGetBoardBomData = (id, store) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const { setStateObjWithData, setStateByKey, setpbaRowList, setfirmwareRowList } = store;
    const response = await tokenAxios.get(`/board/bom/${id}`);
    const { data } = response.data.result;
    // setstate
    //  set pbaRowList,firmwareRowList
    setpbaRowList(data?.item?.length < 1 ? [1] : data.item);
    setfirmwareRowList(data?.firmware?.length < 1 ? [1] : data.firmware);

    store.setrouteNumber(data.routeId || '');
  };
};
export const useSaveBoard = (id, store) => {
  const tokenAxios = useTokenAxios();

  const saveFunc = async () => {
    const { addedPbaRowList, addedFirmwareRowList, deletedFirmwareRowList } = store;
    const formData = new FormData();
    //  추가

    formData.append(
      'pbaIds',
      addedPbaRowList.map((item) => item.id)
    );
    formData.append(
      'firmwareIds',
      addedFirmwareRowList.map((item) => item.id)
    );
    formData.append(
      'deletedFirmwareIds',
      deletedFirmwareRowList.map((item) => item.id)
    );
    // deletedpba는 일단 생략
    const response = await tokenAxios.put(`board-add/${id}`, formData);
    store.setisRouteActive(true);
  };
  return saveFunc;
};
export const boardInputList = [
  {
    inputType: 'DB',
    name: 'Item Classification',
    requestName: 'classificationId',
    option: 1,
    choiceFields: [],
    gray: true,
    cell: 2,
    readOnly: true,
  },
  {
    inputType: 'KEY_IN',
    name: 'Board Part Number',
    requestName: 'partNumber',
    option: 0,
    choiceFields: [],

    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: 'Board Name',
    requestName: 'name',
    option: 0,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: '비고',
    requestName: 'note',
    option: 1,
    choiceFields: [],
    gray: false,
    readOnly: false,
    cell: 2,
  },
  {
    inputType: 'KEY_IN',
    name: 'Board Revision',
    requestName: 'revision',
    option: 0,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
];
