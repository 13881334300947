import create from 'zustand';
import { produce } from 'immer';
import { defaultStoreSeed, stateObjSeed } from './defaultStore';

const electronicPartStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    classificationId: '',
    produceOrganizationId: [{ id: '', name: '' }],
    type: '',
    itemNumber: '',
    name: '',
    diameter: '',
    tmpNumber: '',
    specification: '',
    buyerOrganizationId: '',
    moq: '',
    leadTime: '',
    grade: '',
    price: '',
    tentativePrice: '',
    approvedPrice: '',
    button: '',
    property: [], //  jsonobj
    whereUsed: [],
    //  library
    symbol: [], // []
    footPrint: [],
  },
  library: false,
  setlibrary: (val) => {
    set(() => ({ library: val }));
  },
  // test - point하드코딩
  isTestPoint: false,
  setisTestPoint: (val) => {
    set(() => ({ isTestPoint: val }));
  },
  isMech: false,
  setisMech: (val) => {
    set(() => ({ isMech: val }));
  },
  // 치환 가능 조건
  isSwitchable: false,
  setisSwitchable: (val) => {
    set(() => ({ isSwitchable: val }));
  },
  //  치환 phase
  isSwitching: false,
  setisSwitching: (val) => {
    set(() => ({ isSwitching: val }));
  },
  onSwitchButton: (newNumber) => {
    const { setStateByKey, stateObj } = get();
    setStateByKey('type')('1');
    //  newNumber구하는 규칙 확정
    setStateByKey('tmpNumber')(newNumber);

    set(() => ({ isSwitching: true }));
  },
  libraryPopupData: '',
  setlibraryPopupData: (val) => {
    set(() => ({ libraryPopupData: val }));
  },
  setSymbolFootprint: (type, item) => {
    const targetProp = get().stateObj[type];
    const { stateObj } = get();

    const setStateObj = get().setStateByKey(type);
    const targetIndex = targetProp.findIndex((itemObj) => itemObj.id === item.id);
    if (targetIndex > -1) {
      const tmpPropArr = [...targetProp];
      tmpPropArr.splice(targetIndex, 1);
      setStateObj(tmpPropArr);
    } else {
      if (type === 'symbol') {
        setStateObj([item]);
      }
      if (type === 'footPrint') {
        setStateObj([...targetProp, item]);
      }
    }
  },
  setsearchBoxPropertyForJson: (type, item, propIndex) => {
    const { stateObj } = get();
    const { property } = stateObj;
    const tmpProperty = structuredClone(property);
    tmpProperty[property.findIndex((obj) => obj.requestName === type)].content = item;
    set(() => ({
      stateObj: produce(stateObj, (draft) => {
        draft.property = tmpProperty;
      }),
    }));
  },

  getisConditionFullfill: (phase) => {
    const { stateObj, isAttachmentValid, isSwitching, isTestPoint, isMech } = get();
    const { property } = stateObj;
    const requiredJsonList = property?.filter((obj) => obj.option * 1 === 0) || [];
    if (isTestPoint) {
      return (
        requiredJsonList.every((obj) => !!obj.content) &&
        !!stateObj.name &&
        !!stateObj.produceOrganizationId[0]?.id
      );
    }

    const phaseObj = {
      1:
        (!!stateObj.type || stateObj.type === 0 || isMech) &&
        requiredJsonList.every((obj) => !!obj.content) &&
        !!stateObj.name &&
        !!stateObj.produceOrganizationId[0]?.id &&
        !!stateObj.produceOrganizationId[0]?.partNumber,
      2: true,
      3: !!stateObj.symbol && !!stateObj.footPrint[0],
      4:
        isSwitching ||
        (!!stateObj.symbol &&
          !!stateObj.footPrint[0] &&
          (!!stateObj.type || stateObj.type === 0) &&
          requiredJsonList.every((obj) => !!obj.content) &&
          !!stateObj.name &&
          !!stateObj.produceOrganizationId[0]?.id), // phase4에서 condition이 필요한 경우는 1. 임시->정식 치환 -> 항상true, 2. admin에서 수정 -> 모든 조건 다
    };

    return phaseObj[phase] && !!isAttachmentValid;
  },
  //  design attachment
  // attachment
  attachmentTagOptionList: [{ id: 2, name: 'ETC' }],
  setattachmentTagOptionList: (val) => {
    set(() => ({ attachmentTagOptionList: val }));
  },

  initModuleStore: () => {
    set(() => ({
      libraryPopupData: '',
      attachmentTagOptionList: [
        //  체크
        { id: 2, name: 'ETC' },
      ],
      stateObj: {
        id: '',
        classificationId: '',
        produceOrganizationId: [{ id: '', name: '' }],
        type: '',
        name: '',
        itemNumber: '',
        tmpNumber: '',
        diameter: '',
        button: '',
        specification: '',
        buyerOrganizationId: '',
        moq: '',
        leadTime: '',
        grade: '',
        price: '',
        tentativePrice: '',
        approvedPrice: '',
        property: [], //  jsonobj
        //  library

        symbol: [],
        footPrint: [],
      },
      isSwitching: false,
      isSwitchable: false,
      isTestPoint: false,
      isMech: false,
    }));
  },
}));
export default electronicPartStore;
