import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PageStyle, ListPageStyle, BlueButtonStyle } from '../../Style';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';

import PaginationTable from '../../molecule/PaginationTable';
import SearchBar from '../../molecule/SearchBar';
import usePageConditionList from '../../utility/hooks/usePageConditionList';
import txt from '../../Text';

export default function CrListPage() {
  const navigate = useNavigate();
  const [pageCondition, setpageCondition, crListData] = usePageConditionList('cr', 'searchText');
  const crRowList =
    crListData &&
    crListData.map((item, i) => {
      return (
        <TableRow
          width="1400px"
          key={i}
          onClick={() => {
            navigate(`page/detail/${item.crId}`);
          }}
          itemArray={[
            '',
            item.crNumber,
            item.crReason?.name,
            item.crImportance,
            item.crSource?.name,
            item.crName,
            item.member?.username,
            item.itemNumber,
            item.itemName,
            item.itemClassification,
            item.itemRevision,
            item.itemStatus,
          ]}
          widthArray={[2, 6, 6, 3, 5, 8, 4, 5, 7, 7, 5, 5]}
        />
      );
    });

  return (
    <PageStyle>
      <ListPageStyle>
        <div className="searchSection">
          <SearchBar
            width="400px"
            height="30px"
            placeholder="Search Items"
            state={pageCondition.searchText}
            setState={(val) => {
              setpageCondition('searchText', val);
            }}
          />
          <BlueButtonStyle
            height="max-content"
            width="200px"
            css="padding:5px 10px"
            onClick={() => {
              navigate('page/add/new');
            }}
          >
            <span className="material-icons">add</span>
            Create New CR
          </BlueButtonStyle>
        </div>
        <PaginationTable
          width="100%"
          minHeight="300px"
          height="60vh"
          innerWidth="1400px"
          totalPage={pageCondition.totalPage}
          rowsPerPageDefault={10}
          pageNum={pageCondition.pageNum}
          setRowsPerPage={(val) => {
            setpageCondition('rowsPerPage', val);
          }}
          rowsPerPage={pageCondition.rowsPerPage}
          setpageNum={(val) => {
            setpageCondition('pageNum', val);
          }}
          noshadow
        >
          <>
            <TableIndexRow
              itemArray={['', 'CR Info', 'CR Item']}
              widthArray={[2, 32, 29]}
              width="1400px"
            />
            <TableIndexRow
              itemArray={txt.crco.crListPageIndex}
              widthArray={[2, 6, 6, 3, 5, 8, 4, 5, 7, 7, 5, 5]}
              width="1400px"
            />
          </>

          {crRowList}
        </PaginationTable>
      </ListPageStyle>
    </PageStyle>
  );
}
