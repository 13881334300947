import React, { useEffect, useState } from 'react';
import useTokenAxios from './useTokenAxios';

export default function usePageConditionList(url, ...more) {
  //  url과 다른 조건들을 받아서
  //  다른 조건 : 기본 조건은 페이지 넘버, rowsperpage, totalpage.
  //  여기에 추가할 조건들을 넣는 형태
  // 다른 조건들은 searchText, isManager이 있음
  //  조건 객체, 조건 객체 변경 함수, 결과 리스트 값을 반환
  const tokenAxios = useTokenAxios();

  const [resultData, setresultData] = useState([]);
  const initObj = {
    pageNum: '0',
    rowsPerPage: '10',
    totalPage: '0',
  };
  if (more) {
    more.forEach((item) => {
      initObj[item] = '';
    });
  }

  const [conditionObject, setconditionObject] = useState(initObj);
  const changeCondition = (key, value) => {
    if (key === 'reRender') {
      setconditionObject((state) => ({
        ...state,
      }));
    } else {
      const tmpObj = { ...conditionObject };
      tmpObj[key] = value;
      setconditionObject(tmpObj);
    }
  };

  const getData = async () => {
    const response = await tokenAxios.get(
      `${url}${
        conditionObject.isManager
          ? '/management'
          : conditionObject.classId
            ? `/${conditionObject.classId}`
            : '-page'
      }?size=${conditionObject.rowsPerPage}&page=${
        conditionObject.pageNum
      }&search=${conditionObject.searchText ? conditionObject.searchText : ''}${
        conditionObject.status ? `&status=${conditionObject.status}` : ''
      }`
    );
    setresultData(response?.data?.content || response?.data?.result?.data || []);
    // totalPage설정
    conditionObject.totalPage = response.data.totalPages;
  };

  useEffect(() => {
    if (
      ('classId' in Object.keys(conditionObject) && conditionObject.classId > -1) ||
      !('classId' in Object.keys(conditionObject))
    ) {
      getData();
    }
  }, [conditionObject]);

  return [conditionObject, changeCondition, resultData];
}
