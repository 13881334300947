import commonStore from '../../store/commonStore';
import useTokenAxios from './useTokenAxios';

export default function useSaveFunction(
  url,
  appendFormData,
  modulestore,
  edit,
  editId,
  reviseId,
  callBack = () => {}
) {
  const tokenAxios = useTokenAxios();

  // url, formData추가 함수(appendFormData), store객체, edit여부를 받아서
  //  save명령을 보내는 save함수를 반환
  const { setisLoading, setloadingBoxType } = commonStore();

  const { setStateByKey, setrouteNumber, setisRouteActive, setid } = modulestore;
  //  각 store에 해당 속성들 꼭 있도록 확인
  const save = async () => {
    setloadingBoxType('ARROW');

    setisLoading(true);
    const formData = appendFormData(edit, modulestore);
    try {
      callBack();

      let response = '';
      if (edit && editId && editId !== 'new' && !reviseId) {
        response = await tokenAxios.put(`/${url}-end/${editId}`, formData);
        setrouteNumber(response.data.result.data.routeId || modulestore.routeNumber || '');
      } else {
        response = await tokenAxios.post(`/${url}${reviseId ? `/${reviseId}` : ''}`, formData);
      }
      if (response.data.result.data.routeId || response.data.result.data.routeId) {
        setrouteNumber(response.data.result.data.routeId || response.data.result.data.routeId);
      }
      setid(response.data.result.data.id);
      setisRouteActive(true);

      // alert("Done!");
      setisLoading(false);
      setloadingBoxType('SPINNER');
    } catch (err) {
      setisRouteActive(false);
      setisLoading(false);
      setloadingBoxType('SPINNER');

      throw new Error();
    }
  };
  return save;
}
