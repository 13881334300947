import React from 'react';
import styled from 'styled-components';
import commonStore from '../store/commonStore';

const LoadingBoxStyle = styled.div`
  display: ${(props) => (props.isLoading ? '' : 'none')};
  width: 100vw;
  height: calc(100vh - 80px);
  margin: 0 auto;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 10;
  background-color: ${(props) => (props.isDark ? 'rgba(4, 8, 15, 0.8)' : 'rgba(4, 8, 15, 0.5)')};
  .loadingBox {
    position: fixed;
    top: calc(50% - 200px);
    left: calc(50% - 200px);
    width: 400px;
    height: 400px;
    text-align: center;
    background-color: ${(props) => (props.isDark ? 'transparent' : 'white')};
    border-radius: 10px;
    color: var(--deemGray);
    z-index: 4;
    box-shadow: ${(props) => !props.isDark && '20px 0px 20px 0px #00000040'};
  }
`;
export default function LoadingBox({ children, isLoading, isDark }) {
  const commonstore = commonStore();
  return (
    <LoadingBoxStyle isLoading={isLoading} isDark={isDark}>
      <div className="loadingBox">{children}</div>
    </LoadingBoxStyle>
  );
}
