import create from 'zustand';
import { produce } from 'immer';

import { defaultStoreSeed, stateObjSeed } from './defaultStore';

const subsidiaryStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    classificationId: '',
    produceOrganizationId: [{ id: '', name: '' }],
    type: '',
    itemNumber: '',
    name: '',
    thumbnail: '',
    carTypeId: '',
    specification: '',
    description: '',
    property: [], // jsondata
  },
  setsearchBoxPropertyForJson: (type, item, propIndex) => {
    const { stateObj } = get();
    const { property } = stateObj;
    const tmpProperty = structuredClone(property);
    tmpProperty[property.findIndex((obj) => obj.requestName === type)].content = item;
    set(() => ({
      stateObj: produce(stateObj, (draft) => {
        draft.property = tmpProperty;
      }),
    }));
  },
  getisConditionFullfill: () => {
    let condition = true;
    const { stateObj, isAttachmentValid } = get();
    const requiredJsonList = stateObj.property?.filter((obj) => obj.option * 1 === 0) || [];

    condition =
      !!stateObj.name && !!isAttachmentValid && requiredJsonList.every((obj) => !!obj.content);

    return condition;
  },

  // attachment
  attachmentTagOptionList: [{ id: 2, name: 'ETC' }],

  initModuleStore: () => {
    set(() => ({
      stateObj: {
        id: '',
        classificationId: '',
        produceOrganizationId: [{ id: '', name: '' }],
        type: '',
        itemNumber: '',
        name: '',
        thumbnail: '',
        carTypeId: '',
        specification: '',

        property: [], // jsondata
      },
    }));
  },
}));
export default subsidiaryStore;
