import React from 'react';
import styled from 'styled-components';
import SelectBox from '../../atom/SelectBox';
import txt from '../../Text';

const PeriodSelectboxStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: ${(props) => props.position && 'absolute'};
  left: 45%;
  top: 10px;
  .text {
    color: var(--textDarkGray);
    font-size: 12px;
  }
`;
export default function PeriodSelectBox({
  month,
  year,
  setmonth,
  setyear,
  title = txt.dashBoard.period,
  position,
}) {
  const yearArr = new Array(50);
  yearArr.fill(1);
  const monthArr = new Array(12);
  monthArr.fill(1);
  return (
    <PeriodSelectboxStyle position={position}>
      <div className="text">{title}</div>
      <SelectBox
        height="24px"
        setState={setyear}
        backgroundColor="var(--lightGray)"
        color="var(--textDarkGray)"
        fontSize="11px"
        state={year}
        defaultValue={year}
      >
        {yearArr.map((s, i) => (
          <option key={i} value={i + 2000}>
            {i + 2000}
          </option>
        ))}
      </SelectBox>
      <SelectBox
        height="24px"
        setState={setmonth}
        backgroundColor="var(--lightGray)"
        color="var(--textDarkGray)"
        fontSize="11px"
        state={month}
        defaultValue={month}
      >
        {monthArr.map((s, i) => (
          <option key={i} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </SelectBox>
      <div className="text">~</div>
      <div className="text">
        {month * 1 === 1 ? year : year * 1 + 1}년 {((month * 1 + 10) % 12) + 1}
        {txt.dashBoard.month}
      </div>
    </PeriodSelectboxStyle>
  );
}
