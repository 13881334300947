import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CumulativeInput from '../../molecule/CumulativeInput';
import DoubleCheckInput from '../../molecule/DoubleCheckInput';
import LabelInput from '../../molecule/LabelInput';
import TableInput from '../../molecule/TableInput';
import { PageStyle, PropIndexStyle, PropModalStyle } from '../../Style';

import PropManageRow from '../molecule/PropManageRow';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import txt from '../../Text';
import ContextMenuTitle from '../../molecule/ContextMenuTitle';
import NewPropRow from '../molecule/NewPropRow';

const AdminPropPageStyle = styled(PageStyle)`
  display: flex;
  .left {
    min-width: 300px;
    width: 35%;
    margin-right: 40px;
    .index {
      display: flex;
      border-bottom: solid var(--eciBlue) 1px;
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
    }
    .propContainer {
      height: 50vh;
      background-color: white;
      overflow-y: scroll;
      border-radius: 0px 0px 5px 5px;
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border: 15px solid transparent;
        width: 5px;
        background: rgba(4, 8, 15, 0.3);
        border-radius: 8px;
      }
    }
  }
`;
export default function AdminPropPage() {
  const tokenAxios = useTokenAxios();

  const [modalType, setmodalType] = useState('');
  const [currentId, setcurrentId] = useState('');
  const [currentRequestName, setcurrentRequestName] = useState('');
  const [isWriting, setisWriting] = useState(false);
  const [propListData, setpropListData] = useState([]);
  const [rerenderFlag, setrerenderFlag] = useState(true);
  //  modalState
  const [cell, setcell] = useState(1);
  const [unit1, setunit1] = useState('');
  const [unit2, setunit2] = useState('');
  const [dropDownList, setdropDownList] = useState([{ id: '', name: '', isOk: '' }]);
  const [tableList, settableList] = useState([{ id: '', code: '', name: '' }]);
  const propList = propListData.map((obj, i) => {
    if (obj.id === 'new') {
      return <NewPropRow />;
    }
    return (
      <PropManageRow
        key={i}
        name={obj.name}
        type={obj.type}
        id={obj.id}
        isCurrent={obj.id === currentId}
        setcurrentId={setcurrentId}
        isContextMenuPossible={isWriting}
        setisContextMenuPossible={setisWriting}
        onClick={() => {
          setmodalType(obj.type);
          setcurrentRequestName(obj.requestName);
        }}
      />
    );
  });
  const tableDeleteFunc = async (obj) => {
    const response = await tokenAxios.delete(`${currentRequestName}/${obj.id}`);
    setrerenderFlag((flag) => !flag);
  };
  const dropDownDeleteFunc = async (id) => {
    const response = await tokenAxios.delete(`drop-down/${currentId}/${id}`);
    setrerenderFlag((flag) => !flag);
  };
  const apply = async () => {
    const formData = new FormData();
    if (modalType === 'DB') {
      formData.append('code', tableList[tableList.length - 1].code);
      formData.append('name', tableList[tableList.length - 1].name);
      await tokenAxios.post(`${currentRequestName}`, formData);
      setrerenderFlag((flag) => !flag);
      return 1;
    }
    if (modalType === 'DROP_DOWN') {
      formData.append('name', dropDownList[dropDownList.length - 1].name);
      await tokenAxios.post(`drop-down/${currentId}`, formData);
      setrerenderFlag((flag) => !flag);

      return 1;
    }

    formData.append('cell', cell);
    formData.append('unit1', unit1);
    formData.append('unit2', unit2);
    await tokenAxios.put(`property/${currentId}`, formData);
    setrerenderFlag((flag) => !flag);

    return 1;
  };

  const getModalData = async () => {
    if (modalType === 'DB') {
      const response = await tokenAxios.get(`${currentRequestName}?size=100&page=0&name=`);
      settableList(response.data.result.data.content); //  체크
      return 1;
    }
    if (modalType === 'DROP_DOWN') {
      const response = await tokenAxios.get(`drop-down/${currentId}`);
      setdropDownList(response.data.result.data);
      return 1;
    }
    const response = await tokenAxios.get(`property/${currentId}`);

    setcell(response.data.result.data.cell);
    setunit1(response.data.result.data.unit1);
    setunit2(response.data.result.data.unit2);
    return 1;
  };
  const getListData = async () => {
    const response = await tokenAxios.get('properties');
    setpropListData(response.data.result.data || []);
  };
  const addProp = () => {
    setpropListData((arr) => {
      const tmpArr = [...arr];
      tmpArr.push({ id: 'new' });
      return tmpArr;
    });
  };
  useEffect(() => {
    if (modalType && currentId) {
      getModalData();
    } else {
      setcell(1);
      setunit1('');
      setunit2('');
      setdropDownList([{ id: '', name: '', isOk: '' }]);
      settableList([{ id: '', code: '', name: '' }]);
    }
  }, [modalType, currentId, rerenderFlag]);
  useEffect(() => {
    getListData();
  }, []);
  return (
    <AdminPropPageStyle>
      <div className="left">
        <div className="index">
          <PropIndexStyle width="120px"> {txt.admin.propName}</PropIndexStyle>
          <PropIndexStyle width="120px"> {txt.admin.propInputType}</PropIndexStyle>
        </div>
        <ContextMenuTitle title={<div className="propContainer">{propList}</div>}>
          <div onClick={addProp}>속성 추가</div>
        </ContextMenuTitle>
      </div>
      {modalType && (
        <PropModalStyle>
          <div className="title">{txt.admin.editProp}</div>
          <div className="modal">
            {modalType !== 'DB' && modalType !== 'DROP_DOWN' && (
              <>
                <DoubleCheckInput
                  optionArr={[`1${txt.admin.block}`, `2${txt.admin.block}`]}
                  state={cell}
                  setstate={setcell}
                  title={txt.admin.blockNumber}
                />
                <br />
              </>
            )}
            {(modalType === 'UNIT_KEY_IN' || modalType === 'RANGE_KEY_IN') && (
              <>
                <LabelInput
                  state={unit1}
                  setstate={setunit1}
                  title={modalType === 'UNIT_KEY_IN' ? txt.admin.enterUnit : txt.admin.fromUnit}
                  height="30px"
                />
                <br />
              </>
            )}
            {modalType === 'RANGE_KEY_IN' && (
              <>
                <LabelInput
                  state={unit2}
                  setstate={setunit2}
                  title={txt.admin.toUnit}
                  height="30px"
                />
                <br />
              </>
            )}
            {modalType === 'DROP_DOWN' && (
              <>
                <br />
                <br />
                <br />
                <CumulativeInput
                  stateArr={dropDownList}
                  setstateArr={setdropDownList}
                  title={txt.admin.userChoiceList}
                  deleteFunc={dropDownDeleteFunc}
                />
                <br />
              </>
            )}
            {modalType === 'DB' && (
              <TableInput
                stateArr={tableList}
                setstateArr={settableList}
                indexArr={txt.admin.dbIndex}
                deleteFunc={tableDeleteFunc}
              />
            )}
          </div>
          <div className="buttonPart">
            <div
              className="actionButton cancel"
              onClick={() => {
                setcurrentId('');
                setmodalType('');
              }}
            >
              CANCEL
            </div>
            <div className="actionButton apply" onClick={apply}>
              APPLY
            </div>
          </div>
        </PropModalStyle>
      )}
    </AdminPropPageStyle>
  );
}
