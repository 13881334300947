import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Input from '../atom/Input';
import { LightGrayInputContainer } from '../Style';

const CumulativeInputStyle = styled.div`
  display: flex;
  justify-content: center;
  height: ${(props) => (props.height ? props.height : '40px')};
  .cumulativeTitle {
    font-size: 12px;
    font-weight: 600;
    color: var(--textDarkGray);
    margin-right: 20px;
  }
  .listContainer {
    transform: translate(0, -10px);
  }
  .add {
    color: var(--textDarkGray);
    font-size: 14px;
    cursor: pointer;
  }
`;
const InputContainer = styled(LightGrayInputContainer)`
  display: flex;
  align-items: center;
  width: 90%;
  .delete {
    color: var(--textGray);
    font-size: 20px;
    cursor: pointer;
  }
  margin-bottom: 16px;
`;
export default function CumulativeInput({
  stateArr,
  setstateArr,
  height = '40px',
  title,
  deleteFunc,
}) {
  const inputList =
    stateArr &&
    stateArr.map((item, i) => {
      return (
        <InputContainer key={i}>
          <Input
            height="30px"
            state={stateArr[i].name}
            placeholder="type here"
            setState={(val) => {
              const tmpArr = [...stateArr];
              tmpArr[i].name = val;
              setstateArr(tmpArr);
            }}
            fontSize="10px"
            width="100%"
            readOnly={item.id !== ''}
          />
          {item.id && (
            <div
              className="material-icons delete"
              onClick={() => {
                deleteFunc(item.id);
              }}
            >
              delete
            </div>
          )}
        </InputContainer>
      );
    });
  useEffect(() => {
    if (!stateArr[0] || stateArr[stateArr.length - 1].id !== '') {
      setstateArr((arr) => [...arr, { id: '', code: '', name: '' }]);
    }
  }, [stateArr]);
  return (
    <CumulativeInputStyle>
      <div className="cumulativeTitle">{title}</div>
      <div className="listContainer">{inputList}</div>
    </CumulativeInputStyle>
  );
}
