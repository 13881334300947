import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import TableIndexRow from '../../atom/TableIndexRow';
import ScrollController from '../../molecule/ScrollController';
import ModalBox from '../../organism/ModalBox';
import boardStore from '../../store/boardStore';
import { BlueButtonStyle, PageStyle, ScrollDefault, SectionGap } from '../../Style';
import ChildRow from '../../molecule/ChildRow';
import { useGetPBABomData, useGetPBAData } from '../utility';
import RouteSection from '../../organism/RouteSection';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import PBAStore from '../../store/PBAStore';
import ExportExcelButton from '../../organism/ExportExcelButton';

const BoardBomPageStyle = styled(PageStyle)`
  display: flex;

  width: 100%;
  .mainPart {
    margin-left: 24px;
    height: calc(100vh - 280px);
    width: calc(100% - 100px);
    ${ScrollDefault}
    .flex {
      display: flex;
      flex-shrink: 1;
      .rightIndex {
        width: 55vw;
        flex-shrink: 0;
      }
    }
  }
  .childRows {
    position: relative;

    padding-left: 12px;
    ::before {
      content: '';
      display: block;
      position: absolute;
      top: -20px;
      height: 100%;
      border-left: 2px solid var(--textGray);
    }
    .newPBA {
      cursor: pointer;
    }
  }
  .routeBox {
    width: 45%;
  }
`;
export default function PBABOMPage() {
  const tokenAxios = useTokenAxios();
  const [isFold, setisFold] = useState(false);
  const store = PBAStore();
  const params = useParams();
  const { type } = params;
  const getPBAData = useGetPBAData(params.id, store);
  const getBomData = useGetPBABomData(params.id, store);

  useEffect(() => {
    getPBAData();
    getBomData();
    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);

  const formatBomData = () => {
    const data = [];

    // PBA 자체 정보 추가
    data.push({
      Level: 0,
      Type: 'PBA',
      'Item Number': store.pbaRowList.number || '-',
      'Item Name': store.pbaRowList.name || '-',
      'Item Classification': store.pbaRowList.classificationId || '-',
      Specification: store.pbaRowList.specification || '-',
      Revision: store.pbaRowList.revision || '-',
    });

    // 하위 아이템들 추가
    const traverseBom = (item, level = 1) => {
      if (!item) return;

      data.push({
        Level: level,
        Type: item.type || '-',
        'Item Number': item.number || '-',
        'Item Name': item.name || '-',
        'Item Classification': item.classificationId || '-',
        Specification: item.specification || '-',
        Revision: item.revision || '-',
      });

      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => traverseBom(child, level + 1));
      }
    };

    if (store.pbaRowList.children) {
      store.pbaRowList.children.forEach((item) => traverseBom(item));
    }

    return data;
  };

  return (
    <BoardBomPageStyle childNum={store.pbaRowList.length}>
      <div className="mainPart">
        <div
          className="searchSection"
          style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}
        >
          <ExportExcelButton
            data={formatBomData()}
            title={`${store.pbaRowList.number || 'PBA'}-BOM.xlsx`}
          />
        </div>

        <div className="flex">
          <TableIndexRow
            widthArray={[6, 6]}
            itemArray={['', 'Item Number']}
            backgroundColor="white"
          />
          <div className="rightIndex">
            <TableIndexRow
              widthArray={[6, 6, 7, 8]}
              itemArray={['Item Name', 'Item Classification', 'Specification', 'Revision']}
              backgroundColor="white"
            />
          </div>
        </div>

        {!isFold && (
          <div className="childRows">
            <ChildRow
              widthArray={[7, 7, 8, 7, 3]}
              isAddButton={false}
              onClick={() => store.openModal('pba')}
              isFirst={false}
              key={0}
              border={!store.pbaRowList.id && 'solid 3px var(--textGray)'}
              backgroundColor="white"
              readOnly
              leftItemArray={
                store.pbaRowList?.id
                  ? ['PBA', store.pbaRowList.number]
                  : [
                      <span
                        className={type !== 'detail' && 'newPBA'}
                        onClick={() => type !== 'detail' && store.openModal('pba')}
                      >
                        {type !== 'detail' ? 'Click to add PBA' : 'no item'}
                      </span>,
                    ]
              }
              rightItemArray={
                store.pbaRowList?.id
                  ? [
                      store.pbaRowList.name,
                      store.pbaRowList.classificationId,
                      store.pbaRowList.specification,
                      store.pbaRowList.revision,
                      '',
                    ]
                  : ''
              }
              treeData={store.pbaRowList.children}
            />
          </div>
        )}
        <SectionGap />
        <div className="routeBox">
          {store.isRouteActive && (
            <RouteSection activate readOnly={type === 'detail'} moduleStore={store} />
          )}
        </div>
      </div>
    </BoardBomPageStyle>
  );
}
