import React from 'react';
import styled from 'styled-components';

const AttachmentWarningSignStyle = styled.div`
  width: 100%;
  min-height: 72px;
  border-radius: 10px;
  border: solid ${(props) => props.color} 2px;
  position: relative;
  margin-bottom: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  ::before {
    content: '';
    border-radius: 10px 0 0 10px;
    position: absolute;
    top: -2px;
    left: 0;
    height: calc(100% + 4px);
    width: 10px;
    background-color: ${(props) => props.color};
  }
  .err {
    color: ${(props) => props.color};
    font-size: 25px;
    margin: 0 12px;
  }
  .warningPart {
    div {
      color: var(--eciBlue);
    }
    .warning {
      font-weight: 600;
      font-size: 14px;
    }
    .file {
      text-decoration: underline;
    }
    .names {
      width: 100%;
      word-break: break-all;
      font-size: 13px;
    }
  }
`;
export default function AttachmentWarningSign({
  name,
  text = '파일을 반드시 업로드해야합니다. 미업로드시 router open이 제한됩니다.',
  color = 'rgba(253, 191, 77, 1)',
  title = 'Warning',
  backgroundColor = 'white',
}) {
  return (
    <AttachmentWarningSignStyle color={color} backgroundColor={backgroundColor}>
      <div className="material-icons err">error</div>
      <div className="warningPart">
        <div className="warning">{title}</div>
        <div className="names">
          <span className="file">{name} </span>
          {text}
        </div>
      </div>
    </AttachmentWarningSignStyle>
  );
}
