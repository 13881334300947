import React, { useState } from 'react';
import styled from 'styled-components';
import BomRow from '../atom/BomRow';
import TableRow from '../atom/TableRow';

// TODO ProductChildRow를 삭제하고 ChildRow로 편입 이를 위해서는 ProductBOM도 classificationId를 보여주는 것으로 통일해야한다.

const ChildRowStyle = styled.div`
  .flexPartt {
    display: flex;
    align-items: center;
    margin-top: 20px;
    > .leftA {
      width: 100%;
      flex-shrink: 1;
    }
    > .rightA {
      flex-shrink: 0;
      width: 55vw;
    }
    ::before {
      content: '';
      height: 0px;
      width: ${24}px;
      display: block;
      border-bottom: 1px solid var(--textDarkGray);
      opacity: ${(props) => (props.isLeftLine ? 1 : 0)};
      flex-shrink: 0;
      transform: translate(0, 4px);
    }
    .icon {
      color: var(--eciBlue);
      font-size: 28px;
      transform: rotate(${(props) => (props.isFold ? '-90deg' : '0')})
        translate(${(props) => (props.isFold ? '-8px' : '0')}, 0);
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      cursor: pointer;
      z-index: 1;
    }
    .plus {
      color: var(--eciBlue);
      font-size: 20px;
      cursor: pointer;
      width: 10px;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .gap {
      width: 20px;
      flex-shrink: 0;
      height: 20px;
    }
  }
  .tree {
    padding-left: 22px;
    position: relative;
    > ::before {
      content: '';
      position: absolute;
      top: -41px;
      height: calc(100% ${(props) => (props.isChild ? ' + 20px' : '')});
      width: 24px;
      display: block;
      border-left: 1px solid var(--textDarkGray);
    }
    > ::after {
      content: '';
      position: absolute;
      top: -41px;
      left: 0;
      height: calc(100% ${(props) => (props.isChild ? ' + 20px' : '')});
      width: 24px;
      display: block;
      border-left: 1px solid var(--lightGray);

      opacity: ${(props) => (props.isLastChild ? 1 : 0)};
    }
  }
`;
export default function ProductChildRow({
  backgroundColor,
  border,
  onClick,
  isFirst,
  readOnly,
  isLeftLine = true,
  isAddButton = true,
  rightWidth,
  leftItemArray = [],
  rightItemArray = [],
  treeData,
  depth = 0,
  isLastChild,
  widthArray = [7, 10, 10],
}) {
  const [isFold, setisFold] = useState(false);
  return (
    <ChildRowStyle
      isLeftLine={isLeftLine}
      depth={depth}
      isChild={!!treeData && !!treeData[0] && !isFold}
      isFold={isFold}
      isLastChild={isLastChild}
    >
      <div className="flexPartt">
        {treeData && treeData[0] ? (
          <div
            className="material-icons icon"
            onClick={() => {
              setisFold((t) => !t);
            }}
          >
            expand_more
          </div>
        ) : (
          <div className="gap" />
        )}
        {!readOnly && isAddButton ? (
          <div className="material-icons plus" onClick={onClick}>
            add
          </div>
        ) : (
          <div className="gap" />
        )}

        <div className="leftA">
          <BomRow leftColor="rgba(10, 0, 85, 0.5)" backgroundColor={backgroundColor}>
            <TableRow widthArray={[1, 2]} itemArray={leftItemArray} noBorder />
          </BomRow>
        </div>

        {rightItemArray && (
          <div className="rightA">
            <TableRow
              width="100%"
              widthArray={widthArray}
              height="50px"
              itemArray={rightItemArray}
              noBorder
              backgroundColor={backgroundColor}
              cssStyle={{}}
            />
          </div>
        )}
      </div>
      <div className="tree">
        {treeData &&
          treeData[0] &&
          !isFold &&
          treeData.map((treeObj, i) => (
            <ProductChildRow
              widthArray={widthArray}
              key={i}
              backgroundColor="var(--textGray)"
              border={border}
              isFirst
              readOnly
              isLeftLine
              rightWidth={rightWidth}
              leftItemArray={[treeObj.type, treeObj.number || treeObj.cardNumber]}
              rightItemArray={[
                treeObj.name || treeObj.cardName,
                treeObj.specification,
                treeObj.revision,
              ]}
              treeData={treeObj.children || treeObj.boardChildren || treeObj.itemChildren}
              depth={depth + 1}
              isLastChild={treeData.length - 1 === i}
            />
          ))}
      </div>
    </ChildRowStyle>
  );
}
