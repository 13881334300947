import { uriByClassificationObj } from './Source';

export const appendStateObj = (formData, stateObj) => {
  Object.keys(stateObj).forEach((key) => {
    if (stateObj[key] || stateObj[key] === 0) {
      formData.append(key, stateObj[key]);
    }
  });
  return formData;
};
export const deleteFormdataKey = (formData, deleteKeyArray) => {
  deleteKeyArray.forEach((key) => formData.delete(key));
  return formData;
};
// getData원형
export const setAttachmentArrays = (attachmentsArray, moduleStore) => {
  const {
    setattachmentFileArray,
    setattachmentIdArray,
    setattachmentCommentArray,
    setattachmentTagArray,
    setattachmentOriginArray,
    setattachmentDateArray,
    setattachmentUploaderArray,
    setdeletedFileIdArray,
    setattachmentModifiedAtArray,
  } = moduleStore;
  const tempfileArray = [];
  const temptagArray = [];
  const tempcommentArray = [];
  const temporiginArray = [];
  const tempuploaderArray = [];
  const tempdateArray = [];
  const tempidArray = [];
  const tempdeletedFileArray = [];
  const tempModifiedAtArray = [];
  if (attachmentsArray) {
    attachmentsArray.forEach((item) => {
      tempfileArray.push(item.originName);
      tempcommentArray.push(item.attach_comment);
      temptagArray.push(item.tag);
      temporiginArray.push(item.attachmentaddress);
      tempuploaderArray.push(item.upload);
      tempdateArray.push(item.date);
      tempidArray.push(item.id);
      if (item.deleted) {
        tempdeletedFileArray.push(item.id);
      }
      tempModifiedAtArray.push(item.modifiedAt ? item.modifiedAt : '');
    });

    setattachmentFileArray(tempfileArray);
    setattachmentIdArray(tempidArray);
    setattachmentCommentArray(tempcommentArray);
    setattachmentTagArray(temptagArray);
    setattachmentOriginArray(temporiginArray);
    setattachmentDateArray(tempdateArray);
    setattachmentUploaderArray(tempuploaderArray);
  }
  setdeletedFileIdArray(tempdeletedFileArray);
  setattachmentModifiedAtArray(tempModifiedAtArray);
};
export const appendAttachmentFormData = (formData, edit, moduleStore) => {
  const {
    attachmentFileArray,
    attachmentCommentArray,
    attachmentTagArray,
    deletedAttachmentArray,
    addedAttachmentArray,
  } = moduleStore;
  if (edit) {
    // edit attachment
    addedAttachmentArray.forEach((file) => {
      formData.append('addedAttachments', file);
    });

    formData.append('addedAttachmentComment', attachmentCommentArray);
    formData.append(
      'addedTag',
      attachmentTagArray.map((item) => (item.id ? item.id : item))
    );
    formData.append('deletedAttachments', deletedAttachmentArray);
  } else {
    // attachment
    const newAttachmentIndices = [];
    attachmentFileArray.forEach((file, index) => {
      if (typeof file !== 'string') {
        formData.append('attachments', file);
        newAttachmentIndices.push(index);
      }
    });

    if (newAttachmentIndices.length > 0) {
      // 새로운 첨부파일의 코멘트만 필터링
      const newComments = newAttachmentIndices.map((index) => attachmentCommentArray[index]);
      formData.append('attachmentComment', newComments);

      // 새로운 첨부파일의 태그만 필터링
      const newTags = newAttachmentIndices.map((index) => {
        const tag = attachmentTagArray[index];
        return tag.id ? tag.id : tag;
      });
      formData.append('tag', newTags);
    }
  }
};

export const getTargetURI = (valueStr, classificationIdStr) => {
  // name, a/b/c
  const valArr = valueStr.split('/');
  if (classificationIdStr) {
    const idArr = classificationIdStr.split('/');
    if (idArr[0] * 1 >= 4) {
      return 'item/subsidiary';
    }
  }
  const { length } = valArr;
  let target = 'invalidUri';
  for (let i = 0; i < length; i += 1) {
    if (uriByClassificationObj[valArr[length - i - 1]]) {
      target = uriByClassificationObj[valArr[length - i - 1]];
    }
  }
  return target;
};
export const getImgSource = (str) => {
  if (!str) {
    return false;
  }
  return `${process.env.REACT_APP_BASE_URL}${str}`;
};

export const getSymbolSource = (str) => {
  if (!str) {
    return false;
  }
  return str
    .replace('N:\\symbol\\', `${process.env.REACT_APP_BASE_URL}/symbol-image/`)
    .replace('N://symbol/', `${process.env.REACT_APP_BASE_URL}/symbol-image/`);
};
export const getFootprintSource = (str) => {
  if (!str) {
    return false;
  }
  return str
    .replace('N:\\geoms\\7201\\', `${process.env.REACT_APP_BASE_URL}/footprint-image/`)
    .replace('N://geoms/7201/2Layer/', `${process.env.REACT_APP_BASE_URL}/footprint-image/`);
};
