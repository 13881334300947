import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LineTitle from '../atom/LineTitle';
import SelectBox from '../atom/SelectBox';
import RouteCommentRow from '../molecule/RouteCommentRow';
import Button from '../atom/Button';
import { userStore } from '../store/commonStore';
import useTokenAxios from '../utility/hooks/useTokenAxios';
import txt from '../Text';

const RouteSectionStyle = styled.div``;
const RouteContainerStyle = styled.div`
  width: 100%;
  /* min-height: 800px; */
  margin-top: 20px;
  display: ${(props) => (props.activate ? '' : 'none')};
`;

export default function RouteSection({
  activate, //  isRouteActive
  readOnly,
  rejecting, // rejecting이 on이면 모든 라우트 카드들이 검은색으로, readonly상태로 제시됨
  moduleStore, // 해당 모듈의 스토어 객체
  multiRouteNumbers, //  라우트 넘버가 여럿일 경우
  routeArrayForAddingMember,
  callBack,
}) {
  /// ---CONST
  const tokenAxios = useTokenAxios();
  const routeRef = useRef();
  const navigate = useNavigate();

  /// ---MODEL
  const {
    setrouteRef,
    routeData,
    routeNumber,
    setrouteData,
    members,
    settargetMember,
    openMemberSearchBox,
    targetMember,
  } = moduleStore;
  const { userData } = userStore();

  const [workFlow, setworkFlow] = useState('work flow');
  const [newComment, setnewComment] = useState('');
  const [rejectTarget, setrejectTarget] = useState('');
  /// ---ACTION
  const sendAddMember = async () => {
    const formData = new FormData();
    formData.append('type', 3);
    formData.append('requestComment', newComment);
    if (callBack) {
      callBack(formData);
    }
    if (members && Array.isArray(members)) {
      // 첫 번째는 본인
      formData.append(
        `memberIds[0]`,
        (members[1] || members[0]).map((mem) => mem.id)
      );
    }
    tokenAxios
      .post(`/route/Item`, formData)
      .then((response) => {
        alert('Done !');
        navigate('/');
      })
      .catch((error) => {
        // 에러 응답이 있고, 상태 코드가 401인 경우
        if (error.response && error.response.status === 401) {
          // error.response.data.message를 출력하거나, 해당 메시지가 없을 경우 기본 메시지 출력
          alert(
            `${
              error.response.data.message ||
              'Item Purchase Related Properties 단계에는 구매담당자만 배정할 수 있습니다.'
            }`
          );
        } else {
          // 다른 모든 경우에 대한 에러 메시지
          alert(`Error: An unknown error occurred`);
        }
      });
  };
  const approveRoute = async () => {
    const formData = new FormData();
    if (multiRouteNumbers) {
      formData.append('routeIds', multiRouteNumbers);
    } else {
      formData.append('present', routeData.present);
    }
    formData.append('comment', newComment);

    const response = await tokenAxios.put(
      `/approveRoute${multiRouteNumbers ? '' : `/${routeNumber}`}`,
      formData
    );
    alert(txt.routeRegistered);
    navigate('/');
  };
  const rejectRoute = async () => {
    const formData = new FormData();
    formData.append('comment', newComment);
    formData.append('rejectedSequence', rejectTarget);
    const response = await tokenAxios.put(`/rejectRoute/${routeNumber}`, formData);
    alert(txt.routeRejected);
    navigate('/');
  };

  /// --- VIEW
  const routerList =
    activate && routeData?.routeProductList
      ? routeData.routeProductList.map((router, i) => {
          if (multiRouteNumbers && multiRouteNumbers.length > 1 && i === 0) {
            return '';
          }
          return (
            <RouteCommentRow
              key={i}
              title={router.name}
              member={router.member}
              present={routeData.present === router.sequence}
              inputState={routeData.present === router.sequence ? newComment : router.comment}
              setinputState={setnewComment}
              upperLine={i > 0}
              onApprove={approveRoute}
              onReject={rejectRoute}
              isPassed={router.sequence < routeData.present}
              isRefusal={router.refusal.seq !== -1}
              isRejected={router.rejected || rejecting}
              readOnly={readOnly}
              rejectTarget={router.refusal.seq !== -1 ? router.refusal.name : rejectTarget}
              setrejectTarget={setrejectTarget}
              rejectTargetArray={routeData.response.rejectPossibleIds}
            />
          );
        })
      : '';
  let routerListForAddingMember = [];
  if (routeArrayForAddingMember) {
    routerListForAddingMember = routeArrayForAddingMember.map((router, i) => {
      if (i === 0 && routeArrayForAddingMember.length > 1) {
        return (
          <RouteCommentRow
            init
            key={i}
            title={router}
            member={[
              {
                username: userData.username,
                department: userData.department,
                contact: userData.contact,
                email: userData.email,
              },
            ]}
            present={i === 0}
            inputState={newComment}
            setinputState={setnewComment}
            upperLine={i > 0}
          />
        );
      }
      return (
        <RouteCommentRow
          init
          key={i}
          title={router}
          member={members[i]}
          present={i === 0}
          inputState={newComment}
          setinputState={setnewComment}
          upperLine={i > 0}
          openMember={() => {
            settargetMember(i);
            openMemberSearchBox('members');
          }}
          highLight={i === targetMember}
        />
      );
    });
  }
  /// --- BL

  const getData = useCallback(async (itemID) => {
    const response = await tokenAxios.get(`route/${itemID}`);
    const { data } = response.data.result;
    if (data.response.rejectPossibleIds[0] && !readOnly) {
      setrejectTarget(data.response.rejectPossibleIds[0].seq);
    }
    setrouteData(data);
    setworkFlow(data.workflowphase);
  }, []);

  useEffect(() => {
    setrouteRef(routeRef);
    if (
      (routeNumber && routeNumber > 0) ||
      (multiRouteNumbers && multiRouteNumbers[0] && activate)
    ) {
      getData(routeNumber || multiRouteNumbers[0]);
    }
  }, [setrouteRef, getData, routeNumber]);

  return (
    <RouteSectionStyle ref={routeRef}>
      <LineTitle
        fontSize="16px"
        color={activate ? 'var(--eciBlue)' : 'var(--deemGray)'}
        width="100%"
        height="60px"
      >
        Route Information
      </LineTitle>
      <SelectBox
        width="100%"
        height="32px"
        fontSize="12px"
        color="var(--deemGray)"
        backgroundColor={activate ? 'white' : 'var(--disableGray)'}
        readOnly
        face="Workflow"
        state={workFlow}
      />
      <RouteContainerStyle activate={activate}>
        {routerList}
        {routerListForAddingMember[0] && routerListForAddingMember}
        <RouteCommentRow title="Complete" upperLine isCommentBox={false} complete member={[]} />
        {routeArrayForAddingMember && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={sendAddMember}
            condition={callBack ? routeArrayForAddingMember.length <= members.length : true}
          >
            Save and Route
          </Button>
        )}
      </RouteContainerStyle>
    </RouteSectionStyle>
  );
}
