import React, { useEffect } from 'react';
import { produce } from 'immer';

import AnimationInput from '../molecule/AnimationInput';
import AnimationSearchInput from '../molecule/AnimationSearchInput';
import AnimationSelectBox from '../molecule/AnimationSelectBox';
import UnitInput from '../molecule/UnitInput';
import { GridContainerStyle, GridItemContainerStyle, LabelInputStyle } from '../Style';

import LabelInput from '../molecule/LabelInput';
import DoubleCheckInput from '../molecule/DoubleCheckInput';
import SizeFixedInput from '../molecule/SizeFixedInput';
import AnimationDateInput from '../molecule/AnimationDateInput';

export default function InputGrid({
  readOnly,
  inPutList,
  modulestore,
  jsonDataState,
  setjsonDataState,
}) {
  const { searchBoxType, setsearchBoxType } = modulestore;
  const { stateObj, setStateByKey, initStateObj } = modulestore || {
    stateObj: () => {},
    setStateByKey: () => {},
    initStateObj: () => {},
  };

  const generateInput = (item, i, readonly) => {
    //  inputOb를 받아서 해당하는 인풋 생성

    //   inputList Item 견본
    //   {
    //     inputType: "",
    //     name: "PCB Part Number", unit은 "가로 세로", placeholder에 해당
    //     requestName: "PCB Part Number", 별칭
    //     required: 1,
    //     choiceFields: [], choice인 경우 option에 들어감
    //     gray: true,
    //     cell:1,
    //     unit:"mm",
    //     title: "title" unit input 앞에 들어갈 텍스트
    //     readOnly
    //   },

    let resultInput;

    const setStateForJson = (val) => {
      const tmpArr = produce(jsonDataState, (draft) => {
        draft[i].content = val;
      });

      setjsonDataState(tmpArr);
    };
    const setStateForJson2 = (val) => {
      const tmpArr = produce(jsonDataState, (draft) => {
        draft[i].content2 = val;
      });

      setjsonDataState(tmpArr);
    };
    const state = jsonDataState
      ? jsonDataState[i]?.content?.name ||
        jsonDataState[i]?.content?.name2 ||
        jsonDataState[i]?.content?.name1 ||
        jsonDataState[i]?.content?.code1 ||
        jsonDataState[i]?.content
      : stateObj[item.requestName]?.value ||
        stateObj[item.requestName]?.name ||
        stateObj[item.requestName]?.name2 ||
        stateObj[item.requestName]?.code1 ||
        (typeof stateObj[item.requestName] === 'object' ? ' ' : stateObj[item.requestName]);
    const state2 = jsonDataState
      ? jsonDataState[i]?.content2?.name ||
        jsonDataState[i]?.content2?.name2 ||
        jsonDataState[i]?.content2?.name1 ||
        jsonDataState[i]?.content2?.code1 ||
        jsonDataState[i]?.content2
      : stateObj[item.requestName2 || `${item.requestName}2`]?.name || // requestName2를 따로 지정 안할 경우 어거지로 생성
        stateObj[item.requestName2 || `${item.requestName}2`]?.name2 ||
        stateObj[item.requestName2 || `${item.requestName}2`]?.code1 ||
        stateObj[item.requestName2 || `${item.requestName}2`];

    const setstate = jsonDataState ? setStateForJson : setStateByKey(item.requestName);

    const setstate2 = jsonDataState ? setStateForJson2 : setStateByKey(item.requestName2);
    switch (item.inputType) {
      case 'KEY_IN': {
        resultInput = (
          <GridItemContainerStyle size={item.cell} key={i}>
            <AnimationInput
              readOnly={item.readOnly || readonly}
              width="100%"
              height="40px"
              placeholder={item.name}
              state={state}
              setState={setstate}
              required={item.option === 0}
              backgroundColor={
                item.backgroundColor ? item.backgroundColor : item.gray && 'var(--textGray)'
              }
            />
          </GridItemContainerStyle>
        );

        break;
      }
      case 'UNIT_KEY_IN': {
        resultInput = (
          <GridItemContainerStyle size={item.cell} key={i}>
            <LabelInputStyle>
              <div className="title" title={item.name}>
                {item.name}
              </div>
              <UnitInput
                readOnly={item.readOnly || readonly}
                width="100%"
                height="40px"
                placeholder={item.name}
                state={state}
                setState={setstate}
                required={item.option === 0}
                optionArray={
                  item.choiceFields[0]
                    ? item.choiceFields
                    : [
                        {
                          value: item.unit1 || item.unit2,
                          name: item.unit1 || item.unit2,
                        },
                      ]
                }
                backgroundColor={
                  item.backgroundColor ? item.backgroundColor : item.gray && 'var(--textGray)'
                }
              />
            </LabelInputStyle>
          </GridItemContainerStyle>
        );
        break;
      }
      case 'RANGE_KEY_IN': {
        resultInput = (
          <GridItemContainerStyle size={2} key={i}>
            <SizeFixedInput
              readOnly={item.readOnly || readonly}
              width="100%"
              height="40px"
              title={item.name}
              placeholder1={item.placeholder1 || ' '}
              placeholder2={item.placeholder2 || ' '}
              unit1={item.unit1}
              unit2={item.unit2 || item.unit1}
              state={state}
              setState={setstate}
              state2={state2}
              setState2={setstate2}
              required={item.option === 0}
              centerStr={item.centerStr || '~'}
            />
          </GridItemContainerStyle>
        );
        break;
      }
      case 'DB': {
        resultInput = (
          <GridItemContainerStyle size={item.cell} key={i}>
            <AnimationSearchInput
              readOnly={item.readOnly || readonly}
              width="100%"
              height="40px"
              placeholder={item.name}
              state={state}
              onClick={() => {
                //   setisClassificationBox(false);
                // if (item.searchType === "data") {
                //   setdataSearchBoxType(
                //     `${item.requestName}${jsonDataState ? "-J-" : ""}`
                //   );
                // } else if (item.searchType === "category") {
                //   setcategorySearchBoxType(
                //     `${item.requestName}${jsonDataState ? "-J-" : ""}`
                //   );
                // } else {
                setsearchBoxType(`${item.requestName}${jsonDataState ? '-J-' : ''}`);
                // }
              }}
              isNow={
                item.requestName === searchBoxType
                // ||item.requestName === dataSearchBoxType
              }
              required={item.option === 0}
              backgroundColor={
                item.backgroundColor ? item.backgroundColor : item.gray && 'var(--textGray)'
              }
            />
          </GridItemContainerStyle>
        );
        break;
      }
      case 'label': {
        resultInput = (
          <GridItemContainerStyle size={item.cell} key={i}>
            <LabelInput
              readOnly={item.readOnly || readonly}
              width="100%"
              height="40px"
              title={item.name}
              state={state}
              setstate={setstate}
              required={item.option === 0}
              backgroundColor={
                item.backgroundColor
                  ? item.backgroundColor
                  : item.gray
                    ? 'var(--textGray)'
                    : 'white'
              }
              titleColor="var(--eciBlue)"
            />
          </GridItemContainerStyle>
        );
        break;
      }
      case 'check': {
        resultInput = (
          <GridItemContainerStyle size={item.cell} key={i}>
            <DoubleCheckInput
              readOnly={item.readOnly || readonly}
              width="100%"
              height="40px"
              title={item.name}
              state={state}
              optionArr={item.choiceFields}
              setstate={setstate}
              backgroundColor={
                item.backgroundColor ? item.backgroundColor : item.gray && 'var(--textGray)'
              }
              titleColor="var(--eciBlue)"
            />
          </GridItemContainerStyle>
        );
        break;
      }
      case 'date': {
        resultInput = (
          <GridItemContainerStyle size={item.cell} key={i}>
            <AnimationDateInput
              readOnly={item.readOnly || readonly}
              width="100%"
              height="40px"
              title={item.name}
              state={state}
              setState={setstate}
              required={item.option === 0}
              placeholder={item.name}
              backgroundColor={
                item.backgroundColor ? item.backgroundColor : item.gray && 'var(--textGray)'
              }
              titleColor="var(--eciBlue)"
            />
          </GridItemContainerStyle>
        );
        break;
      }

      case 'DROP_DOWN': {
        const optionList =
          item.choiceFields &&
          item.choiceFields.map((optionObject, j) => {
            return (
              <option key={j} value={optionObject.value || optionObject.name}>
                {optionObject.name}
              </option>
            );
          });
        resultInput = (
          <GridItemContainerStyle size={item.cell} key={i}>
            <AnimationSelectBox
              readOnly={item.readOnly || readonly}
              width="100%"
              height="40px"
              placeholder={item.name}
              state={state}
              setState={setstate}
              required={item.option === 0}
              backgroundColor={
                item.backgroundColor ? item.backgroundColor : item.gray && 'var(--textGray)'
              }
            >
              {optionList}
            </AnimationSelectBox>
          </GridItemContainerStyle>
        );
        break;
      }

      default:
        resultInput = (
          <GridItemContainerStyle size={item.cell} key={i}>
            <div> </div>{' '}
          </GridItemContainerStyle>
        );

        break;
    }
    return resultInput;
  };

  useEffect(() => {
    if (!jsonDataState && inPutList) {
      initStateObj(inPutList);
    }
  }, []);
  return (
    <GridContainerStyle>
      {jsonDataState
        ? jsonDataState.map((item, i) => generateInput(item, i, readOnly))
        : inPutList && inPutList.map((item, i) => generateInput(item, i, readOnly))}
    </GridContainerStyle>
  );
}
