import React from 'react';
import styled from 'styled-components';

const LegendStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
  width: ${(props) => props.width};
  .round {
    border-radius: 1000px;
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.color};
  }
  .txt {
    font-size: 12px;
    color: var(--textDarkGray);
  }
`;
export default function Legend({ color, text, width }) {
  return (
    <LegendStyle color={color} width={width}>
      <div className="round" />
      <div className="txt">{text}</div>
    </LegendStyle>
  );
}
