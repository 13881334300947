import create from 'zustand';
import txt from '../Text';

export const adminModuleNavigationListObj = {
  item: [
    // XXX : 삭제하지말고 나중에 커스터마이징할 때 사용
    // {
    //   to: "/admin/item/itemClassification",
    //   name: txt.admin.classificationManagement,
    // },
    // { to: "/admin/item/property", name: txt.admin.manageProperty },

    { to: '/admin/item/editList', name: txt.admin.manageItem },
    { to: '/admin/item/lock', name: 'Item Lock' },
    // { to: "/admin/item/specification", name: "specification" },
  ],
  members: [{ to: '/admin/member/manage-member', name: txt.admin.manageMember }],
  library: [
    { to: '/admin/library/symbol', name: 'Symbol' },
    { to: '/admin/library/footPrint', name: 'Footprint' },
    // XXX : 삭제하지말고 나중에 커스터마이징할 때 사용
    // { to: "/admin/library/cadLibrary", name: txt.admin.cadLibraryManagement },
  ],
};
