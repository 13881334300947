import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ScrollContainer from '../../organism/ScrollContainer';
import AttachmentSection from '../../organism/AttachmentSection';
import Button from '../../atom/Button';
import RouteInitSection from '../../organism/RouteInitSection';

import RouteSection from '../../organism/RouteSection';

import { PageStyle, SectionGap } from '../../Style';
import { conditionByTypeObject } from '../../utility/Source';

import { appendReleaseForm, useGetReleaseData } from '../utility';
import releaseStore from '../../store/releaseStore';
import ReleaseInformationSection from '../organism/ReleaseInformationSection';
import ReleaseInformation2Section from '../organism/ReleaseInformation2Section';
import useSaveFunction from '../../utility/hooks/useSaveFunction';
import useTempSaveFunction from '../../utility/hooks/useTempSaveFunction';
import SearchBoxGroup from '../../organism/SearchBoxGroup';
import useInitPage from '../../utility/hooks/useInitPage';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import TreeModal from '../../organism/TreeModal/TreeModal';

export default function ReleasePage() {
  //    ---CONST
  const navigate = useNavigate();
  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const tokenAxios = useTokenAxios();

  //    ---MODEL
  const store = releaseStore();
  const phase = searchParam.get('phase');
  const { type } = params;

  //    ---ACTION
  const saveFunc = useSaveFunction(
    'release',
    appendReleaseForm,
    store,
    conditionByTypeObject[type].saveFuncType === 'put' || phase * 1 > 1,
    params.id
  );
  const saveTempFunc = useTempSaveFunction(
    'release',
    appendReleaseForm,
    store,
    conditionByTypeObject[type].saveFuncType === 'put',
    params.id
  );

  //    ---BUSINESS LOGIC

  // conditions
  const isChecking = phase === '2';
  const isSaveButton = !store.isRouteActive;
  const isRouteSectionActive = store.isRouteActive;
  const isRouteInit = conditionByTypeObject[type].routeSectionType === 'init';
  const isReadOnly = store.isRouteActive;

  // Logic
  const sendCheck = async () => {
    await tokenAxios.post(`/release-check/${params.id}`);
  };

  useInitPage({
    getData: useGetReleaseData(params.id, store),
    store,
    id: params.id,
    phase,
    type,
    callBack: () => {
      if (isChecking) {
        sendCheck();
      }
    },
  });

  return (
    <PageStyle>
      <TreeModal
        url="release/product-page"
        isActivate={store.isModal}
        setisActivate={store.setisModal}
        applyFunction={(finalItemList) => {
          store.setStateByKey('product')(finalItemList);
        }}
        treeConfigure={(data) => {
          return data.filter((obj) => obj.name === 'Product')[0].children;
        }}
      />
      <ScrollContainer
        isWideScroll={conditionByTypeObject[type].isScrollWide}
        scrollRefList={[
          [store.informationRef, 'Release Information'],
          [store.attachmentRef, 'Release Attachment'],
          [store.routeRef, 'Route Information'],
        ]}
        searchBoxComponent={<SearchBoxGroup store={store} />}
        tempButtonTitle={!store.isRouteActive && 'save as draft'}
        tempButtonOnclick={saveTempFunc}
      >
        <ReleaseInformationSection readOnly={store.isRouteActive} />

        <SectionGap />

        <AttachmentSection
          editMode={conditionByTypeObject[type].isAttachmentEdit || phase > 2}
          title="Release Attachment"
          readOnly={isReadOnly}
          moduleStore={store}
        />

        <SectionGap />

        {isSaveButton && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveFunc}
            condition={store.getisConditionFullfill()}
          >
            Save and Route
          </Button>
        )}

        {isRouteSectionActive &&
          (isRouteInit ? (
            <RouteInitSection
              activate
              routeType="Release"
              itemId={store.id || params.id}
              moduleStore={store}
              afterUrl="/"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={store}
            />
          ))}
      </ScrollContainer>
    </PageStyle>
  );
}
