import React from 'react';
import LineTitle from '../../atom/LineTitle';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import Table from '../../molecule/Table';

export default function WhereUseSection({ data = [] }) {
  return (
    <div>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        Where Used
      </LineTitle>
      <Table>
        <TableIndexRow
          widthArray={[5, 4, 4, 5]}
          itemArray={['PBA Part Number', 'PBA Name', 'Revision', 'Specification']}
        />
        {data.map((whereUseRow) => (
          <TableRow
            widthArray={[5, 4, 4, 5]}
            itemArray={[
              whereUseRow.number,
              whereUseRow.name,
              whereUseRow.revision,
              whereUseRow.specification,
            ]}
          />
        ))}
      </Table>
    </div>
  );
}
