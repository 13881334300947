import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import LineTitle from '../../atom/LineTitle';
import InputGrid from '../../organism/InputGrid';
import { BlueBackGroundBox, MultiSearchInputStyle, PageStyle } from '../../Style';
import txt from '../../Text';
import Button from '../../atom/Button';
import { appendPCBForm, purchasePartBottomInputList } from '../utility';
import PCBStore from '../../store/PCBStore';
import useSaveFunction from '../../utility/hooks/useSaveFunction';
import AnimationSearchInput from '../../molecule/AnimationSearchInput';

const Style = styled(PageStyle)`
  .flex {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .multiContainer {
    width: 50%;
    margin-top: 20px;
  }
`;
export default function PurchaseSection({ readOnly }) {
  const store = PCBStore();
  const {
    setsearchBoxType,
    setStateByKey,
    searchBoxTargetIndex,
    searchBoxType,
    setsearchBoxTargetIndex,
    stateObj,
  } = store;
  const [isChanging, setisChanging] = useState(false);
  const isUserValidToChange = store.stateObj.button;
  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const phase = searchParam.get('phase') * 1;
  const saveFunc = useSaveFunction('pcb', appendPCBForm, store, true, params.id);

  const suppllierInputList = (
    stateObj.pcbProduceOrganizationId[0]
      ? stateObj.pcbProduceOrganizationId
      : readOnly && phase !== 6
        ? []
        : [{ id: '', name: '' }]
  ).map((item, i) => {
    return (
      <MultiSearchInputStyle key={i}>
        {(!readOnly || phase === 6) &&
          (i === stateObj.pcbProduceOrganizationId.length - 1 ? (
            <span
              className="plus"
              onClick={() => {
                if (
                  stateObj.pcbProduceOrganizationId[stateObj.pcbProduceOrganizationId.length - 1]
                    .id !== ''
                ) {
                  setStateByKey('pcbProduceOrganizationId')([
                    ...stateObj.pcbProduceOrganizationId,
                    { id: '', name: '' },
                  ]);
                }
              }}
            >
              +
            </span>
          ) : (
            <span className="plus"> </span>
          ))}
        <AnimationSearchInput
          readOnly={readOnly && phase !== 6}
          width="100%"
          height="40px"
          placeholder={txt.pcb.produceOrganizationId}
          state={item.name || item.name2 || item.code1 || item.code2}
          onClick={() => {
            setsearchBoxType('pcbProduceOrganizationId');
            setsearchBoxTargetIndex(i);
          }}
          isNow={searchBoxType === 'pcbProduceOrganizationId' && searchBoxTargetIndex === i}
        />
      </MultiSearchInputStyle>
    );
  });
  return (
    <Style>
      <div className="flex">
        <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
          {txt.elec.purchaseDetail}
        </LineTitle>
        {isUserValidToChange && (
          <Button
            width="80px"
            height="24px"
            color="white"
            backgroundColor="var(--eciBlue)"
            condition
            onClick={
              isChanging
                ? () => {
                    setisChanging(false);

                    saveFunc();
                  }
                : () => {
                    setisChanging(true);
                  }
            }
          >
            {isChanging ? txt.elec.save : txt.elec.change}
          </Button>
        )}
      </div>

      <InputGrid
        readOnly={readOnly && !isChanging}
        modulestore={store}
        inPutList={purchasePartBottomInputList}
      />

      <div className="multiContainer">{suppllierInputList}</div>
    </Style>
  );
}
