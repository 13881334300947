import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import LineTitle from '../../atom/LineTitle';
import AnimationSearchInput from '../../molecule/AnimationSearchInput';
import InputGrid from '../../organism/InputGrid';
import PBAStore from '../../store/PBAStore';
import { MultiSearchInputStyle, PageStyle } from '../../Style';
import { PBAInputList } from '../utility';
import txt from '../../Text';

const PBAInfoStyle = styled(PageStyle)`
  .multiContainer {
    width: 50%;
    margin-top: 15px;
  }
`;
export default function PBAInformationSection({
  readOnly,
  pbaProduceOrganizationId,
  setpbaProduceOrganizationId,
  searchBoxType,
  setsearchBoxType,
  searchBoxTargetIndex,
}) {
  const { setinformationRef, setsearchBoxTargetIndex } = PBAStore();
  const PBAstore = PBAStore();
  const informationRef = useRef();
  const suppllierInputList =
    pbaProduceOrganizationId &&
    pbaProduceOrganizationId.map((item, i) => {
      return (
        <MultiSearchInputStyle key={i}>
          {!readOnly &&
            (i === pbaProduceOrganizationId.length - 1 ? (
              <span
                className="plus"
                onClick={() => {
                  if (pbaProduceOrganizationId[pbaProduceOrganizationId.length - 1].id !== '') {
                    setpbaProduceOrganizationId([
                      ...pbaProduceOrganizationId,
                      { id: '', name: '' },
                    ]);
                  }
                }}
              >
                +
              </span>
            ) : (
              <span className="plus"> </span>
            ))}
          <AnimationSearchInput
            readOnly={readOnly}
            width="100%"
            height="40px"
            placeholder={txt.pba.produceOrganizationId}
            state={item.code1 || item.code2 || item.name}
            onClick={() => {
              setsearchBoxType('pbaProduceOrganizationId');
              setsearchBoxTargetIndex(i);
            }}
            isNow={searchBoxType === 'pbaProduceOrganizationId' && searchBoxTargetIndex === i}
          />
        </MultiSearchInputStyle>
      );
    });
  useEffect(() => {
    setinformationRef(informationRef);
  }, []);
  return (
    <PBAInfoStyle ref={informationRef}>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        Item Information
      </LineTitle>{' '}
      <InputGrid readOnly={readOnly} modulestore={PBAstore} inPutList={PBAInputList} />
      <div className="multiContainer">{suppllierInputList}</div>
    </PBAInfoStyle>
  );
}
