import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import TableIndexRow from '../atom/TableIndexRow';
import TableRow from '../atom/TableRow';
import PaginationTable from '../molecule/PaginationTable';
import SearchBar from '../molecule/SearchBar';
import useTokenAxios from '../utility/hooks/useTokenAxios';

const SearchBoxStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  padding: 5% 10%;
  display: ${(props) => (props.type && props.isActive ? '' : 'none')};
  .tableSection {
    height: 80%;
  }
`;

export default function SearchBox({
  width,
  height,
  type,
  setproperty,
  propertyIndex, // 멤버처럼 인덱스를 건드려야 할 경우
  isActive,
  indexArray,
  widthArrayProp,
  itemArrayGenerator,
}) {
  /// ---CONST
  const tokenAxios = useTokenAxios();

  /// ---MODEL
  const [searchText, setSearchText] = useState('');
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState('0');
  const [rowsPerPage, setrowsPerPage] = useState(7);
  const [dataList, setdataList] = useState([]);
  const [index, setindex] = useState([]);
  /// ---VIEW
  const widthArray = new Array(index.length).fill(4);
  const indexRow = (
    <TableIndexRow
      widthArray={widthArrayProp || widthArray}
      itemArray={indexArray || index}
      // width={index.length > 5 ? "1800px" : ""}
    />
  );

  const itemRowList =
    dataList &&
    dataList.map((item, i) => {
      const itemArray = itemArrayGenerator
        ? itemArrayGenerator(item)
        : Object.keys(item)
            .filter((key) => key !== 'id')
            .map((key) => {
              const itm = item[key];
              if (typeof itm === 'object' && itm !== null) {
                return itm.value ? itm.value : itm.name;
              }

              return itm;
            });
      return (
        <TableRow
          key={i}
          onClick={() => {
            setproperty(type, item, propertyIndex && propertyIndex);
          }}
          widthArray={widthArrayProp || widthArray}
          itemArray={itemArray}
        />
      );
    });
  /// ---BL

  const getItemList = async () => {
    const response = await tokenAxios.get(
      `${type}?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`
    );
    const data = response.data.result.data || response.data;
    setdataList([...data.content]);
    setTotalPage(data.totalPages);
    setindex(data.indexes?.filter((str) => str !== 'id') || ['code', 'name']);
  };
  useEffect(() => {
    setdataList([]);
    if (type) {
      getItemList();
    }
  }, [setdataList, type, pageNum, rowsPerPage, propertyIndex, searchText]);
  useEffect(() => {
    setPageNum('0');
  }, [searchText]);
  return (
    <SearchBoxStyle width={width} height={height} type={type} isActive={isActive}>
      <div className="searchSection">
        <SearchBar
          width="170px"
          height="30px"
          placeholder="Search Items"
          state={searchText}
          setState={setSearchText}
        />
        <br />
        <br />
      </div>
      <div className="tableSection">
        <PaginationTable
          height="100%"
          noshadow
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setPageNum}
        >
          {indexRow}
          {itemRowList}
        </PaginationTable>
      </div>
    </SearchBoxStyle>
  );
}
