import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useLocation } from 'react-router-dom';
import AnimationDateInput from '../../molecule/AnimationDateInput';
import InputGrid from '../../organism/InputGrid';
import PCBStore from '../../store/PCBStore';

import {
  BoldBlueTextStyle,
  GridItemFlexContainer,
  MultiSearchInputStyle,
  PageStyle,
} from '../../Style';
import AnimationSearchInput from '../../molecule/AnimationSearchInput';
import txt from '../../Text';

const PCBInfoStyle = styled(PageStyle)`
  .multiContainer {
    width: 50%;
    margin-top: 15px;
  }
`;
export default function PCBInformationSection2({ readOnly }) {
  const {
    setsearchBoxType,
    setsearchBoxTargetIndex,
    searchBoxType,
    searchBoxTargetIndex,
    stateObj,
    setStateByKey,
    setPCBInformationRef,
  } = PCBStore();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const phase = searchParam.get('phase') * 1;
  const PCBstore = PCBStore();
  const PCBInformationRef = useRef();

  useEffect(() => {
    setPCBInformationRef(PCBInformationRef);
  }, []);

  return (
    <PCBInfoStyle ref={PCBInformationRef}>
      {/* <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        PCB Information
      </LineTitle> */}

      <GridItemFlexContainer width="50%">
        <BoldBlueTextStyle fontSize="13px" height="40px" margin="0 8px 0 0">
          {txt.mpba.completeDate}
        </BoldBlueTextStyle>

        <AnimationDateInput
          width="40%"
          height="40px"
          placeholder=" "
          state={stateObj.startDate ? stateObj.startDate : ''}
          setState={setStateByKey('startDate')}
          readOnly={readOnly}
        />
      </GridItemFlexContainer>
    </PCBInfoStyle>
  );
}
