import React, { useEffect, useState } from 'react';
import DesignFileList from '../../molecule/DesignFileList';
import LineTitle from '../../atom/LineTitle';
import DesignUploader from '../../molecule/DesignUploader';
import PCBStore from '../../store/PCBStore';
import PartListButton from './PartListButton';
import Table from '../../molecule/Table';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import Button from '../../atom/Button';
import AttachmentWarningSign from '../../molecule/AttachmentWarningSign';
import txt from '../../Text';
import ExportExcelButton from '../../organism/ExportExcelButton';

export default function SchematicDesignUploadSection({ readOnly }) {
  const store = PCBStore();
  const { stateObj, setStateByKey } = store;
  const [fileData, setfileData] = useState({});
  const [partList, setpartList] = useState([]);
  const [unmatchedList, setunmatchedList] = useState([]);
  // partNumber : partNumber가 없거나 일치하는 Part Number가 없다.
  // property : design file이 가지고 있는 property가 DB와 일치하지 않는다.
  const [unmatchedType, setunmatchedType] = useState('PROPERTY');

  const [listTableOpenFlag, setlistTableOpenFlag] = useState(false);
  useEffect(() => {
    if (stateObj.circuitDesign.designFileName) {
      setfileData(stateObj.circuitDesign);
      setpartList(
        typeof stateObj.circuitDesign.partList === 'string'
          ? JSON.parse(stateObj.circuitDesign?.partList || '[]')
          : stateObj.circuitDesign.partList || []
      );
      setunmatchedList(
        typeof stateObj.circuitDesign.error === 'string'
          ? JSON.parse(stateObj.circuitDesign?.error || '[]')
          : stateObj.circuitDesign.error || []
      );
      setunmatchedType(
        typeof stateObj.circuitDesign.errorType === 'string'
          ? stateObj.circuitDesign?.errorType || 'ITEM_NUMBER'
          : stateObj.circuitDesign.errorType || 'ITEM_NUMBER'
      );
    }
    if (stateObj.circuitDesign === '') {
      setfileData({});
      setpartList([]);
      setunmatchedList([]);
    }
  }, [stateObj.circuitDesign]);
  useEffect(() => {
    if (!unmatchedList[0] && !!stateObj.circuitDesign) {
      store.setisCircuitDesignValid(true);
    }
  }, [unmatchedList]);
  return (
    <div>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        Schematic design Upload
      </LineTitle>
      {!readOnly && !stateObj.circuitDesign && (
        <DesignUploader
          title="Schematic Design Uploader"
          bold
          optionArray={[
            {
              name: 'Altium',
              url: `altiumDesignUploader:${store.id}/des/${(stateObj.revision || 1).toFixed(
                1
              )}/${process.env.REACT_APP_BASE_URL}/altium-design`,
            },
          ]}
          pollingUrl={`schematic-file/${store.id}`}
          setState={setStateByKey('circuitDesign')}
        />
      )}
      <br />
      <br />
      {stateObj.circuitDesign && (
        <PartListButton title={txt.pcb.checkSchematicPartList} isTableFlag={listTableOpenFlag}>
          {unmatchedList[0] && (
            <>
              <AttachmentWarningSign
                color="rgba(242, 102, 102, 1)"
                title="Error"
                text={`Reference ${unmatchedList.map((itemObj) => itemObj.ref).join(', ')}${
                  unmatchedType === 'PROPERTY'
                    ? txt.pcb.propertyNoMatchWarning
                    : txt.pcb.noMatchWarning
                }`}
              />
              <br />
            </>
          )}
          <ExportExcelButton
            data={partList}
            cssStyle="margin-left: auto;"
            title={`${stateObj.partNumber}-schematic-partList.xlsx`}
          />
          <br />
          <Table height="360px">
            <TableIndexRow
              widthArray={[3, 5, 3, 3, 8, 3, 8, 5, 5, 5, 5, 5]}
              itemArray={txt.pcb.schematicIndex}
            />
            {partList.map((itemObj, i) => (
              <TableRow
                key={i}
                widthArray={[3, 5, 3, 3, 8, 3, 8, 5, 5, 5, 5, 5]}
                itemArray={[
                  itemObj.ref,
                  itemObj.partNumber,
                  itemObj.unit,
                  itemObj.cnt,
                  itemObj.classification,
                  itemObj.supplier,
                  itemObj.supplierNumber,
                  itemObj.price,
                  itemObj.usage,
                  itemObj.name,
                  itemObj.footPrint,
                  itemObj.symbol,
                ]}
              />
            ))}
          </Table>
          <br />
          {/* {!readOnly && (
            <Button
              backgroundColor="var(--eciBlue)"
              width="100%"
              height="30px"
              color="white"
              condition={!unmatchedList[0]}
              onClick={() => {
                store.setisCircuitDesignValid(true);
                setlistTableOpenFlag((state) => !state);
              }}
            >
              OK
            </Button>
          )} */}
        </PartListButton>
      )}
      {unmatchedList[0] && (
        <>
          <AttachmentWarningSign
            color="rgba(242, 102, 102, 1)"
            title="Error"
            text={`Reference ${unmatchedList.map((itemObj) => itemObj.ref).join(', ')}${
              unmatchedType === 'PROPERTY' ? txt.pcb.propertyNoMatchWarning : txt.pcb.noMatchWarning
            }`}
          />
          <br />
        </>
      )}
      <br />
      <br />
      {stateObj.circuitDesign && (
        <DesignFileList
          title={`${txt.pcb.schematicDesign}`}
          placeholder="Schematic Design Data"
          topFileName={stateObj.circuitDesign.designFileName}
          readOnly={readOnly}
          onDelete={() => {
            setStateByKey('circuitDesign')('');
            store.setisCircuitDesignValid(false);
          }}
          fileObjectArray={[
            {
              title: '회로 Design Data',
              fileName: fileData.designDataName,
              url: fileData.designDataAddress,
            },
            {
              title: 'Part List',
              fileName: fileData.partListName,
              url: fileData.partListAddress,
            },
            {
              title: `${txt.pcb.circuit} view`,
              fileName: fileData.viewName,
              url: fileData.viewAddress,
            },
          ]}
        />
      )}
    </div>
  );
}
