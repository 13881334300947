import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Header from './organism/Header';
import NavigationBar from './organism/NavigationBar';
import Home from './module/Home';
import LogIn from './page/LogIn';
import NotFound from './page/NotFound';
import ProjectModule from './module/ProjectModule';
import ProjectListPage from './x-project/page/ProjectListPage';
import ProjectPage from './x-project/page/ProjectPage';
import commonStore, { userStore } from './store/commonStore';
import SignUp from './page/SignUp';
import ItemModule from './module/ItemModule';
import ItemListPage from './x-electronicPart/page/ItemListPage';
import PCBPage from './x-pcb/page/PCBPage';
import AdminModule from './module/AdminModule';
import AdminItemClassificationPage from './x-admin/page/AdminItemClassificationPage';
import AdminPropPage from './x-admin/page/AdminPropPage';
import ElectronicPartPage from './x-electronicPart/page/ElectronicPartPage';
import PBAPage from './x-pba/page/PBAPage';
import MPBAPage from './x-mpba/page/MPBAPage';
import BOMModule from './module/BOMModule';
import FirmwarePage from './x-firmware/page/FirmwarePage';
import SymbolManagePage from './x-admin/page/SymbolManagePage';
import FootPrintManagePage from './x-admin/page/FootPrintManagePage';
import BlankModule from './module/BlankModule';
import BoardBomModule from './module/BoardBomModule';
import BoardBOMPage from './x-board/page/BoardBOMPage';
import BoardPage from './x-board/page/BoardPage';
import ProductBomModule from './module/ProductBomModule';
import ProductPage from './x-product/page/ProductPage';
import ProductBOMPage from './x-product/page/ProductBomPage';
import CrcoModule from './module/CrcoModule';
import CrListPage from './x-crco/page/CrListPage';
import CoListPage from './x-crco/page/CoListPage';
import CrPage from './x-crco/page/CrPage';
import CoPage from './x-crco/page/CoPage';
import MPBADetailPage from './x-mpba/page/MPBADetailPage';
import LoadingBox from './organism/LoadingBox';
import LoadingSpinner from './molecule/LoadingSpinner';
import SRBomPage from './x-product/page/SRBomPage';
import CompareBomPage from './x-product/page/CompareBomPage';
import SubsidiaryPage from './x-subsidiary/page/SubsidiaryPage';
import ReleasePage from './x-release/page/ReleasePage';
import ReleaseListPage from './x-release/page/ReleaseListPage';
import ReleaseModule from './module/ReleaseModule';
import MemberPage from './x-admin/page/MemberPage';
import LoadingArrowAnimation from './molecule/LoadingArrowAnimation';
import BoldText from './atom/BoldText';
import EditListPage from './x-admin/page/EditListPage';
import LockItemListPage from './x-admin/page/LockItemListPage';
import LoadingArrowBox from './molecule/LoadingArrowBox';
import CadLibraryPage from './x-admin/page/CadLibraryPage';
import SpecificationPage from './x-admin/page/SpecificationPage';
import UnitCostPage from './x-product/page/UnitCostPage';
import UnitCostModule from './module/UnitCostModule';
import PBABOMModule from './module/PBABOMModule';
import PBABOMPage from './x-pba/page/PBABOMPage';

const AppStyle = styled.div`
  width: 100%;
  min-height: 100vh;

  .route {
    min-width: 1000px;
    /* min-height: 580px; */
    min-height: calc(100vh);
    height: max-content;
    display: flex;
    padding-top: 100px; //heqder + padding
    padding-left: ${(props) => (props.isHover ? '200px' : '160px')};
    padding-bottom: 24px;
    padding-right: 60px;
    transition: 0.45s;
    background-color: var(--lightGray);

    min-width: 1200px;
  }
`;
function App() {
  const location = useLocation();

  const [isHover, setisHover] = useState(false);
  const { isLogged, loadingRequestCnt, isLoading, setloadingRequestCnt, loadingBoxType } =
    commonStore();
  const { userData } = userStore();
  // const isLogged = true;
  useEffect(() => {
    setloadingRequestCnt(0);
  }, [location.pathname]);
  return (
    <div className="App">
      {!isLogged ? (
        <Routes>
          <Route path="/" element={<LogIn />} />
          {/* <Route path="/signUp" element={<SignUp />} /> */}

          <Route path="*" element={<NotFound />} />

          {/* <Route path="/signup" element={<SignUp />} /> */}
        </Routes>
      ) : (
        <AppStyle isHover={isHover}>
          <LoadingBox
            isLoading={loadingRequestCnt !== 0 || isLoading}
            isDark={loadingBoxType === 'SPINNER'}
          >
            {loadingBoxType === 'SPINNER' && <LoadingSpinner />}
            {loadingBoxType === 'ARROW' && <LoadingArrowBox />}
            {loadingBoxType === 'RETRY' && <LoadingArrowBox retry />}
          </LoadingBox>
          <Header isHover={isHover} />
          <NavigationBar isHover={isHover} setisHover={setisHover} />
          <div className="route">
            <Routes>
              <Route path="/" element={<Home />} />

              {/** Project Module */}
              <Route path="/project" element={<ProjectModule />}>
                <Route path="page/:type/:id" element={<ProjectPage />} />
                <Route path="list" element={<ProjectListPage />} />
              </Route>

              {/* crco module */}
              <Route path="crco" element={<CrcoModule />}>
                <Route path="cr" element={<BlankModule />}>
                  <Route path="page/:type/:id" element={<CrPage />} />
                  <Route path="" element={<CrListPage />} />
                </Route>
                <Route path="co" element={<BlankModule />}>
                  <Route path="page/:type/:id" element={<CoPage />} />
                  <Route path="" element={<CoListPage />} />
                </Route>
              </Route>

              {/* item module  */}
              <Route path="pba-bom" element={<PBABOMModule />}>
                <Route path="bom/:type/:id" element={<PBABOMPage />} />
              </Route>

              <Route path="board-bom" element={<BoardBomModule />}>
                <Route path="bom/:type/:id" element={<BoardBOMPage />} />
              </Route>

              <Route path="product-bom" element={<ProductBomModule />}>
                <Route path="bom/dev/:type/:id" element={<ProductBOMPage />} />
                <Route path="bom/sr/:type/:id" element={<SRBomPage />} />
                <Route path="bom/compare/:type/:id" element={<CompareBomPage />} />
              </Route>

              <Route path="unit-cost" element={<UnitCostModule />}>
                <Route path=":type/:id" element={<UnitCostPage />} />
              </Route>

              <Route path="/item" element={<ItemModule />}>
                <Route path="pcb/:type/:id" element={<PCBPage />} />
                <Route path="pba/:type/:id" element={<PBAPage />} />
                <Route path="firmware/:type/:id" element={<FirmwarePage />} />
                <Route path="board/:type/:id" element={<BoardPage />} />
                <Route path="product/:type/:id" element={<ProductPage />} />
                <Route path="mpba/:type/:id" element={<MPBADetailPage />} />
                <Route path="subsidiary/:type/:id" element={<SubsidiaryPage />} />

                <Route path="electronic-part/:type/:id" element={<ElectronicPartPage />} />
                <Route path="" element={<ItemListPage />} />
              </Route>

              <Route path="/bom/:type/:id" element={<BOMModule />}>
                <Route path="mpba/:id" element={<MPBAPage />} />
                <Route path="" element={<MPBAPage />} />
              </Route>

              <Route path="/release" element={<ReleaseModule />}>
                <Route path="page/:type/:id" element={<ReleasePage />} />
                <Route path="" element={<ReleaseListPage />} />
              </Route>

              {/* admin module */}
              {!!userData.admin && (
                <Route path="admin" element={<AdminModule />}>
                  <Route path="item" element={<BlankModule />}>
                    <Route path="itemClassification" element={<AdminItemClassificationPage />} />
                    <Route path="lock" element={<LockItemListPage />} />
                    <Route path="property" element={<AdminPropPage />} />
                    <Route path="editList" element={<EditListPage />} />
                    <Route path="specification" element={<SpecificationPage />} />

                    {/* <Route path="" element={<AdminItemClassificationPage />} /> */}
                  </Route>
                  <Route path="member" element={<BlankModule />}>
                    <Route path="manage-member" element={<MemberPage />} />
                  </Route>
                  <Route path="library" element={<BlankModule />}>
                    <Route path="symbol" element={<SymbolManagePage />} />
                    <Route path="footPrint" element={<FootPrintManagePage />} />

                    <Route path="cadLibrary" element={<CadLibraryPage />} />
                  </Route>
                </Route>
              )}
              {/* {userData.admin && (
                <Route path="/admin" element={<AdminModule />}>
                  <Route path="/admin" element={<AdminListPage />} />
                  <Route path="/admin/add/" element={<AdminTaskChangePage />} />
                </Route>
              )} */}

              <Route path="/notFound" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </AppStyle>
      )}
    </div>
  );
}

export default App;
