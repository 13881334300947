import React from 'react';
import styled from 'styled-components';

const BomRowStyle = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  line-height: ${(props) => props.height};
  border: ${(props) => props.border};
  display: flex;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};

  ::before {
    content: '';
    height: ${(props) => props.height};
    width: 6px;
    background-color: ${(props) => props.leftColor};
    border-radius: 5px;
    display: block;
    margin-right: 8px;
  }
`;
export default function BomRow({
  backgroundColor = 'var(--textGray)',
  border,
  children,
  height = '50px',
  leftColor = 'var(--eciBlue)',
  width = '100%',
}) {
  return (
    <BomRowStyle
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      border={border}
      leftColor={leftColor}
    >
      {children}
    </BomRowStyle>
  );
}
