import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ScrollContainer from '../../organism/ScrollContainer';
import AttachmentSection from '../../organism/AttachmentSection';
import Button from '../../atom/Button';
import RouteInitSection from '../../organism/RouteInitSection';

import RouteSection from '../../organism/RouteSection';

import { PageStyle, SectionGap } from '../../Style';
import { conditionByTypeObject } from '../../utility/Source';

import coStore from '../../store/coStore';
import CoInformationSection from '../organism/CoInformationSection';
import ModalBox from '../../organism/ModalBox';
import CoModal from '../organism/CoModal';

import CoCrModal from '../organism/CoCrModal';
import { appendCoForm, useGetcoData } from '../utility';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import useSaveFunction from '../../utility/hooks/useSaveFunction';
import useTempSaveFunction from '../../utility/hooks/useTempSaveFunction';
import SearchBoxGroup from '../../organism/SearchBoxGroup';

export default function CoPage() {
  const tokenAxios = useTokenAxios();

  const params = useParams();

  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const phase = searchParam.get('phase') * 1 || 1;
  //    ---MODEL
  const store = coStore();
  const { type } = params;

  //    ---ACTION
  const saveFunc = useSaveFunction(
    'co',
    appendCoForm,
    store,
    conditionByTypeObject[type].saveFuncType === 'put',
    params.id
  );

  const saveTempFunc = useTempSaveFunction(
    'co',
    appendCoForm,
    store,
    conditionByTypeObject[type].saveFuncType === 'put',
    params.id
  );

  //  ---BUSINESS LOGIC
  const classificationConfigureFunc = (data) => {
    const tmpTree = data.filter(
      (obj) => obj.name === 'Product' || obj.name === 'Assembly' || obj.name === 'Part'
    );
    // elec assembly에서 mpba제거
    const assemblyTree = tmpTree[tmpTree.findIndex((obj) => obj.name === 'Assembly')];
    const electronicAssemblyIndex = assemblyTree.children.findIndex(
      (obj) => obj.name === 'Electronic Assembly'
    );
    const electronicAssemblyTree = assemblyTree.children[electronicAssemblyIndex];

    electronicAssemblyTree.children = electronicAssemblyTree.children.filter(
      (obj) => obj.name !== 'Master PBA'
    );
    const partTree = tmpTree[tmpTree.findIndex((obj) => obj.name === 'Part')];
    partTree.children = partTree.children.filter((obj) => obj.name === 'Software Part');
    return tmpTree;
  };
  const getClassificationTree = async () => {
    const response = await tokenAxios.get('classification-tree');
    const { data } = response.data.result;

    store.setmodalClassificationTree(classificationConfigureFunc(data));
  };
  const getCoModalList = async () => {
    // modal for affected item. crlist is attained from cocrmodal component
    let response = [];
    if (store.modalSelectedItemClassification.classificationId) {
      response = await tokenAxios.get(
        `co/item-classification/${store.modalSelectedItemClassification.classificationId}?size=${store.rowsPerPage}&page=${store.pageNum}`
      );
    }
    store.setmodalItemList(response.data?.result.data.content || []);
    store.settotalPage(response.data?.result.data.totalPages || 1);
  };
  const getCoCrModalList = async () => {
    const response = await tokenAxios.get(
      `co/cr-page?size=${store.rowsPerPage}&page=${store.pageNum}`
    );
    store.setmodalItemList(response.data?.content || []);
    store.settotalPage(response.data?.totalPages || 1);
  };
  const getCrData = useGetcoData(params.id, store);

  useEffect(() => {
    store.setisRouteActive(phase > 1 || conditionByTypeObject[type].isRouteActive);
    store.setid(params.id);
    if (type !== 'detail') {
      getClassificationTree();
    }
    if (params.id !== 'new') {
      getCrData();
    }
    getCoModalList();
    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  useEffect(() => {
    if (store.modalType === 'changeRequestsId') {
      getCoCrModalList();
    }
    if (store.modalType === 'changeModuleIds') {
      getCoModalList();
    }
  }, [store.modalSelectedItemClassification, store.pageNum, store.rowsPerPage, store.modalType]);
  return (
    <PageStyle>
      <ModalBox
        isActivate={store.isModal}
        setisActivate={store.setisModal}
        onExit={store.exitModalCallBack}
      >
        {store.modalType === 'changeRequestsId' ? (
          <CoCrModal />
        ) : (
          <CoModal
            onCheckState={store.onCheckModalItem}
            applyLv2={store.applyModalLv2}
            applyLv3={store.applyModalLv3}
            cancelFunc={store.cancelModal}
            onDeleteModalAddedItems={store.onDeleteModalAddedItems}
          />
        )}
      </ModalBox>
      <ScrollContainer
        isWideScroll={conditionByTypeObject[type].isScrollWide}
        scrollRefList={[
          [store.informationRef, 'CO  Information'],
          [store.attachmentRef, 'CO  Attachment'],
          [store.routeRef, 'Route Information'],
        ]}
        searchBoxComponent={
          <SearchBoxGroup
            store={store}
            setproperty={
              store.searchBoxType === 'affectedItemFeatureId'
                ? store.setsearchBoxPropertyModuleFeature
                : ''
            }
          />
        }
      >
        <CoInformationSection readOnly={store.isRouteActive} />
        <SectionGap />

        <AttachmentSection
          editMode={conditionByTypeObject[type].isAttachmentEdit}
          title="Change Order Attachment"
          readOnly={store.isRouteActive}
          moduleStore={store}
        />
        <SectionGap />
        {!store.isRouteActive && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveFunc}
            condition={store.getisConditionFullfill()}
            // condition
          >
            Save and Route
          </Button>
        )}

        {store.isRouteActive &&
          (conditionByTypeObject[type].routeSectionType === 'init' && phase <= 1 ? (
            <RouteInitSection
              activate
              routeType="Co"
              itemId={store.id}
              moduleStore={store}
              afterUrl="/"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={store}
            />
          ))}
        <SectionGap />
      </ScrollContainer>
    </PageStyle>
  );
}
