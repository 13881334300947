import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import LineTitle from '../../atom/LineTitle';
import AnimationInput from '../../molecule/AnimationInput';
import AnimationSearchInput from '../../molecule/AnimationSearchInput';
import InputGrid from '../../organism/InputGrid';
import TextBox from '../../molecule/TextBox';
import crStore from '../../store/crStore';
import { BlueBackGroundBox, PageStyle } from '../../Style';
import { crTopInputList } from '../utility';
import txt from '../../Text';

const InformationSection = styled(PageStyle)``;
export default function CrInformationSection({ readOnly }) {
  const store = crStore();
  const informationRef = useRef();

  useEffect(() => {
    store.setinformationRef(informationRef);
  }, []);
  return (
    <PageStyle ref={informationRef}>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        Change Request Information
      </LineTitle>{' '}
      <InputGrid readOnly={readOnly} modulestore={store} inPutList={crTopInputList} />
      <BlueBackGroundBox>
        <AnimationInput
          width="50%"
          height="40px"
          placeholder={txt.crco.crTitle}
          state={store.stateObj.name}
          setState={store.setStateByKey('name')}
          required
          readOnly={readOnly}
        />
        <TextBox
          width="100%"
          height="100px"
          placeholder={txt.crco.crContent}
          required
          state={store.stateObj.content}
          setState={store.setStateByKey('content')}
          readOnly={readOnly}
        />
        <br />
        <TextBox
          width="100%"
          height="100px"
          placeholder="Proposed Solution"
          state={store.stateObj.solution}
          setState={store.setStateByKey('solution')}
          readOnly={readOnly}
        />
      </BlueBackGroundBox>
      <br />
      <br />
      <AnimationSearchInput
        readOnly={readOnly}
        width="50%"
        height="40px"
        placeholder="Affected Item"
        state={store.stateObj.affectedModuleId.name || store.stateObj.affectedModuleId.number}
        required
        onClick={() => {
          store.setisModal(true);
        }}
      />
    </PageStyle>
  );
}
