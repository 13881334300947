import React from 'react';
import styled from 'styled-components';
import Input from '../atom/Input';

const SearchBarStyle = styled.div`
  display: flex;
  align-items: center;

  padding-left: 20px;
  gap: 20px;
  height: ${(props) => props.height};
  width: calc(${(props) => `${props.width} + ${props.height} + 30px`});
  background-color: white;
  border: 1px solid var(--deemGray);
  border-radius: 10px;
  font-size: 1.6rem;
  padding-right: 13px;
  .material-icons {
    color: var(--deemGray);
  }
  ${(props) => props.cssStyle}
`;
export default function SearchBar({
  width,
  height,
  placeholder = 'Search Item',
  state,
  setState,
  cssStyle,
}) {
  return (
    <SearchBarStyle width={width} height={height} cssStyle={cssStyle}>
      <span className="material-icons">search</span>
      <Input
        width="90%"
        height={height}
        placeholder={placeholder}
        state={state}
        setState={setState}
        late
      />
    </SearchBarStyle>
  );
}
