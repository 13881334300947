import React, { useState } from 'react';
import styled from 'styled-components';

import NavBarCategoryBox from '../molecule/NavBarCategoryBox';
import NavBarLogoBox from '../molecule/NavBarLogoBox';
import NavBarBackground from '../molecule/NavBarBackground';
import NavBarUserBox from '../molecule/NavBarUserBox';
import { userStore } from '../store/commonStore';

const NavigationBarStyle = styled.div`
  height: 100vh;
  width: ${(props) => (props.isHover ? '150px' : '70px')};
  padding-top: 50px;

  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;

  background-color: transparent;
  border-radius: 0 30px 30px 0;

  font-family: 'Noto Sans KR', sans-serif;
`;

export default function NavigationBar({ isHover, setisHover }) {
  const navBarArray = [
    { name: 'Home', src: 'home', to: '/' },
    { name: 'Project', src: 'lightbulb', to: '/project/list' },
    { name: 'Design Change', src: 'published_with_changes', to: '/crco/cr' },
    { name: 'Item', src: 'build', to: '/item' },
    { name: 'Release', src: 'exit_to_app', to: '/release' },
    // { name: "Admin", src: "admin_panel_settings", to: "/admin/item" },
  ];
  const { userData } = userStore();
  const moduleArray = navBarArray.map((item) => item.to.split('/')[1]);
  const [currentModuleNum, setcurrentModuleNum] = useState(
    moduleArray.indexOf(window.location.pathname.split('/')[1])
  );
  const navBarList = navBarArray.map((module, i) => {
    return (
      <NavBarCategoryBox
        key={i}
        src={module.src}
        name={module.name}
        to={module.to}
        onClick={() => setcurrentModuleNum(i)}
        isHover={isHover}
        isCurrent={i === currentModuleNum}
      />
    );
  });
  return (
    <NavigationBarStyle
      isHover={isHover}
      onMouseEnter={() => {
        setisHover(true);
      }}
      onMouseLeave={() => {
        setisHover(false);
      }}
    >
      <NavBarLogoBox isHover={isHover} />
      {navBarList}
      {userData.admin && (
        <NavBarCategoryBox
          src="admin_panel_settings"
          name="Admin"
          to="/admin/member"
          onClick={() => setcurrentModuleNum(5)}
          isHover={isHover}
          isCurrent={currentModuleNum === 5}
        />
      )}
      <NavBarBackground
        currentModuleNum={currentModuleNum > 0 ? currentModuleNum : 0}
        isHover={isHover}
      />
      <NavBarUserBox isHover={isHover} />
    </NavigationBarStyle>
  );
}
