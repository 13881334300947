import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { BlueButtonStyle } from '../../Style';
import ModalBox from '../../organism/ModalBox';

const Gap = styled.div`
  height: calc(${(props) => props.height});
`;
export default function PartListButton({ title, children, isTableFlag }) {
  const [isTable, setisTable] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setisTable(false);
  }, [isTableFlag]);
  return (
    <div>
      <BlueButtonStyle ref={ref} height="36px" onClick={() => setisTable((state) => !state)} id="b">
        {title} &gt;
      </BlueButtonStyle>
      <br />
      <br />
      <ModalBox isActivate={isTable} setisActivate={setisTable}>
        {children}
      </ModalBox>
    </div>
  );
}
