import React from 'react';
import styled from 'styled-components';

const PopupInputStyle = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: white;
  padding: 0 10px;
  div {
    color: var(--eciBlue);
    font-size: 14px;
  }
  .left {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .icon {
    cursor: pointer;
    color: var(--eciBlue);
    font-size: 20px;
    font-weight: 600;
  }
  margin-bottom: 20px;
`;
export default function PopupInput({ title, onClick }) {
  return (
    <PopupInputStyle title={title}>
      <div className="left">{title}</div>
      <div className="material-icons icon" onClick={onClick}>
        attach_file
      </div>
    </PopupInputStyle>
  );
}
