import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BlueButtonStyle, PageStyle } from '../../Style';
import { useGetProductData } from '../utility';
import productStore from '../../store/productStore';
import BoldText from '../../atom/BoldText';
import ModalBox from '../../organism/ModalBox';
import AnimationSearchInput from '../../molecule/AnimationSearchInput';
import CompareModal from '../organism/CompareModal';
import AnimationSelectBox from '../../molecule/AnimationSelectBox';
import CompareTable from '../molecule/CompareTable';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import txt from '../../Text';

const CompareBomStyle = styled(PageStyle)`
  padding-left: 20%;
  padding-top: 60px;
  > .gap {
    height: 32px;
  }
`;

export default function CompareBomPage() {
  const tokenAxios = useTokenAxios();
  const store = productStore();
  const params = useParams();
  const getProductData = useGetProductData(params.id, store);
  // MODEL
  const [compareData, setcompareData] = useState([]);
  const [srData, setsrData] = useState([]);
  const [isComparing, setisComparing] = useState(false);
  // ACTION
  const onOpenModal = () => {
    store.setisModal(true);
  };
  const onExitModal = () => {
    store.setisModal(false);
  };
  const onCompare = async () => {
    const response = await tokenAxios.get(`product/dev-cmp/${params.id}/${store.compareTarget.id}`);
    setcompareData(response.data.result?.data || []);
    setisComparing(true);
  };

  // BUSINESS Logic
  const getClassificationTree = async () => {
    const response = await tokenAxios.get('classification-tree');
    const { data } = response.data.result;
    store.setmodalClassificationTree(
      data.filter((obj) => obj.name === 'Product' && obj.name !== ' ')
    );
  };
  const getModalList = async () => {
    if (store.modalSelectedItemClassification.classificationId) {
      const response = await tokenAxios.get(
        `dev/product-page/${store.modalSelectedItemClassification.classificationId}?size=${store.rowsPerPage}&page=${store.pageNum}&name=${store.searchText}`
      );
      store.setmodalItemList(response.data?.content || []);
      store.settotalPage(response?.data?.totalPages || 1);
    }
  };
  const getSRData = async () => {
    const response = await tokenAxios.get(`product/sr/dev/${params.id}`);
    setsrData(response.data.result?.data || []);
    setcompareData(response.data.result?.data || []);
  };
  useEffect(() => {
    getProductData();
    getClassificationTree();
    getSRData();
    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  useEffect(() => {
    getModalList();
  }, [store.modalSelectedItemClassification, store.rowsPerPage, store.pageNum, store.searchText]);
  useEffect(() => {
    if (store.compareType === 'SR') {
      store.setcompareTarget({ name: store.stateObj.name });
      setcompareData(srData);
    }
    if (store.compareType === 'DEV') {
      store.setcompareTarget('');
      setcompareData([]);
    }
  }, [store.compareType]);
  return (
    <CompareBomStyle>
      <ModalBox isActivate={store.isModal} setisActivate={store.setisModal} onExit={onExitModal}>
        <CompareModal
          onCheckState={(val) => {
            store.onCheckModalItem(val, true);
          }}
          applyLv2={store.applyModalLv2}
          applyLv3={store.applyCompareModalLv3}
          cancelFunc={store.cancelModal}
          onDeleteModalAddedItems={store.onDeleteModalAddedItems}
        />
      </ModalBox>

      <BoldText fontSize="24px" color="var(--eciBlue)">
        Compare
      </BoldText>
      <div className="gap" />
      <AnimationSelectBox
        width="50%"
        height="40px"
        placeholder={txt.product.compareMethod}
        state={store.compareType}
        setState={(val) => {
          store.setcompareType(val);
          setisComparing(false);
        }}
        required
      >
        <option value="SR">SR</option>
        <option value="DEV">DEV</option>
      </AnimationSelectBox>
      {/* <AnimationSearchInput
        placeholder="dev BOM"
        state={store.stateObj.name}
        width="50%"
        readOnly
      /> */}

      <div className="gap" />
      <BoldText fontSize="24px" color="var(--eciBlue)">
        To
      </BoldText>
      <div className="gap" />

      <AnimationSearchInput
        state={store.compareTarget.name || ''}
        width="50%"
        onClick={onOpenModal}
        placeholder="Search Item Name"
        readOnly={store.compareType === 'SR'}
      />
      <div className="gap" />
      <div className="gap" />
      {store.compareType === 'SR' && (
        <>
          {!isComparing && (
            <BlueButtonStyle
              height="40px"
              width="320px"
              css="justify-content : center;"
              onClick={() => {
                setisComparing(true);
              }}
            >
              Compare
            </BlueButtonStyle>
          )}
          {isComparing && (
            <CompareTable
              name="SR BOM"
              compareName={store.compareTarget.name}
              compareData={compareData}
            />
          )}
        </>
      )}
      {store.compareType === 'DEV' && (
        <>
          {!isComparing && (
            <BlueButtonStyle
              height="40px"
              width="320px"
              css="justify-content : center;"
              onClick={onCompare}
            >
              Compare
            </BlueButtonStyle>
          )}
          {isComparing && (
            <CompareTable
              name={store.stateObj.name}
              compareName={store.compareTarget.name}
              compareData={compareData}
            />
          )}
        </>
      )}
    </CompareBomStyle>
  );
}
