import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ScrollContainer from '../../organism/ScrollContainer';
import AttachmentSection from '../../organism/AttachmentSection';

import LineTitle from '../../atom/LineTitle';
import { PageStyle, SectionGap } from '../../Style';
import { useGetPCBData } from '../../x-pcb/utility';
import PCBStore, { designAttachmentStore } from '../../store/PCBStore';

import PCBInformationSection from '../organism/PCBInformationSection';
import PCBInformationSection2 from '../organism/PCBInformationSection2';
import BoldText from '../../atom/BoldText';
import PCBDesignUploadSection from '../../x-pcb/organism/PCBDesignUploadSection';
import LayerInnerozSection from '../../x-pcb/organism/LayerInnerozSection';

export default function MPBADetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  // 페이지 상태 관리
  const {
    stateObj,
    setStateByKey,
    // ref
    informationRef,
    PCBInformationRef,
    attachmentRef,
    routeRef,
    setid,
  } = PCBStore(); // store를 mpba에서 관리하지 않고 pcbStore를 그대로 재사용한다.
  const pcbstore = PCBStore();
  const designattachmentstore = designAttachmentStore();

  const getPCBData = useGetPCBData(params.id, pcbstore, designattachmentstore);
  useEffect(() => {
    setid(params.id && params.id);
    getPCBData();

    //  attachmentTagOptionList
    return () => {
      pcbstore.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  return (
    <PageStyle>
      <ScrollContainer
        scrollRefList={[
          [informationRef, 'Item Information'],
          [attachmentRef, 'Item Attachment'],
          [PCBInformationRef, 'PCB Information'],
          [routeRef, 'Route Information'],
        ]}
      >
        <SectionGap />
        <div className="flex">
          <BoldText
            fontSize="16px"
            color="var(--eciBlue)"
            cssStyle="display:flex;align-items:center;cursor:pointer;margin-right:60px;"
            onClick={() => {
              navigate(`/item/pcb/detail/${params.id}`);
            }}
          >
            PCB
            <span className="material-icons">open_in_new</span>
          </BoldText>
          <BoldText
            fontSize="16px"
            color="var(--eciBlue)"
            cssStyle="display:flex;align-items:center;cursor:pointer;"
            onClick={() => {
              navigate(`/bom/detail/${pcbstore.stateObj.mpbaId}`);
            }}
          >
            Master PBA BOM
            <span className="material-icons">open_in_new</span>
          </BoldText>
        </div>
        <br />
        <br />
        <AttachmentSection title="Item Attachment" readOnly moduleStore={pcbstore} />
        <SectionGap />
        <PCBInformationSection readOnly />
        <LayerInnerozSection readOnly />
        <PCBInformationSection2 readOnly />
        <SectionGap />
        <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
          PCB design
        </LineTitle>

        <PCBDesignUploadSection readOnly />

        <SectionGap />
        <AttachmentSection
          title="Design Attachment"
          readOnly
          moduleStore={designattachmentstore}
          noScrollRef
        />

        <SectionGap />
      </ScrollContainer>
    </PageStyle>
  );
}
