import React from 'react';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import PaginationTable from '../../molecule/PaginationTable';
import coStore from '../../store/coStore';
import txt from '../../Text';

export default function CoCrModal() {
  const store = coStore();

  const crRowForModalList = store.modalItemList.map((item, i) => {
    return (
      <TableRow
        key={i}
        onClick={() => {
          store.onClickCrModalRow(item);
        }}
        itemArray={[
          '',
          item.itemNumber,
          item.itemName,
          item.itemClassification,
          item.itemRevision,
          item.itemStatus,
          item.crNumber,
          item.crReason?.name,
          item.crImportance,
          item.crSource?.name,
          item.crName,
          item.member?.username,
        ]}
        widthArray={[2, 5, 7, 7, 5, 5, 6, 6, 3, 5, 8, 4]}
      />
    );
  });
  return (
    <div>
      {/* <SearchBar
        width="400px"
        height="30px"
        placeholder="Search Items"
        state={store.searchText}
        setState={(val) => store.setsearchTxt(val)}
      /> */}
      <br />
      <PaginationTable
        width="100%"
        minHeight="300px"
        totalPage={store.totalPage}
        rowsPerPageDefault={10}
        pageNum={store.pageNum}
        setRowsPerPage={(val) => {
          store.setRowsPerPage(val);
        }}
        rowsPerPage={store.rowsPerPage}
        setpageNum={(val) => {
          store.setpageNum(val);
        }}
        noshadow
      >
        <>
          <TableIndexRow itemArray={['', 'CR Item', 'CR Info']} widthArray={[2, 29, 32]} />
          <TableIndexRow
            itemArray={txt.crco.cocrModalIndex}
            widthArray={[2, 5, 7, 7, 5, 5, 6, 6, 3, 5, 8, 4]}
          />
        </>

        {crRowForModalList}
      </PaginationTable>
    </div>
  );
}
