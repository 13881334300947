import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import LineTitle from '../../atom/LineTitle';
import InputGrid from '../../organism/InputGrid';
import PCBStore from '../../store/PCBStore';
import { PageStyle } from '../../Style';
import { PCBTopInputList } from '../utility';

const PCBInfoStyle = styled(PageStyle)`
  .multiContainer {
    width: 50%;
    margin-top: 15px;
  }
`;
export default function PCBInformationSection({ readOnly }) {
  const { setinformationRef } = PCBStore();

  const PCBstore = PCBStore();
  const informationRef = useRef();

  useEffect(() => {
    setinformationRef(informationRef);
  }, []);

  return (
    <PCBInfoStyle ref={informationRef}>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        Item Information
      </LineTitle>
      <InputGrid readOnly={readOnly} modulestore={PCBstore} inPutList={PCBTopInputList} />
    </PCBInfoStyle>
  );
}
