import React, { useState } from 'react';
import styled from 'styled-components';
import Tree from 'react-d3-tree';
import BOMCard from '../molecule/BomCard';

const TreeChartContainerStyle = styled.div`
  .revisetreeContainer {
    background-color: white;
    width: 100%;
    border-radius: 10px;
    height: 600px;
  }
`;
const ReviseButtonStyle = styled.div`
  cursor: pointer;
  width: 50%;
  height: 40px;
  background-color: var(--eciBlue);
  border-radius: 6px;
  color: white;
  padding-left: 8px;
  font-weight: 600;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 12px;
  }
  .icon {
    font-size: 36px;
  }
`;
export default function TreeChartContainer({ treeData }) {
  const [isTree, setisTree] = useState(false);
  return (
    <TreeChartContainerStyle>
      <ReviseButtonStyle
        onClick={() => {
          setisTree(!isTree);
        }}
      >
        <span>Affected Item</span>
        <span className="material-icons icon">arrow_drop_down</span>
      </ReviseButtonStyle>
      <br />
      <br />
      {isTree && (
        <div className="revisetreeContainer">
          <Tree
            stlyle={{ backgroundColor: 'white' }}
            data={treeData}
            orientation="vertical"
            pathFunc="elbow"
            enableLegacyTransitions
            renderCustomNodeElement={BOMCard}
            separation={{ siblings: 3, nonSiblings: 3 }}
            translate={{ x: 500, y: 100 }}
          />
        </div>
      )}
    </TreeChartContainerStyle>
  );
}

// const orgChart = {
//   name: "CEO",
//   children: [
//     {
//       name: "Manager",
//       attributes: {
//         department: "Production",
//       },
//       children: [
//         {
//           name: "Foreman",
//           attributes: {
//             department: "Fabrication",
//           },
//           children: [
//             {
//               name: "Worker",
//             },
//           ],
//         },
//         {
//           name: "Foreman",
//           attributes: {
//             department: "Assembly",
//           },
//           children: [
//             {
//               name: "Worker",
//             },
//           ],
//         },
//       ],
//     },
//   ],
// };
