import React from 'react';
import styled from 'styled-components';
import BarOfGraph from './BarOfGraph';
import PeriodSelectBox from './PeriodSelectBox';
import Legend from './Legend';

const BarGraphStyle = styled.div`
  display: flex;
  height: ${(props) => props.height};
  padding-left: 40px;
  margin-bottom: 40px;
  border-bottom: solid 2px var(--eciBlue);
  padding-top: 60px;
  background-color: white;
  border-radius: 8px 8px 0 0;
  position: relative;
  width: 100%;
  > .title {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 16px;
    font-weight: 600;
    color: var(--textDarkGray);
  }
`;
export default function BarGraph({
  data,
  colorArr,
  borderIndex,
  height = '35vh',
  isLegend,
  title,
  periodObj = false,
}) {
  const maxVal = Math.max(
    ...data.map((obj) => Object.values(obj.val).reduce((acc, cum) => acc + cum))
  );

  const barList = data.map((obj, i) => (
    <BarOfGraph
      key={i}
      bottomTitle={obj.name}
      pointArr={Object.values(obj.val)}
      colorArr={colorArr}
      width={`100% / ${data.length}`}
      height={height}
      rightBorder={i === borderIndex - 1}
      maxY={maxVal}
    />
  ));
  const legendList = Object.keys(data[0].val).map((name, i) => (
    <Legend key={i} text={name} color={colorArr[i]} />
  ));
  return (
    <BarGraphStyle>
      <div className="title">{title}</div>
      {isLegend && <div className="legends">{legendList}</div>}
      {periodObj && (
        <PeriodSelectBox
          month={periodObj.month}
          year={periodObj.year}
          setmonth={periodObj.setmonth}
          setyear={periodObj.setyear}
          position
        />
      )}

      {barList}
    </BarGraphStyle>
  );
}
