import React, { useState } from 'react';
import styled from 'styled-components';
import { WidthHeightStyle } from '../../Style';

const PopupImgBoxStyle = styled.div`
  ${WidthHeightStyle}
  padding:40px;

  .top {
    height: 30px;
    .type {
      font-size: 14px;
      color: var(--eciBlue);
      margin-right: 12px;
    }
    .name {
      font-weight: 600;
      font-size: 14px;
      color: var(--eciBlue);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  img {
    width: 100%;
    height: calc(100% - 30px);
    border-radius: 10px;
    background-color: var(--eciBlue);
    opacity: ${(p) => (p.isImgLoaded ? 1 : 0)};
  }
  .img {
    width: 100%;
    height: calc(100% - 30px);
    border-radius: 10px;
    background-color: var(--eciBlue);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    opacity: ${(p) => (p.isImgLoaded ? 0 : 1)};
  }
  display: ${(props) => (props.activate ? '' : 'none')};
`;
export default function PopupImgBox({
  type,
  title,
  imgOrigin,
  width = '90%',
  height = '400px',
  isActive,
}) {
  const [isImgLoaded, setisImgLoaded] = useState(false);
  return (
    <PopupImgBoxStyle
      width={width}
      height={height}
      activate={isActive}
      isImgLoaded={isImgLoaded && imgOrigin}
    >
      <div className="top">
        <span className="type">{type}</span>
        <span className="name">{title}</span>
      </div>
      <br />

      <img
        src={imgOrigin || ''}
        alt="noImage"
        onLoad={() => {
          setisImgLoaded(true);
        }}
      />
      <div className="img">image not found</div>
    </PopupImgBoxStyle>
  );
}
