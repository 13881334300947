import React, { useRef } from 'react';
import styled from 'styled-components';
import Button from '../atom/Button';
import ScrollController from '../molecule/ScrollController';

const ScrollContainerStyle = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  .flex {
    display: flex;
  }
  .additional {
    width: 90%;
    margin-left: 60px;
  }
`;
const FormSectionStyle = styled.div`
  position: relative;
  width: ${(props) => (props.isWideScroll ? 'calc(96% - 120px)' : 'calc(60% - 120px)')};
  height: calc(100vh - 280px); //여기 픽셀 수정
  overflow-y: scroll;
  padding-right: ${(props) => (props.isWideScroll ? '40%' : '30px')};
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
  ${({ formSectionCss }) => formSectionCss}
`;
const SearchBoxSectionStyle = styled.div`
  width: 40%;
  display: ${(props) => (props.isWideScroll ? 'none' : ' ')};

  position: relative;
  :nth-child() {
    position: absolute;
    left: 0;
  }
`;
const TempButtonContainerStyle = styled.div`
  height: 40px;
  display: flex;
  position: absolute;
  top: -62px;
  right: ${(props) => (props.isWideScroll ? '5%' : '46%')};
`;
export default function ScrollContainer({
  children, // form에 들어갈 애들
  scrollRefList, // [[ref,name],[]]
  searchBoxComponent, // arr
  tempButtonTitle, // temp에 들어갈 버튼 이름
  tempButtonOnclick,
  isWideScroll,
  additionalPartTop, // revise관련
  formSectionCss,
}) {
  const formRef = useRef();
  //  children에서 큰 애들 구현할 때는 position:absolute달아주기
  return (
    <ScrollContainerStyle>
      <ScrollController width="100px" buttonArray={scrollRefList} scrollRef={formRef} />
      <TempButtonContainerStyle isWideScroll={isWideScroll}>
        {tempButtonTitle && (
          <Button
            backgroundColor="transparent"
            onClick={tempButtonOnclick}
            fontSize="12px"
            color="var(--textDarkGray)"
            condition
          >
            {tempButtonTitle}
          </Button>
        )}
      </TempButtonContainerStyle>
      <div className="additional">
        {additionalPartTop && (
          <>
            {additionalPartTop}
            <br />
            <br />
          </>
        )}
        <div className="flex">
          <FormSectionStyle
            isWideScroll={isWideScroll}
            ref={formRef}
            formSectionCss={formSectionCss}
          >
            {children}
          </FormSectionStyle>
          <SearchBoxSectionStyle isWideScroll={isWideScroll}>
            {searchBoxComponent}
          </SearchBoxSectionStyle>
        </div>
      </div>
    </ScrollContainerStyle>
  );
}
