import React, { useEffect, useState } from 'react';
import DesignFileList from '../../molecule/DesignFileList';
import LineTitle from '../../atom/LineTitle';
import DesignUploader from '../../molecule/DesignUploader';
import PCBStore from '../../store/PCBStore';
import PartListButton from './PartListButton';
import Table from '../../molecule/Table';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import AttachmentWarningSign from '../../molecule/AttachmentWarningSign';
import txt from '../../Text';
import ExportExcelButton from '../../organism/ExportExcelButton';

export default function PCBDesignUploadSection({ readOnly }) {
  const store = PCBStore();
  const { stateObj, setStateByKey } = store;
  const [partlistButtonModalFlag, setpartlistButtonModalFlag] = useState(false);
  const [circuitFileData, setcircuitFileData] = useState({});
  const [circuitPartList, setcircuitPartList] = useState([]);
  const [circuitUnmatchedList, setcircuitUnmatchedList] = useState([]);
  const [pcbFileData, setpcbFileData] = useState({});
  const [pcbPartList, setpcbPartList] = useState([]);
  const [pcbUnmatchedList, setpcbUnmatchedList] = useState([]);
  useEffect(() => {
    if (stateObj.circuitDesign.designFileName) {
      setcircuitFileData(stateObj.circuitDesign);
      setcircuitPartList(
        typeof stateObj.circuitDesign.partList === 'string'
          ? JSON.parse(stateObj.circuitDesign?.partList || '[]')
          : stateObj.circuitDesign.partList || []
      );
      setcircuitUnmatchedList(
        typeof stateObj.circuitDesign.error === 'string'
          ? JSON.parse(stateObj.circuitDesign?.error || '[]')
          : stateObj.circuitDesign.error || []
      );
    }
    if (stateObj.circuitDesign === '') {
      setcircuitFileData({});
      setcircuitPartList([]);
      setcircuitUnmatchedList([]);
    }
  }, [stateObj.circuitDesign]);
  useEffect(() => {
    if (stateObj.pcbDesign.designFileName) {
      setpcbFileData(stateObj.pcbDesign);
      setpcbPartList(
        typeof stateObj.pcbDesign.partList === 'string'
          ? JSON.parse(stateObj.pcbDesign?.partList || '[]')
          : stateObj.pcbDesign.partList || []
      );
      setpcbUnmatchedList(
        typeof stateObj.pcbDesign.error === 'string'
          ? JSON.parse(stateObj.pcbDesign?.error || '[]')
          : stateObj.pcbDesign.error || []
      );
    }
    if (stateObj.pcbDesign === '') {
      setpcbFileData({});
      setpcbPartList([]);
      setpcbUnmatchedList([]);
    }
  }, [stateObj.pcbDesign]);

  useEffect(() => {
    if (!circuitUnmatchedList[0] && !!stateObj.circuitDesign) {
      store.setisCircuitDesignValid(true);
    }
  }, [circuitUnmatchedList]);
  useEffect(() => {
    if (!pcbUnmatchedList[0] && !!stateObj.pcbDesign && isPartlistMatch) {
      store.setisPcbDesignValid(true);
    }
  }, [pcbUnmatchedList]);

  const getIsTwoPartlistValid = () => {
    if (pcbPartList.length !== circuitPartList.length) {
      if (store.isPcbDesignValid) {
        store.setisPcbDesignValid(false);
      }
      return false;
    }

    const pcbRefs = pcbPartList.map((item) => item?.ref).sort();
    const circuitRefs = circuitPartList.map((item) => item?.ref).sort();

    const isValid = pcbRefs.every((ref, index) => ref === circuitRefs[index]);

    if (!isValid && store.isPcbDesignValid) {
      store.setisPcbDesignValid(false);
    }
    return isValid;
  };

  const isPartlistMatch = getIsTwoPartlistValid();
  return (
    <div>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        PCB design Upload
      </LineTitle>
      {!readOnly && !stateObj.circuitDesign && (
        <DesignUploader
          title="Schematic Design Uploader"
          bold
          optionArray={[
            {
              name: 'Altium',
              url: `altiumDesignUploader:${store.id}/des/${(stateObj.revision || 1).toFixed(
                1
              )}/${process.env.REACT_APP_BASE_URL}/altium-design`,
            },
          ]}
          pollingUrl={`schematic-file/${store.id}`}
          setState={setStateByKey('circuitDesign')}
        />
      )}
      {!readOnly && !stateObj.pcbDesign && (
        <DesignUploader
          title="PCB Design Uploader"
          bold
          optionArray={[
            {
              name: 'Altium',
              url: `altiumDesignUploader:${store.id}/pcb/${(1).toFixed(1)}/${
                process.env.REACT_APP_BASE_URL
              }/altium-design`,
            },
          ]}
          pollingUrl={`pcb-file/${store.id}`}
          setState={setStateByKey('pcbDesign')}
        />
      )}
      {stateObj.circuitDesign && (
        <PartListButton title="Schematic PartList 확인 " isTableFlag={partlistButtonModalFlag}>
          {circuitUnmatchedList[0] && (
            <AttachmentWarningSign
              color="rgba(242, 102, 102, 1)"
              title="Error"
              text={`Reference ${circuitUnmatchedList.map(
                (itemObj) => itemObj.ref
              )}${txt.pcb.noMatchWarning}`}
            />
          )}

          <ExportExcelButton
            data={circuitPartList}
            cssStyle="margin-left: auto;"
            title={`${stateObj.partNumber}-schematic-partList.xlsx`}
          />
          <br />
          <Table height="360px">
            <TableIndexRow
              widthArray={[3, 5, 3, 3, 8, 3, 8, 5, 5, 5, 5, 5]}
              itemArray={txt.pcb.schematicIndex}
            />

            {circuitPartList.map((itemObj, i) => (
              <TableRow
                key={i}
                widthArray={[3, 5, 3, 3, 8, 3, 8, 5, 5, 5, 5, 5]}
                itemArray={[
                  itemObj.ref,
                  itemObj.partNumber,
                  itemObj.unit,
                  itemObj.cnt,
                  itemObj.classification,
                  itemObj.supplier,
                  itemObj.supplierNumber,
                  itemObj.price,
                  itemObj.usage,
                  itemObj.name,
                  itemObj.footPrint,
                  itemObj.symbol,
                ]}
              />
            ))}
          </Table>
          <br />
          {/* {!readOnly && (
            <Button
              backgroundColor="var(--eciBlue)"
              width="100%"
              height="30px"
              color="white"
              condition={!circuitUnmatchedList[0]}
              onClick={() => {
                store.setisCircuitDesignValid(true);
                setpartlistButtonModalFlag((state) => !state);
              }}
            >
              OK
            </Button>
          )} */}
        </PartListButton>
      )}
      {circuitUnmatchedList[0] && (
        <>
          <AttachmentWarningSign
            color="rgba(242, 102, 102, 1)"
            title="Error"
            text={`Reference ${circuitUnmatchedList
              .map((itemObj) => itemObj.ref)
              .join(', ')}${txt.pcb.noMatchWarning}`}
          />
          <br />
        </>
      )}
      {stateObj.pcbDesign && (
        <PartListButton title={txt.pcb.checkPcbPartList} isTableFlag={partlistButtonModalFlag}>
          {pcbUnmatchedList[0] && (
            <>
              <AttachmentWarningSign
                color="rgba(242, 102, 102, 1)"
                title="Error"
                text={`Reference ${pcbUnmatchedList.map(
                  (itemObj) => itemObj.ref
                )}${txt.pcb.noMatchWarning}`}
              />
              <br />

              <Table height="240px">
                <TableIndexRow
                  width="1400px"
                  widthArray={[3, 5, 3, 3, 8, 3, 8, 5, 5, 5, 5, 5, 5, 5, 5, 5]}
                  itemArray={txt.pcb.pcbIndex}
                />
                {pcbUnmatchedList.map((itemObj, i) => (
                  <TableRow
                    key={i}
                    backgroundColor="rgba(255, 214, 203, 1)"
                    widthArray={[3, 5, 3, 3, 8, 3, 8, 5, 5, 5, 5, 5, 5, 5, 5, 5]}
                    itemArray={[
                      itemObj.ref,
                      itemObj.partNumber,
                      itemObj.unit,
                      itemObj.cnt,
                      itemObj.classification,
                      itemObj.supplier,
                      itemObj.supplierNumber,
                      itemObj.price,
                      itemObj.usage,
                      itemObj.name,
                      itemObj.footPrint,
                      itemObj.symbol,
                      itemObj.exist * 1 === 1 ? 'O' : 'X',
                      itemObj.exist * 1 === 0 ? 'O' : 'X',
                    ]}
                  />
                ))}
              </Table>
            </>
          )}
          <br />
          <ExportExcelButton
            data={pcbPartList}
            cssStyle="margin-left: auto;"
            title={`${stateObj.partNumber}-PCB-partList.xlsx`}
          />
          <br />
          <Table height="360px" innerWidth="1400px">
            <TableIndexRow
              width="1400px"
              widthArray={[3, 5, 3, 3, 8, 3, 8, 5, 5, 5, 5, 5, 5, 5, 5, 5]}
              itemArray={txt.pcb.pcbFileIndex}
            />
            {pcbPartList.map((itemObj, i) => (
              <TableRow
                width="1400px"
                key={i}
                widthArray={[3, 5, 3, 3, 8, 3, 8, 5, 5, 5, 5, 5, 5, 5, 5, 5]}
                itemArray={[
                  itemObj.ref,
                  itemObj.partNumber,
                  itemObj.unit,
                  itemObj.cnt,
                  itemObj.classification,
                  itemObj.supplier,
                  itemObj.supplierNumber,
                  itemObj.price,
                  itemObj.usage,
                  itemObj.name,
                  itemObj.footPrint,
                  itemObj.symbol,
                  itemObj.rotation,
                  itemObj.side,
                  itemObj.coordinates,
                  itemObj.pcbType,
                ]}
              />
            ))}
          </Table>

          <br />
          {/* {!readOnly && (
            <Button
              backgroundColor="var(--eciBlue)"
              width="100%"
              height="30px"
              color="white"
              condition={!pcbUnmatchedList[0] && isPartlistMatch}
              onClick={() => {
                store.setisPcbDesignValid(true);
                setpartlistButtonModalFlag((state) => !state);
              }}
            >
              OK
            </Button>
          )} */}
        </PartListButton>
      )}
      {(pcbUnmatchedList[0] || !isPartlistMatch) && (
        <AttachmentWarningSign
          color="rgba(242, 102, 102, 1)"
          title="Error"
          text={`${
            pcbUnmatchedList[0]
              ? `Reference ${pcbUnmatchedList.map((itemObj) => itemObj.ref)}${
                  txt.pcb.noMatchWarning
                }`
              : ''
          }${isPartlistMatch ? '' : `\n ${txt.pcb.schematicNoMatch}`}`}
        />
      )}
      <br />
      <br />
      <br />
      {stateObj.circuitDesign && (
        <DesignFileList
          title={`${txt.pcb.schematicDesign}`}
          placeholder="Schematic Design Data"
          topFileName={stateObj.circuitDesign.designFileName}
          readOnly={readOnly}
          onDelete={() => {
            setStateByKey('circuitDesign')('');

            store.setisCircuitDesignValid(false);
          }}
          fileObjectArray={[
            {
              title: '회로 Design Data',
              fileName: circuitFileData.designDataName,
              url: circuitFileData.designDataAddress,
            },
            {
              title: 'Part List',
              fileName: circuitFileData.partListName,
              url: circuitFileData.partListAddress,
            },
            {
              title: `${txt.pcb.circuit} view`,
              fileName: circuitFileData.viewName || circuitFileData.smtDataName,
              url: circuitFileData.viewAddress || circuitFileData.smtDataAddress,
            },
          ]}
        />
      )}
      {stateObj.pcbDesign && (
        <DesignFileList
          title="PCB Design"
          placeholder="PCB Design Data"
          topFileName={stateObj.pcbDesign.designDataName}
          readOnly={readOnly}
          onDelete={() => {
            setStateByKey('pcbDesign')('');
            store.setisPcbDesignValid(false);
          }}
          fileObjectArray={[
            {
              title: 'PCB Design Data',
              fileName: pcbFileData.designDataName,
              url: pcbFileData.designDataAddress,
            },
            {
              title: 'Part List',
              fileName: pcbFileData.partListName,
              url: pcbFileData.partListAddress,
            },
            {
              title: 'SMT Data',
              fileName: pcbFileData.viewName || pcbFileData.smtDataName,
              url: pcbFileData.viewAddress || pcbFileData.smtDataAddress,
            },
            {
              title: 'Gerber',
              fileName: pcbFileData.gerberName,
              url: pcbFileData.gerberAddress,
            },
          ]}
        />
      )}
    </div>
  );
}
