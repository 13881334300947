import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useGetDataByPeriod } from '../utility';
import BarGraph from '../molecule/BarGraph';
import txt from '../../Text';

const GraphReleaseStyle = styled.div`
  padding: 8px 20px;
  height: 50vh;
`;

export default function GraphRelease() {
  // ---MODEL

  const [month, setmonth, year, setyear, data] = useGetDataByPeriod('home-release-date');

  return (
    <GraphReleaseStyle>
      <br />
      <br />
      <br />
      <BarGraph
        borderIndex={13 - month}
        data={data.list}
        colorArr={['rgba(42, 46, 128, 1)', '#5F7BE8', '#53AEEA', '#9EECB0']}
        periodObj={{ month, year, setmonth, setyear }}
        title={`${txt.dashBoard.gun}${data.total} ${txt.dashBoard.TotalTitle}`}
      />
    </GraphReleaseStyle>
  );
}
