import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import HistoryPopup from './HistoryPopup';

const HistoryButtonStyle = styled.div`
  .history {
    color: var(--eciBlue);
    display: flex;
    align-items: center;
    font-size: 28px;
    cursor: pointer;
  }
`;
export default function HistoryButton({
  onClick = () => {},
  readOnly = false,
  historyList,
  isLock,
}) {
  const modalRef = useRef(null);
  const [top, settop] = useState(0);
  const [left, setleft] = useState(0);
  const [isPopup, setisPopup] = useState(false);
  const handleClick = (e) => {
    if (!readOnly) {
      setisPopup((bool) => !bool);
      settop(e.pageY);
      setleft(e.pageX);
    }
    onClick();
  };
  const handleClickOutside = (e) => {
    if (!modalRef.current.contains(e.target)) {
      setisPopup(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    window.addEventListener('scroll', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('scroll', handleClickOutside);
    };
  }, []);
  return (
    <HistoryButtonStyle onClick={handleClick} ref={modalRef}>
      <div className="material-icons history">history</div>
      {isPopup && (
        <HistoryPopup
          isLock={isLock}
          top={top}
          left={left}
          setisActive={setisPopup}
          historyList={historyList}
        />
      )}
    </HistoryButtonStyle>
  );
}
