import React from 'react';
import styled from 'styled-components';

import txt from '../../Text';

const BoxStyle = styled.div`
  width: 240px;
  border-radius: 10px;
  padding: 16px;
  background-color: var(--eciBlue);

  * {
    color: white;
    font-weight: 600;
  }
  .currencySelect {
    border-bottom: solid white 1px;
    padding-bottom: 8px;
    margin-bottom: 12px;
    color: var(--textGray);
    font-weight: 400;
  }
  .title {
    font-size: 14px;
  }
  .underline {
    text-decoration: underline;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price {
    font-size: 24px;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
export default function TotalCostBox({ currency, title, name, price }) {
  return (
    <BoxStyle>
      <div className="currencySelect">
        {' '}
        {txt.product.currency}: <span className="currency">{currency}</span>
      </div>
      <span className="title">{title}</span>
      <br />
      <span className="title underline">{name}</span>
      <br />
      <br />
      <div className="container">
        <div className="price" title={price}>
          {price}
        </div>
        <div className="title">{currency}</div>
      </div>
    </BoxStyle>
  );
}
