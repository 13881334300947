import create from 'zustand';

const dashboardStore = create((set) => ({
  currentModuleIndex: '',
  setcurrentModuleIndex: (val) => {
    set(() => ({ currentModuleIndex: val }));
  },
  todoDataObj: {}, //  obj 키값이 모듈 이름
  settodoDataObj: (val) => {
    set(() => ({ todoDataObj: val }));
  },
}));
export default dashboardStore;

export const moduleList = [
  'Project',
  'Item Product',
  'Item Board',
  'Item PCB',
  'Item PBA',
  'Item Firmware',
  'Item Component',
  'Item Mech',
  '부자재',
  '소모재',
  'CR',
  'CO',
  'Release',
];
export const routeByTodoNameObj = {
  Project: 'project/page',
  'Item Product': 'item/product',
  'Item Board': 'item/board',

  'Item PCB': 'item/pcb',
  'Item PBA': 'item/pba',
  'Item Firmware': 'item/firmware',
  'Item Component': 'item/electronic-part',
  'Item Mech': 'item/electronic-part',
  부자재: 'item/subsidiary',
  소모재: 'item/subsidiary',
  CR: 'crco/cr/page',
  CO: 'crco/co/page',
  Release: 'release/page',
};

export const typeByRoute = {
  tempsave1: 'editD',
  tempsave2: 'editS',
  rejected: 'rejected',
  revise: 'editD',
  review: 'review',
};
