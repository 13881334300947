import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { PropModalStyle, ScrollDefault } from '../../Style';
import Table from '../../molecule/Table';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import PaginationTable from '../../molecule/PaginationTable';

import coStore from '../../store/coStore';
import ClassificationSelectTree from '../../organism/ClassificationSelectTree';
import DeleteButton from '../../atom/DeleteButton';

const ModalStyle = styled.div`
  display: flex;
  width: 80vw;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 1px solid var(--deemGray);
    width: 5px;
    background: white;
    border-radius: 8px;
  }
  .gap {
    width: 40px;
    flex-shrink: 0;
  }
  .treeScrollContainer {
    border-radius: 12px 12px 0 0;

    padding-top: 40px;
    background-color: white;
    height: calc(100% - 59px);
    ${ScrollDefault}
  }
`;

const saveTreeState = (state) => {
  localStorage.setItem('coClassificationTreeState', JSON.stringify(state));
};

const loadTreeState = () => {
  const savedState = localStorage.getItem('coClassificationTreeState');
  return savedState ? JSON.parse(savedState) : {};
};
export default function CoModal({
  cancelFunc,
  applyLv2,
  applyLv3,
  onCheckState,
  onDeleteModalAddedItems,
}) {
  const [treeState, setTreeState] = useState(loadTreeState());
  const setIsOpen = (id, isOpen) => {
    setTreeState((prevState) => {
      const newState = { ...prevState, [id]: isOpen };
      saveTreeState(newState);
      return newState;
    });
  };
  const modalContainerRef = useRef();
  const store = coStore();
  return (
    <ModalStyle ref={modalContainerRef}>
      <PropModalStyle width="240px" height="70vh" fontSize="20px">
        <div className="title">Choose Item Classification</div>
        <div className="treeScrollContainer">
          {store.modalClassificationTree.map((item, i) => {
            return (
              <ClassificationSelectTree
                key={i}
                child={item.children}
                title={item.name}
                last={item.last}
                id={item.id}
                data={item}
                depth={0}
                treeId={i}
                backgroundColor="var(--eciBlue)"
                boxWidth="100px"
                onClick={(val) => {
                  if (val.last === 1) {
                    store.setmodalSelectedItemClassification(val);
                    modalContainerRef.current.scrollTo({
                      left: 250,
                      behavior: 'smooth',
                    });
                  }
                }}
                currentState={store.modalSelectedItemClassification}
                isOpen={treeState}
                setIsOpen={setIsOpen}
              />
            );
          })}
        </div>
      </PropModalStyle>
      <div className="gap" />
      <PropModalStyle width="680px" height="70vh" fontSize="20px">
        <div className="title">Select the Item</div>
        <div className="modal">
          <PaginationTable
            width="100%"
            minHeight="90%"
            totalPage={store.totalPage}
            rowsPerPageDefault={10}
            pageNum={store.pageNum}
            rowsPerPage={store.rowsPerPage}
            setRowsPerPage={store.setrowsPerPage}
            setpageNum={store.setpageNum}
            noshadow
          >
            <TableIndexRow
              widthArray={[2, 6, 9, 4, 4]}
              itemArray={['', 'Item Number', 'Item Name', 'Revision']}
            />

            {store.modalItemList.map((itemObj, i) => (
              <TableRow
                key={i}
                widthArray={[2, 6, 9, 4, 4]}
                itemArray={[
                  <input
                    type="checkbox"
                    onChange={() => {
                      onCheckState(itemObj);
                    }}
                    checked={
                      store.modalTempCheckedList.findIndex((item) => item.id === itemObj.id) > -1
                    }
                  />,
                  itemObj.number,
                  itemObj.name,
                  itemObj.revision,
                ]}
              />
            ))}
          </PaginationTable>
        </div>

        <div className="buttonPart">
          <div className="actionButton cancel" onClick={cancelFunc}>
            CANCEL
          </div>
          <div
            className="actionButton apply"
            onClick={() => {
              modalContainerRef.current.scrollTo({
                left: 3000,
                behavior: 'smooth',
              });
              applyLv2();
            }}
          >
            APPLY
          </div>
        </div>
      </PropModalStyle>
      <div className="gap" />

      <PropModalStyle width="500px" height="70vh" fontSize="20px">
        <div className="title">Added Items</div>
        <div className="modal">
          <Table width="100%" noshadow>
            <TableIndexRow
              widthArray={[7, 7, 7]}
              itemArray={['Item Number', 'Item Name', 'Revision']}
            />

            {store.modalCheckedItemList.map((itemObj, i) => (
              <TableRow
                key={i}
                widthArray={[7, 7, 4, 3]}
                itemArray={[
                  itemObj.number,
                  itemObj.name,
                  itemObj.revision,
                  <DeleteButton onDelete={() => onDeleteModalAddedItems(itemObj.id)} />,
                ]}
              />
            ))}
          </Table>
        </div>

        <div className="buttonPart">
          <div className="actionButton cancel" onClick={cancelFunc}>
            CANCEL
          </div>
          <div className="actionButton apply" onClick={applyLv3}>
            APPLY
          </div>
        </div>
      </PropModalStyle>
    </ModalStyle>
  );
}
