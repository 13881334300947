import React from 'react';
import styled from 'styled-components';
import { getImgSource } from '../utility/Utility';

const BOMCardStyle = styled.g`
  text {
    stroke-width: 0;
  }
  .itemName {
    font-size: 12px;
    font-weight: 600;
    fill: var(--textDarkGray);
  }
  .classification {
    font-size: 11px;

    fill: ${(props) => (props.blue ? 'white' : 'var(--eciBlue)')};
  }
  .name {
    font-size: 14px;

    font-weight: 600;
    fill: ${(props) => (props.blue ? 'white' : 'var(--eciBlue)')};
  }
  image {
    width: 40;
    height: 40;
    stroke-width: 0;
  }
  .type {
    fill: var(--eciBlue);
    font-size: 16px;
    font-weight: 600;
  }
`;
export default function BOMCard({ nodeDatum, toggleNode }) {
  const isValid = nodeDatum.itemNumber || nodeDatum.name || nodeDatum.itemClassification;

  return (
    <BOMCardStyle blue={!!nodeDatum?.top} onClick={toggleNode}>
      <rect
        className="backGround"
        x="-150"
        y="0"
        width="300"
        height="90"
        rx="5"
        ry="5"
        style={{
          fill: nodeDatum?.top ? 'var(--eciBlue)' : 'white',
          strokeWidth: '1px',
        }}
      />

      {isValid ? (
        <>
          <text className="itemName" x="-135" y="20">
            {nodeDatum?.itemNumber}
          </text>
          <text className="classification" x="-90" y="50">
            {nodeDatum?.itemClassification}
          </text>
          <text className="name" x="-90" y="70">
            {nodeDatum?.name}
          </text>
          <rect
            x="-135"
            y="35"
            rx="100"
            ry="100"
            width="40"
            height="40"
            style={{
              fill: 'var(--textGray)',
              strokeWidth: 0,
            }}
          />
          <image
            className="image"
            xlinkHref={nodeDatum?.thumbNail && getImgSource(nodeDatum?.thumbNail)}
            x="-135"
            y="35"
          />
        </>
      ) : (
        <text className="type" x="-40" y="50">
          {nodeDatum?.type}
        </text>
      )}
    </BOMCardStyle>
  );
}
//   <div className="itemName">{nodeDatum.itemName}</div>
//   <div className="width" />
//   <div className="box">
//     <RoundImgBox
//       src={
//         nodeDatum.thumbNail &&
//         nodeDatum.thumbNail.replace(
//           "src/main/prodmedia",
//           `${process.env.REACT_APP_BASE_URL}`
//         )
//       }
//     />
//     <div className="right">
//       <span className="classification"></span>
//       <br />
//       <span className="name">{nodeDatum.name}</span>{" "}
//     </div>
//   </div>
