import React from 'react';
import styled from 'styled-components';

import PCBStore from '../../store/PCBStore';

import { PageStyle } from '../../Style';
import txt from '../../Text';
import AnimationSelectBox from '../../molecule/AnimationSelectBox';

const PCBInfoStyle = styled(PageStyle)`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
`;
export default function LayerInnerozSection({ readOnly }) {
  const {
    setsearchBoxType,
    setsearchBoxTargetIndex,
    searchBoxType,
    searchBoxTargetIndex,
    stateObj,
    setStateByKey,
    setPCBInformationRef,
  } = PCBStore();

  const store = PCBStore();

  const layerOzObj = {
    1: 0,
    2: 0,
    4: 2,
    6: 4,
    8: 6,
    10: 8,
    12: 10,
  };
  const innerOzList = new Array(layerOzObj[stateObj.layer] * 2 || 0).fill(1).map((_, i) => {
    if (i % 2 === 0) {
      const targetInnerOz = `innerOz${Math.floor(i / 2) + 1}`;
      return (
        <AnimationSelectBox
          width="100%"
          height="40px"
          placeholder={`${txt.pcb.innerOz}${Math.floor(i / 2) + 1}`}
          state={stateObj[targetInnerOz]}
          setState={setStateByKey(targetInnerOz)}
          readOnly={readOnly}
        >
          {[
            { value: '0.5oz', name: '0.5oz' },
            { value: '1.0oz', name: '1.0oz' },
            { value: '2.0oz', name: '2.0oz' },
            { value: '3.0oz', name: '3.0oz' },
            { value: '4.0oz', name: '4.0oz' },
            { value: '5.0oz', name: '5.0oz' },
            { value: '6.0oz', name: '6.0oz' },
            { value: '7.0oz', name: '7.0oz' },
            { value: '8.0oz', name: '8.0oz' },
            { value: 'ETC', name: 'ETC' },
          ].map((opt) => (
            <option value={opt.value}>{opt.name}</option>
          ))}
        </AnimationSelectBox>
      );
    }
    return <div className="half" />;
  });
  return (
    <PCBInfoStyle>
      <AnimationSelectBox
        width="100%"
        height="40px"
        placeholder="Layer"
        state={stateObj.layer}
        setState={setStateByKey('layer')}
        readOnly={readOnly}
      >
        {Object.keys(layerOzObj).map((key) => (
          <option key={key} value={key}>
            {key}
          </option>
        ))}
      </AnimationSelectBox>
      {innerOzList}
    </PCBInfoStyle>
  );
}
