import React from 'react';
import styled from 'styled-components';

const ProjectManageButtonStyle = styled.div`
  width: 80%;
  height: 24px;
  line-height: 24px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 4px;
  border: solid 1px var(--eciBlue);
  background-color: #f2f4f8;
  color: var(--eciBlue);
  font-weight: 600;
  opacity: ${(props) => (props.disable ? 0.4 : 1)};
  cursor: ${(props) => (props.disable ? '' : 'pointer')};
`;
export default function ProjectManageButton({ children, onClick, disable }) {
  return (
    <ProjectManageButtonStyle
      title={children + disable && ' 복구'}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {children}
      {disable && ' 복구'}
    </ProjectManageButtonStyle>
  );
}
