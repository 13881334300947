import txt from '../Text';

const fortyTwo = true;

export default fortyTwo;
/** inputGird용 단위 목록  */
export const unitObj = {
  weight: 'g',
  height: 'mm',
  width: 'mm',
  thickness: 'mm',
  modulus: 'Pa',
};

export const conditionByTypeObject = {
  add: {
    isRouteActive: false,
    isRouteReadOnly: false,
    routeSectionType: 'init',
    saveFuncType: 'post',
    isAttachmentEdit: false,
    isScrollWide: false,
  },
  detail: {
    isRouteActive: true,
    isRouteReadOnly: true,
    routeSectionType: '',
    saveFuncType: '',
    isAttachmentEdit: false,
    isScrollWide: true,
  },
  editS: {
    isRouteActive: true,
    isRouteReadOnly: false,
    routeSectionType: 'init',
    saveFuncType: '',
    isAttachmentEdit: false,
    isScrollWide: false,
  },
  editD: {
    // temp, reject관리
    isRouteActive: false,
    isRouteReadOnly: false,
    routeSectionType: 'init',
    saveFuncType: 'put',
    isAttachmentEdit: true,
    isScrollWide: false,
  },
  rejected: {
    // temp, reject관리
    isRouteActive: false,
    isRouteReadOnly: false,
    routeSectionType: '',
    saveFuncType: 'put',
    isAttachmentEdit: true,
    isScrollWide: false,
  },
  review: {
    isRouteActive: true,
    isRouteReadOnly: false,
    routeSectionType: '',
    saveFuncType: '',
    isAttachmentEdit: false,
    isScrollWide: true,
  },
};

export const uriByClassificationObj = {
  'Electronic Part': 'item/electronic-part',
  'ETC Part': 'item/electronic-part',
  'Mechanical Part': 'item/electronic-part',
  PCB: 'item/pcb',
  PBA: 'item/pba',
  'Master PBA': 'item/mpba',
  Board: 'item/board',
  Firmware: 'item/firmware',
  Product: 'item/product',
  'Item Mech': 'item/mech',
};

export const priceUnitList = ['￥', '₩', '$'];
export const { priceTypeList } = txt;
export const priceContractCondition = ['FCA', 'FDA', 'DDP'];
