import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import InsertPopup from './InsertPopup';
import useTokenAxios from '../../utility/hooks/useTokenAxios';

const InsertButtonStyle = styled.div`
  width: 80%;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: ${(p) => (p.active ? 'var(--eciBlue)' : '#A8A3CC')};
  ${(p) => !p.active && 'opacity:0.6'};
  border: solid 1px ${(p) => (p.active ? 'var(--eciBlue)' : 'var(--textGray)')};
  border-radius: 4px;
  background-color: ${(p) => (p.active ? '#f2f4f8' : '#D7D7D7')};
  ${(p) => p.active && 'cursor: pointer'};
  .a {
    ${(p) => !p.active && 'pointer-events: none'};

    color: var(--eciBlue);
    text-decoration: none;
    display: block;
  }
`;
export default function InsertButton({ onClick = () => {}, data, active }) {
  const modalRef = useRef(null);
  const [top, settop] = useState(0);
  const [left, setleft] = useState(0);
  const [isPopup, setisPopup] = useState(false);
  const tokenAxios = useTokenAxios();
  const handleClick = (e) => {
    // e.stopPropagation();
    setisPopup((bool) => !bool);
    settop(e.pageY);
    setleft(e.pageX);

    onClick();
  };
  const handleClickOutside = (e) => {
    if (!modalRef.current.contains(e.target)) {
      setisPopup(false);
    }
  };
  const onInsert = async (footprint) => {
    const response = await tokenAxios.get(`item/${data.id}`);
    const property = JSON.parse(
      response.data.result.data?.property && response.data.result.data?.property !== 'undefined'
        ? response.data.result.data?.property
        : '[]'
    );
    const propertyStr = property?.reduce((acc, cu) => {
      // 조건 확인: cu.specification이 1이거나, cu.specification이 undefined이고 cu.option이 0일 경우
      if (cu.option === 0) {
        // 기본 content 처리
        let contentStr = cu.content?.name ? cu.content.name : cu.content;
        // unit1, unit2가 있는 경우 contentStr에 추가
        if (contentStr) {
          // content가 숫자로 끝나는지 확인
          if (/\d$/.test(contentStr) && cu.unit1 && cu.unit1 !== '-') {
            contentStr += ` ${cu.unit1}`;
          }
          // content2가 있는 경우 contentStr에 추가
          if (cu.content2) {
            // content가 숫자로 끝나는지 확인
            contentStr += `~${cu.content2}`;
            if (/\d$/.test(contentStr) && cu.unit2 && cu.unit2 !== '-') {
              contentStr += ` ${cu.unit2}`;
            }
          }
        }
        // 문자열 합치기
        return `${acc}N=${cu.name}|V=${contentStr}|${cu.show ? 1 : 0}|`;
      }
      // 조건에 맞지 않는 경우, 이전 누적값을 반환
      return acc;
    }, '');
    const specification = property?.reduce((acc, cu) => {
      if (cu.specification) {
        return `${acc + (acc ? `,` : '')}${cu.name}=${
          cu.content?.name ? cu.content.name : cu.content
        }`;
      }
      return acc;
    }, '');

    window.location = `InsertSymbolToAltium:LibReference=${
      data.symbolName
    }|Library=${data.symbolPath?.replaceAll(
      '\\',
      '/'
    )}|partid=1|ModelType=SIM|N=PART_NO|V=${data.number}|0|N=Manufacturer|V=${
      data.produceOrganizationId[0]?.code1
    }|0|N=ManufacturerPartNumber|V=${
      data.produceOrganizationId[0]?.partNumber
    }|0|${propertyStr}Orientation=1|AddFootprint=${
      footprint?.split('@')[1] || footprint
    }|Comment=${specification}`;
  };

  useEffect(() => {
    if (active) {
      window.addEventListener('click', handleClickOutside);
      window.addEventListener('scroll', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('scroll', handleClickOutside);
    };
  }, []);
  return (
    <InsertButtonStyle onClick={handleClick} ref={modalRef} active={active}>
      {data.footprintName?.length > 1 && active ? (
        <>
          INSERT
          {isPopup && (
            <InsertPopup
              top={top}
              left={left}
              setisActive={setisPopup}
              footprintList={data.footprintName}
              onInsert={onInsert}
            />
          )}
        </>
      ) : (
        <div className="a" onClick={() => onInsert(data.footprintName[0])}>
          INSERT
        </div>
      )}
    </InsertButtonStyle>
  );
}
