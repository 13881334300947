import React from 'react';
import styled from 'styled-components';

import LoadingArrowAnimation from './LoadingArrowAnimation';
import BoldText from '../atom/BoldText';
import commonStore from '../store/commonStore';

const ArrowBoxStyle = styled.div`
  position: relative;
  .xButton {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 24px;
    font-weight: 600;
    color: var(--textDarkGray);
    z-index: 10;
    cursor: pointer;
  }
`;
const RetryButtonStyle = styled.div`
  color: var(--eciBlue);
  font-size: 16px;
  cursor: pointer;
`;

export default function LoadingArrowBox({ retry }) {
  const commonstore = commonStore();
  return (
    <ArrowBoxStyle>
      <LoadingArrowAnimation />{' '}
      <div
        className="xButton"
        onClick={() => {
          commonstore.setisLoading(false);
          commonstore.setloadingBoxType('SPINNER');
          commonstore.setloadingRequestCnt(0);
          commonstore.loadingOnExit();
        }}
      >
        x
      </div>
      <BoldText fontSize="26px" color="var(--eciBlue)">
        S a v i n g
      </BoldText>
      <br />
      <br />
      Please wait while we set
      <br />
      thing up for you!
      {retry && <RetryButtonStyle onClick={commonstore.loadingClickEvent}>Retry</RetryButtonStyle>}
    </ArrowBoxStyle>
  );
}
