import create from 'zustand';
import { defaultStoreSeed } from './defaultStore';

//  functions
const appendArr = (targetArray, valueArray) => {
  let tmpArr = structuredClone(targetArray);
  if (Array.isArray(valueArray)) {
    tmpArr = [...tmpArr, ...valueArray];
  } else {
    tmpArr = [...tmpArr, valueArray];
  }
  return tmpArr;
};
const insertArr = (targetArray, i, valueArray) => {
  const tmpArr = structuredClone(targetArray);
  if (Array.isArray(valueArray)) {
    tmpArr.splice(i, 0, valueArray);
  } else {
    tmpArr.splice(i, 0, valueArray);
  }

  return tmpArr;
};
const mPBAStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),

  //  all
  MPBAName: '', //  0:default,1:mpba,2:pba
  setMPBAName: (val) => {
    set(() => ({ MPBAName: val }));
  },
  MPBANumber: '', //  0:default,1:mpba,2:pba
  setMPBANumber: (val) => {
    set(() => ({ MPBANumber: val }));
  },
  mode: '0', //  0:default,1:mpba,2:pba
  setmode: (val) => {
    set(() => ({ mode: val }));
  },
  modeListByUser: [1, 1, 1],
  setmodeListByUser: (val) => {
    set(() => ({ modeListByUser: val }));
  },
  isOneByOne: '1',
  setisOneByOne: (val) => {
    set(() => ({ isOneByOne: val }));
  },
  MPBAList: [],
  deletedMPBAList: [], // mpba obj가 들어감
  setMPBAList: (val) => {
    set(() => ({ MPBAList: val }));
  },
  appendMPBAList: (itemArray) => {
    set(() => ({ MPBAList: appendArr(get().MPBAList, itemArray) }));
  },
  insertMPBAList: (itemObject, i) => {
    //  itemArray or oneItem
    set(() => ({ MPBAList: insertArr(get().MPBAList, i, itemObject) }));
  },
  filterMPBAListByKey: (key) => {
    const tmpArr = [...get().MPBAList];
    set(() => ({ MPBAList: tmpArr.filter((item) => item.id !== key) }));
  },
  deleteMPBAList: (obj) => {
    let tmpArr = [...get().MPBAList];
    const tmpDelArr = [...get().deletedMPBAList];
    const tmpPBAArr = [...get().PBAColumnList];
    let delIndex = -1;
    tmpArr = tmpArr.filter((item, i) => {
      if (item.id === obj.id) {
        delIndex = i;
      }
      return item.id !== obj.id;
    });
    tmpPBAArr.forEach((pbaColObj) => {
      if (delIndex > -1) {
        pbaColObj.items.splice(delIndex, 1);
      }
    });
    set(() => ({
      MPBAList: tmpArr,
      deletedMPBAList: [...tmpDelArr, obj],
      // obj의 id가 새로 추가된 mpbaObj의 id인 경우 추가 안함. api확인 후 추가.
      PBAColumnList: tmpPBAArr,
    }));
  },
  PBAColumnList: [],
  setPBAColumnList: (val) => {
    set(() => ({ PBAColumnList: val }));
  },
  appendPBAColumnList: (partObjList) => {
    const tmpArr = structuredClone(get().PBAColumnList);
    tmpArr.forEach((partObj) => {
      partObj.items = appendArr(partObj.items, partObjList);
    });
    set(() => ({ PBAColumnList: tmpArr }));
  },
  insertPBAColumnList: (itemObject, i) => {
    //  itemArray or oneItem
    const tmpArr = structuredClone(get().PBAColumnList);
    tmpArr.forEach((partObj) => {
      partObj.items = insertArr(partObj.items, i, itemObject);
    });
    set(() => ({ PBAColumnList: tmpArr }));
  },
  filterPBAColumnListByKey: (key) => {
    const tmpArr = structuredClone(get().PBAColumnList);
    tmpArr.forEach((partObj) => {
      partObj.items = partObj.items.filter((item) => item.id !== key);
    });
    set(() => ({ PBAColumnList: tmpArr }));
  },

  isModal: false,
  setisModal: (val) => {
    set(() => ({ isModal: val }));
  },
  //  mpba
  modalClassificationTree: [],
  setmodalClassificationTree: (val) => {
    set(() => ({ modalClassificationTree: val }));
  },
  modalSelectedItemClassification: {}, // classId
  setmodalSelectedItemClassification: (val) => {
    set(() => ({ modalSelectedItemClassification: val }));
  },
  modalItemList: [],
  setmodalItemList: (val) => {
    set(() => ({ modalItemList: val }));
  },
  totalPage: '',
  settotalPage: (val) => {
    set(() => ({ totalPage: val }));
  },
  searchText: '',
  setsearchText: (val) => {
    set(() => ({ searchText: val }));
  },
  pageNum: 0,
  setpageNum: (val) => {
    set(() => ({ pageNum: val }));
  },
  rowsPerPage: 6,
  setrowsPerPage: (val) => {
    set(() => ({ rowsPerPage: val }));
  },
  modalTempCheckedList: [], // 추가로 그 아이템 정보
  setmodalTempCheckedList: (val) => {
    set(() => ({ modalTempCheckedList: val }));
  },
  modalCheckedItemList: [],
  setmodalCheckedItemList: (val) => {
    set(() => ({ modalCheckedItemList: val }));
  },
  changeCheckedItemListCnt: (val, index) => {
    const tmpArr = structuredClone(get().modalCheckedItemList);
    tmpArr[index].cnt = val;
    set(() => ({ modalCheckedItemList: tmpArr }));
  },

  newItemList: [], // 추가로 그 아이템 정보
  setnewItemlist: (val) => {
    set(() => ({ newItemList: val }));
  },
  yellowIndexArray: [], // id담은 array
  appendYellowIndexArray: (indexArr) => {
    let tmpArr = get().yellowIndexArray;
    if (Array.isArray(indexArr)) {
      tmpArr = [...tmpArr, ...indexArr];
    } else {
      tmpArr = [...tmpArr, indexArr];
    }
    set(() => ({ yellowIndexArray: [...tmpArr] }));
  },
  changeRowYellow: (i) => {
    const tmpMPBAList = get().MPBAList;
    const tmpYellowArr = get().yellowIndexArray;
    tmpMPBAList[i].isYellow = true;
    tmpYellowArr.push(i);
    set(() => ({ MPBAList: tmpMPBAList }));

    set(() => ({ yellowIndexArray: tmpYellowArr }));
  },
  dismissRowYellow: (index) => {
    // console.log(index);
    const tmpMPBAList = get().MPBAList;
    const tmpYellowArr = get().yellowIndexArray;
    if (tmpYellowArr.indexOf(index) === tmpYellowArr.lastIndexOf(index)) {
      tmpMPBAList[index].isYellow = false;
    }
    tmpYellowArr.splice(tmpYellowArr.lastIndexOf(index), 1);

    set(() => ({ MPBAList: tmpMPBAList }));
    set(() => ({ yellowIndexArray: tmpYellowArr }));
  },
  dismissAllYellow: () => {
    const tmpMPBAList = get().MPBAList;
    set(() => ({
      MPBAList: tmpMPBAList.map((item) => ({ ...item, isYellow: false })),
    }));

    set(() => ({ yellowIndexArray: [] }));
  },
  //  pba
  isWriting: false,
  setisWriting: (val) => {
    set(() => ({ isWriting: val }));
  },
  newPBAOption: '-1',
  setnewPBAOption: (val) => {
    set(() => ({ newPBAOption: val }));
  },
  targetPart: {},
  settargetPart: (val) => {
    set(() => ({ targetPart: val }));
  },
  newPBAPartNumber: '',
  setnewPBAPartNumber: (val) => {
    set(() => ({ newPBAPartNumber: val }));
  },
  changedPBAColumnObject: {},
  appendChangedPBAColumnObject: (key, val) => {
    const tmpObj = { ...get().changedPBAColumnObject };
    tmpObj[key] = val;
    set(() => ({ changedPBAColumnObject: tmpObj }));
  },
  initTempVariables: () => {
    const tmpMPBAList = get().MPBAList;

    set(() => ({
      isModal: false,
      //  mpba
      modalSelectdeItemClassification: '', // classId
      modalItemList: [],
      modalTempCheckedList: [], // 추가로 그 아이템 정보
      modalCheckedItemList: [],
      newItemList: [], // 추가로 그 아이템 정보
      yellowIndexArray: [], // id담은 array
      MPBAList: tmpMPBAList.map((item) => ({ ...item, isYellow: false })),
      deletedMPBAList: [],

      //  pba
      isWriting: false,
      changedPBAColumnIdList: [],
      changedIdList: [],
      newPBAOption: '',
      newPBAPartNumber: '',
    }));
  },
  //  default
  defaultCheckedPBAColumnList: [],
  setdefaultCheckedPBAColumnList: (val) => {
    set(() => ({ defaultCheckedPBAColumnList: val }));
  },
  isRouteActive: false,
  setisRouteActive: (val) => {
    set(() => ({ isRouteActive: val }));
  },

  initmPBAStore: () => {
    set(() => ({
      //  all
      mode: 0, //  0:default,1:mpba,2:pba
      isOneByOne: '1',
      MPBAList: [],
      deletedMPBAList: [],

      PBAColumnList: [],
      isModal: false,
      //  mpba
      modalClassificationTree: [{}],
      modalSelectdeItemClassification: '', // classId
      modalItemList: [],
      modalTempCheckedList: [], // 추가로 그 아이템 정보
      modalCheckedItemList: [],
      newItemList: [], // 추가로 그 아이템 정보
      yellowIndexArray: [], // id담은 array

      //  pba
      isWriting: false,
      changedPBAColumnIdList: [],
      changedIdList: [],
      newPBAOption: '',
      newPBAPartNumber: '',
    }));
  },
}));
export default mPBAStore;
