import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import LineTitle from '../../atom/LineTitle';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import AnimationInput from '../../molecule/AnimationInput';
import AnimationSearchInput from '../../molecule/AnimationSearchInput';
import AnimationSelectBox from '../../molecule/AnimationSelectBox';
import CheckBox from '../../molecule/CheckBox';
import InputGrid from '../../organism/InputGrid';
import Table from '../../molecule/Table';
import TextBox from '../../molecule/TextBox';
import UnitInput from '../../molecule/UnitInput';
import coStore from '../../store/coStore';
import {
  BlueBackGroundBox,
  LightGrayInputContainer,
  PageStyle,
  RowContainerStyle,
} from '../../Style';
import Input from '../../atom/Input';
import DeleteButton from '../../atom/DeleteButton';
import { CoBottomInputList, coTopInputList } from '../utility';
import txt from '../../Text';

const InformationSection = styled(PageStyle)`
  .half {
    width: 50%;
  }
`;
export default function CoInformationSection({ readOnly }) {
  const store = coStore();
  const informationRef = useRef();

  useEffect(() => {
    store.setinformationRef(informationRef);
  }, []);
  return (
    <PageStyle ref={informationRef}>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        Change Order Information
      </LineTitle>{' '}
      <InputGrid readOnly={readOnly} modulestore={store} inPutList={coTopInputList} />
      <RowContainerStyle>
        <AnimationSelectBox
          width="100%"
          height="40px"
          placeholder="COST 변동여부"
          state={store.stateObj.costDifferent}
          setState={store.setStateByKey('costDifferent')}
          required
          readOnly={readOnly}
        >
          <option value="1">yes</option>
          <option value="2">no</option>
        </AnimationSelectBox>
        {store.stateObj.costDifferent === '1' && (
          <UnitInput
            width="100%"
            height="40px"
            placeholder="COST 변동 금액"
            state={store.stateObj.costDifference}
            setState={store.setStateByKey('costDifference')}
            optionArray={[{ value: '원', name: '원' }]}
            required
            readOnly={readOnly}
          />
        )}
      </RowContainerStyle>
      <InputGrid readOnly={readOnly} modulestore={store} inPutList={CoBottomInputList} />
      <CheckBox
        readOnly={readOnly}
        title="CO영향"
        width="100%"
        height="200px"
        state={store.stateObj.effectId}
        setstate={store.setStateByKey('effectId')}
        checkList={txt.crco.coCheckBoxCheckList}
        required
      />
      <br />
      <br />
      <AnimationSelectBox
        width="50%"
        height="40px"
        placeholder="CO 중요도"
        state={store.stateObj.importance}
        setState={store.setStateByKey('importance')}
        required
        readOnly={readOnly}
      >
        <option value="1">A</option>
        <option value="2">B</option>
        <option value="3">C</option>
      </AnimationSelectBox>
      <BlueBackGroundBox>
        <AnimationInput
          width="50%"
          height="40px"
          placeholder="CO 제목"
          state={store.stateObj.name}
          setState={store.setStateByKey('name')}
          required
          readOnly={readOnly}
        />
        <TextBox
          width="100%"
          height="100px"
          placeholder="CO 내용"
          required
          state={store.stateObj.content}
          setState={store.setStateByKey('content')}
          readOnly={readOnly}
        />
        <br />
      </BlueBackGroundBox>
      <br />
      <br />
      <AnimationSearchInput
        width="100%"
        height="40px"
        placeholder="Related CR"
        state=""
        onClick={() => {
          store.setmodalType('changeRequestsId');
          store.setisModal(true);
        }}
        backgroundColor="var(--eciBlue)"
        readOnly={readOnly}
      />
      <Table minHeight="120px">
        <TableIndexRow
          widthArray={[5, 5, 4, 4, 4, 4, 1]}
          itemArray={['CR Number', 'Type', '이유', '중요도', '출처', '제목']}
        />
        {store.stateObj.changeRequestsId.map((crObj, i) => (
          <TableRow
            key={i}
            itemArray={[
              crObj.crNumber || crObj.number,
              // check : type추가
              crObj.crReason?.name || crObj.reason,
              crObj.crImportance || crObj.importanceId,
              crObj.crSource?.name || crObj.sourceId?.name,
              crObj.itemName || crObj.affectedModuleId?.name,
              !readOnly && (
                <DeleteButton
                  height="20px"
                  fontSize="px"
                  width="5px"
                  onDelete={() => store.onDeleteModuleList(crObj.itemId)}
                />
              ),
            ]}
            widthArray={[5, 5, 4, 4, 4, 4, 1]}
          />
        ))}
      </Table>
      <br />
      <br />
      <AnimationSearchInput
        width="100%"
        height="40px"
        placeholder="Affected Item"
        state=""
        required
        onClick={() => {
          store.setmodalType('changeModuleIds');
          store.setisModal(true);
        }}
        backgroundColor="var(--eciBlue)"
        readOnly={readOnly}
      />
      <Table minHeight="120px" innerWidth="1200px">
        <TableIndexRow
          width="1200px"
          widthArray={[5, 5, 5, 8, 4, 4, 5, 5]}
          itemArray={[
            'CR Number',
            'Item Number',
            'Item Name',
            'Item Classification',
            'Revision',
            'Status',
            '변경 내용',
            '변경 항목',
          ]}
        />
        {store.stateObj.changeModuleIds.map((coObj, i) => (
          <TableRow
            key={i}
            width="1200px"
            widthArray={[5, 5, 5, 8, 4, 4, 5, 4, 1]}
            itemArray={[
              coObj.crNumber,
              coObj.number,
              coObj.name,
              coObj.itemClassification?.name || coObj.itemClassification,
              coObj.revision,
              coObj.status,
              <LightGrayInputContainer height="30px" width="200px" styleCss="cursor:pointer;">
                {!readOnly && <div className="material-icons">edit</div>}
                <Input
                  width="80%"
                  state={coObj.content}
                  setState={store.onChangeModuleContent(i)}
                  readOnly={readOnly}
                />
              </LightGrayInputContainer>,
              <LightGrayInputContainer
                height="30px"
                width="200px"
                styleCss="cursor:pointer;"
                onClick={() => {
                  if (!readOnly) {
                    store.onChangeModulefeature(i)();
                  }
                }}
              >
                {!readOnly && <div className="material-icons">search</div>}
                {coObj.feature?.name1 || coObj.feature?.name}
              </LightGrayInputContainer>,
              !readOnly && (
                <DeleteButton
                  height="20px"
                  fontSize="px"
                  width="5px"
                  onDelete={() => store.onDeleteModuleList(coObj.id || coObj.itemId)}
                />
              ),
            ]}
          />
        ))}
      </Table>
    </PageStyle>
  );
}
