import React from 'react';
import styled, { keyframes } from 'styled-components';

const BarStyle = styled.div`
  position: relative;
  width: calc(${(props) => props.width});
  height: ${(props) => props.height};
  overflow: visible;
  border-right: ${(props) => props.rightBorder && 'dotted var(--eciBlue) 2px'};
  .title {
    position: absolute;
    left: calc(50%);
  }
  .bottom {
    bottom: -24px;
    font-size: 14px;
    white-space: nowrap;
    color: var(--textDarkGray);
    transform: translate(-50%);
  }
`;
const grow = keyframes`
  0%{
    transform: scaleY(0%) translate(-50%,-60%);
  }
  100%{
    transform: scaleY(100%) translate(-50%,0);

  }
`;
const OneBarStyle = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: ${(prop) => prop.barWidth};
  height: calc(${(prop) => prop.height});
  border-radius: ${(props) => (props.last ? '10px 11px 0 0' : 0)};
  background-color: ${(props) => props.color};
  transform: translate(-50%);
  opacity: ${(props) => props.opacity};
  .topTotal {
    opacity: ${(props) => (props.last ? 1 : 0)};
    font-size: 12px;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%);
    color: var(--textDarkGray);
  }
  animation: ${grow} 0.7s;
`;
export default function BarOfGraph({
  bottomTitle,
  colorArr,
  pointArr,
  rightBorder,
  width = '100px',
  height = '300px',
  barWidth = '20px',
  maxY = 40,
}) {
  const tmpArr = [...pointArr];
  const cumulArr = [...pointArr];
  cumulArr.reverse();
  tmpArr.reverse();
  tmpArr.forEach((point, i) => {
    cumulArr[i] = i > 0 ? cumulArr[i - 1] + point : point;
  });
  let last = cumulArr.length - 1;
  while (tmpArr[last] === 0) {
    last -= 1;
  }
  const barArr = cumulArr
    .map((point, i) => (
      <OneBarStyle
        key={i}
        barWidth={barWidth}
        height={`${(point / maxY) * 100}%`}
        color={colorArr[colorArr.length - i - 1]}
        last={i === last}
        title={tmpArr[i]}
        opacity={pointArr[pointArr.length - i - 1] === 0 ? 0 : 1}
      >
        <div className="topTotal">{cumulArr[cumulArr.length - 1]}</div>
      </OneBarStyle>
    ))
    .reverse();
  return (
    <BarStyle width={width} height={height} rightBorder={rightBorder}>
      {barArr}
      <div className="title bottom">{bottomTitle}</div>
    </BarStyle>
  );
}
