import {
  appendAttachmentFormData,
  appendStateObj,
  deleteFormdataKey,
  setAttachmentArrays,
} from '../utility/Utility';
import useTokenAxios from '../utility/hooks/useTokenAxios';
import txt from '../Text';

export const appendSubsidiaryForm = (edit, store) => {
  const formData = new FormData();
  const { stateObj } = store;
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, [
    'property',
    'classificationId',
    'buyerOrganizationId',
    'produceOrganizationId',
    'carTypeId',
    'thumbnail',
  ]);
  formData.append(
    'classificationId',
    stateObj.classificationId.id || stateObj.classificationId.classId
  );
  if (stateObj.thumbnail?.size) {
    formData.append('thumbnail', stateObj.thumbnail);
  }
  if (stateObj.buyerOrganizationId && stateObj.buyerOrganizationId > -1) {
    formData.append('buyerOrganizationId', stateObj.buyerOrganizationId.id);
  }
  formData.append(
    'produceOrganizationId',
    stateObj.produceOrganizationId.map((item) => item.id)
  );
  formData.append(
    'partNumber',
    stateObj.produceOrganizationId.map((item) => item.partNumber)
  );
  formData.append('carTypeId', stateObj.carTypeId?.id || '');

  formData.append('property', JSON.stringify(stateObj.property));
  appendAttachmentFormData(formData, edit, store);

  return formData;
};
export const useGetSubsidiaryData = (id, electronicPartstore, callBack) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/item/${id}`);
    const { data } = response.data.result;
    const { setStateObjWithData, deleteStateByKey, setStateByKey, setrouteNumber } =
      electronicPartstore;
    // setstate
    setStateObjWithData(data);
    if (!data.produceOrganizationId[0]) {
      setStateByKey('produceOrganizationId')([{ id: '', name: '' }]);
    }
    ['property'].forEach((key) => {
      deleteStateByKey(key);
    });
    //  design attachment
    setAttachmentArrays(data.attachmentList, electronicPartstore);
    setrouteNumber(data.routeId);

    const jsonData = data.property !== ' ' ? JSON.parse(data.property) : {};

    if (!jsonData[0]) {
      callBack(data);
    } else {
      setStateByKey('property')(jsonData);
    }
  };
};
export const subsidiaryTopInputList = [
  {
    inputType: 'DB', //  체크
    name: 'Item Classification',
    requestName: 'classificationId',
    option: 0,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 2,
    title: 'Array Size',
  },
  {
    inputType: 'KEY_IN',
    name: 'Item Number',
    requestName: 'itemNumber',
    option: 0,

    choiceFields: [],
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: 'Item Name',
    requestName: 'name',
    option: 0,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: '비고',
    requestName: 'note',
    option: 1,
    choiceFields: [],
    gray: false,
    readOnly: false,
    cell: 2,
  },
  {
    inputType: 'KEY_IN',
    name: txt.subsidiary.detail,
    requestName: 'specification',
    option: 1,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
];
export const subsidiaryTopNoRouteInputList = [
  {
    inputType: 'DB', //  체크
    name: 'Item Classification',
    requestName: 'classificationId',
    option: 0,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 2,
    title: 'Array Size',
  },
  {
    inputType: 'KEY_IN',
    name: 'Item Number',
    requestName: 'itemNumber',
    option: 0,
    gray: true,
    readOnly: true,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: 'Item Name',
    requestName: 'name',
    option: 0,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: '비고',
    requestName: 'note',
    option: 1,
    choiceFields: [],
    gray: false,
    readOnly: false,
    cell: 2,
  },
  {
    inputType: 'KEY_IN',
    name: txt.subsidiary.detail,
    requestName: 'specification',
    option: 1,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
];
