import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ScrollContainer from '../../organism/ScrollContainer';
import AttachmentSection from '../../organism/AttachmentSection';
import Button from '../../atom/Button';
import RouteInitSection from '../../organism/RouteInitSection';
import ModalBox from '../../organism/ModalBox';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import PaginationTable from '../../molecule/PaginationTable';
import BoldText from '../../atom/BoldText';
import RouteSection from '../../organism/RouteSection';

import { appendPBAForm, useGetPBAData } from '../utility';
import { PageStyle, SectionGap } from '../../Style';
import { conditionByTypeObject } from '../../utility/Source';

import DataStateSection from '../../organism/DataStateSection';

import PBAStore from '../../store/PBAStore';
import PBAInformationSection from '../organism/PBAInformation';
import TreeChartContainer from '../../organism/TreeChartContainer';
import useGetReviseData from '../../utility/hooks/useGetReviseData';
import useSaveFunction from '../../utility/hooks/useSaveFunction';
import useTempSaveFunction from '../../utility/hooks/useTempSaveFunction';
import usePageConditionList from '../../utility/hooks/usePageConditionList';
import SearchBoxGroup from '../../organism/SearchBoxGroup';
import txt from '../../Text';

const RoundWhiteBoxStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  height: 30px;
  width: calc(100% + 8px);
  transform: translate(-8px);
  background-color: white;
  color: inherit;
  font-size: inherit;
  border-radius: 10px;
  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .material-icons {
    font-size: 14px;
    color: var(--textGray);
    cursor: pointer;
  }
`;
export default function PBAPage() {
  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const type = (searchParam.get('phase') * 1 || 1) >= 2 ? 'review' : params.type;
  const navigate = useNavigate();
  const reviseId = searchParam.get('reviseId') || '';

  // 페이지 상태 관리
  const {
    setid,
    isRouteActive,
    setisRouteActive,
    setsearchBoxType,
    searchBoxType,
    searchBoxTargetIndex,

    stateObj,
    setStateByKey,

    // ref
    informationRef,
    relatedMPBARef,
    setrelatedMPBARef,
    attachmentRef,
    routeRef,
  } = PBAStore();

  const PBAstore = PBAStore();

  //  모달관리
  const [isModalBox, setisModalBox] = useState(false);

  //    함수관리
  const saveFunc = useSaveFunction(
    'pba-module',
    appendPBAForm,
    PBAstore,
    conditionByTypeObject[type].saveFuncType === 'put',
    params.id,
    reviseId
  );
  const saveTempFunc = useTempSaveFunction(
    'pba-module',
    appendPBAForm,
    PBAstore,
    conditionByTypeObject[type].saveFuncType === 'put',
    params.id
  );
  const [pageCondition, setpageCondition, mpbaListData] = usePageConditionList('mpba');
  const getPBAPartData = useGetPBAData(params.id, PBAstore);
  const [treeData] = useGetReviseData(`pba-tree/${params.id}`, reviseId, PBAstore);
  const onMPBAModalClick = (item) => {
    setStateByKey('mpba')(item);
    setisModalBox(false);
  };
  useEffect(() => {
    setisRouteActive(conditionByTypeObject[type].isRouteActive);
    setid(params.id && params.id);
    if (params.id === 'new') {
      setStateByKey('classificationId')({
        id: searchParam.get('classId'),
        name: searchParam.get('classificationName'),
      });
    }
    if (params.id !== 'new') {
      getPBAPartData();
    }

    //  attachmentTagOptionList
    return () => {
      PBAstore.initDefaultStore();
      PBAstore.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  useEffect(() => {
    if (searchParam.get('phase') === '2' && stateObj.mpba.id > -1 && stateObj.mpba.id) {
      navigate(`/bom/add/${stateObj.mpba.id}`);
    }
  }, [stateObj]);
  return (
    <PageStyle>
      <ModalBox isActivate={isModalBox} setisActivate={setisModalBox}>
        <PaginationTable
          width="100%"
          minHeight="300px"
          innerWidth="100%"
          totalPage={pageCondition.totalPage}
          rowsPerPageDefault={10}
          pageNum={pageCondition.pageNum}
          setRowsPerPage={(val) => {
            setpageCondition('rowsPerPage', val);
          }}
          rowsPerPage={pageCondition.rowsPerPage}
          setpageNum={(val) => {
            setpageCondition('pageNum', val);
          }}
          noshadow
        >
          <TableIndexRow
            widthArray={[8, 6, 9, 4]}
            itemArray={['Master PBA Number', 'Master PBA Name', 'Item Classification', 'Revision']}
          />
          {mpbaListData &&
            mpbaListData.map((item, i) => {
              return (
                <TableRow
                  onClick={() => onMPBAModalClick(item)}
                  key={i}
                  widthArray={[8, 6, 9, 4]}
                  itemArray={[item.number, item.name, item.classification, item.revision]}
                />
              );
            })}
        </PaginationTable>
      </ModalBox>
      <ScrollContainer
        scrollRefList={[
          [informationRef, 'Item Information'],
          [relatedMPBARef, 'Related Master PBA'],
          [attachmentRef, 'Item Attachment'],
          [routeRef, 'Route Information'],
        ]}
        searchBoxComponent={<SearchBoxGroup store={PBAstore} />}
        tempButtonTitle={params.type !== 'detail' && !reviseId ? 'save as draft' : undefined}
        tempButtonOnclick={saveTempFunc}
        additionalPartTop={reviseId && <TreeChartContainer treeData={treeData} />}
      >
        <PBAInformationSection
          readOnly={isRouteActive}
          pbaProduceOrganizationId={stateObj.pbaProduceOrganizationId}
          setpbaProduceOrganizationId={setStateByKey('pbaProduceOrganizationId')}
          searchBoxType={searchBoxType}
          setsearchBoxType={setsearchBoxType}
          searchBoxTargetIndex={searchBoxTargetIndex}
        />
        <SectionGap />
        {type !== 'add' && type !== 'editD' && (
          <div className="flex">
            {PBAstore?.routeData?.workflowphase === 'COMPLETE' && (
              <BoldText
                onClick={() => {
                  navigate(`/pba-bom/bom/detail/${stateObj.id}`);
                }}
                fontSize="16px"
                cssStyle="display:flex;align-items:center;cursor:pointer;margin-right:60px;"
                color="var(--eciBlue)" // mpba 권한 조건 추가
              >
                PBA BOM
                <div className="material-icons">open_in_new</div>
              </BoldText>
            )}
            <BoldText
              onClick={() => {
                navigate(`/bom/detail/${stateObj.mpba.id}`);
              }}
              cssStyle="display:flex;align-items:center;cursor:pointer;"
              fontSize="16px"
              color="var(--eciBlue)" // mpba 권한 조건 추가
            >
              Related Master PBA BOM
              <div className="material-icons">open_in_new</div>
            </BoldText>
          </div>
        )}
        <DataStateSection
          required
          title="Related Master PBA"
          placeholder="Related Master PBA"
          readOnly={isRouteActive || (type !== 'add' && type !== 'editD')}
          state={stateObj.mpba?.name}
          onSearchClick={() => setisModalBox(true)}
          setsectionRef={setrelatedMPBARef}
          dataArray={[
            {
              title: 'Master PBA Part Number',
              content: (
                <RoundWhiteBoxStyle>
                  <div className="text">{stateObj.mpba?.number}</div>
                  {isRouteActive && (
                    <div
                      className="material-icons"
                      onClick={() => {
                        navigate(`/bom/detail/${stateObj.mpba.id}`);
                      }}
                    >
                      open_in_new
                    </div>
                  )}
                </RoundWhiteBoxStyle>
              ),
            },
            {
              title: 'Master PBA Name',
              content: stateObj.mpba.name,
            },
            { title: 'Revision', content: stateObj.mpba.revision },
          ]}
        />
        <AttachmentSection
          editMode={conditionByTypeObject[type].isAttachmentEdit}
          title="Item Attachment"
          readOnly={isRouteActive}
          moduleStore={PBAstore}
        />
        <SectionGap />
        {!isRouteActive && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveFunc}
            condition={PBAstore.getisConditionFullfill()}
          >
            Save and Route
          </Button>
        )}
        {isRouteActive &&
          (conditionByTypeObject[type].routeSectionType === 'init' ? (
            <RouteInitSection
              activate
              routeType="Pba"
              itemId={PBAstore.id}
              moduleStore={PBAstore}
              afterUrl="/"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={PBAstore}
            />
          ))}
        <SectionGap />
      </ScrollContainer>
    </PageStyle>
  );
}
