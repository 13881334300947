import React from 'react';
import styled from 'styled-components';

import BoldText from '../atom/BoldText';
import Logo from '../static/logo1.svg';

const SpinnerStyle = styled.div`
  position: relative;
  .loadingLogo {
    width: 160px;
  }
  svg {
    position: absolute;
    top: -100px;
    left: 0;
    width: 400px;
    height: 400px;
    animation: spin 4s infinite;
  }
  .texxt {
    font-size: 12px;
    color: white;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }
`;
export default function LoadingSpinner() {
  return (
    <SpinnerStyle>
      <svg>
        <circle
          className="background"
          cx="200"
          cy="200"
          r="170"
          stroke="white"
          strokeWidth="8"
          fill="none"
          strokeDashoffset={0}
          strokeDasharray={85 * Math.PI}
        />
        <circle
          className="background"
          cx="200"
          cy="200"
          r="170"
          stroke="rgba(255, 255, 255, 0.3)"
          strokeWidth="8"
          fill="none"
        />
      </svg>
      <BoldText fontSize="26px" color="white">
        L o a d i n g
      </BoldText>
      <br />
      <img className="loadingLogo" src={Logo} alt="?" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="texxt">All caught up! What`s next?</div>
    </SpinnerStyle>
  );
}
