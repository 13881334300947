import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import ModuleTopNavigation from '../molecule/ModuleTopNavigation';
import { ModuleStyle } from '../Style';

import unitCostStore from '../store/unitCostStore';

const BoardBomStyle = styled(ModuleStyle)`
  .con {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .grayBox {
    font-size: 14px;
    border-radius: 50px;
    height: 32px;
    line-height: 32px;
    padding: 0 18px;
    background-color: rgb(10, 0, 85, 0.3);
    color: white;
    font-weight: 600;
  }
  .blueText {
    color: var(--eciBlue);
    font-size: 14px;
    font-weight: 600;
  }
`;
export default function UnitCostModule() {
  const store = unitCostStore();
  return (
    <BoardBomStyle>
      <ModuleTopNavigation title="Unit Costs" navList={[]}>
        <div className="con">
          <div className="grayBox">{store.partNumber}</div>
          <span className="blueText">{store.name}</span>
        </div>
      </ModuleTopNavigation>
      <Outlet />
    </BoardBomStyle>
  );
}
