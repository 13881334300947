import React from 'react';
import styled from 'styled-components';
import BoldText from '../../atom/BoldText';

const TodoCardStyle = styled.div`
  position: relative;
  width: 95%;
  height: 70px;
  border-radius: 10px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  padding-left: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ::before {
    content: ' ';
    height: 100%;
    width: 10px;
    position: absolute;
    border-radius: inherit 0 0 inherit;
    background-color: ${(props) => props.color};
    left: 0px;
    top: 0px;
  }
  .topaPart {
    display: flex;
    justify-content: space-between;
    .number {
      font-size: 11px;
      color: var(--textDarkGray);
      width: ${(props) => (props.numberWide ? '50%' : '100%')};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 22px;
      line-height: 22px;
    }
  }
  .gap {
    height: 8px;
  }
  margin-bottom: 16px;
  cursor: pointer;
`;
const StateBox = styled.div`
  background-color: ${(props) => props.backgroundColor || 'var(--textGray)'};
  color: ${(props) => props.color || 'var(--textDarkGray)'};
  border-radius: 10px;
  text-align: center;
  max-width: 50%;
  width: max-content;
  padding: 2px 6px;
  gap: 8px;
`;
export default function TodoCard({
  number,
  title,
  state,
  color = 'var(--eciBlue)',
  onClick,
  projectType,
}) {
  const visibleStateObj = {
    tempsave1: 1,
    tepsave2: 1,
    'temp save': 1,
    rejected: 1,
    revise: 1,
  };
  return (
    <TodoCardStyle numberWide={!!state} onClick={onClick} color={color}>
      <div className="topaPart">
        <div className="number">{number}</div>
        {projectType && (
          <StateBox backgroundColor="gray" color="white">
            {projectType}
          </StateBox>
        )}

        {state && visibleStateObj[state] && (
          <StateBox>
            {state === 'tempsave1' || state === 'tempsave2' ? 'temp save' : state}
          </StateBox>
        )}
      </div>
      <div className="gap" />
      <BoldText color="var(--eciBlue)" fontSize="18px">
        {title}
      </BoldText>
    </TodoCardStyle>
  );
}
