import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import ScrollContainer from '../../organism/ScrollContainer';
import AttachmentSection from '../../organism/AttachmentSection';
import Button from '../../atom/Button';
import RouteInitSection from '../../organism/RouteInitSection';

import RouteSection from '../../organism/RouteSection';
import { PageStyle, SectionGap } from '../../Style';

import { appendPCBForm, appendPCBPBAForm, appendSchematicForm, useGetPCBData } from '../utility';

import { conditionByTypeObject } from '../../utility/Source';
import PCBStore, { designAttachmentStore } from '../../store/PCBStore';

import PCBInformationSection from '../organism/PCBInformationSection';
import PCBInformationSection2 from '../organism/PCBInformationSection2';
import TreeChartContainer from '../../organism/TreeChartContainer';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import useGetReviseData from '../../utility/hooks/useGetReviseData';
import useSaveFunction from '../../utility/hooks/useSaveFunction';
import useTempSaveFunction from '../../utility/hooks/useTempSaveFunction';
import SearchBoxGroup from '../../organism/SearchBoxGroup';

import SchematicDesignUploadSection from '../organism/SchematicDesignUploadSection';
import PCBDesignUploadSection from '../organism/PCBDesignUploadSection';
import CompareSection from '../organism/CompareSection';
import txt from '../../Text';
import LayerInnerozSection from '../organism/LayerInnerozSection';
import PurchaseSection from '../organism/PurchaseSection';
import { userStore } from '../../store/commonStore';

export default function PCBPage() {
  //    ---CONST
  const tokenAxios = useTokenAxios();
  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const [phase, setphase] = useState(searchParam.get('phase') * 1 || 1); // phase 1 : info, 2: schematic, 3: pcb, 4: review, detail
  const { type } = params;
  const {
    stateObj,
    setStateByKey,
    // ref
    informationRef,
    PCBInformationRef,
    attachmentRef,
    routeRef,

    setid,
    isRouteActive,
    setisRouteActive,
  } = PCBStore();
  //      ---MODEL
  const pcbstore = PCBStore();
  const designattachmentstore = designAttachmentStore();
  const reviseId = searchParam.get('reviseId') || '';
  const [treeData] = useGetReviseData(`pcb-tree/${params.id}`, reviseId, pcbstore);
  const { userData } = userStore();
  //      ---ACTION
  const editIdObj = {
    1: {
      url: 'pcb',
      appendFunc: appendPCBForm,
      editId: params.id === 'new' ? '' : params.id,
      attachmentTag: [{ id: 1, name: 'ETC' }],
    },
    2: {
      url: 'pcb',
      appendFunc: appendPCBForm,
      editId: params.id === 'new' ? '' : params.id,
      attachmentTag: [{ id: 1, name: 'ETC' }],
    },
    3: {
      url: 'schematic',
      appendFunc: appendSchematicForm(designattachmentstore),
      editId: stateObj.schematicId,
      attachmentTag: [
        { id: 2, name: 'ETC' },
        { id: 3, name: '회로 DR', option: 1 },
      ],
    },
    4: {
      url: 'pba',
      appendFunc: appendPCBPBAForm(designattachmentstore),
      editId: stateObj.pbaId,
      attachmentTag: [
        { id: 2, name: 'ETC' },
        { id: 10, name: 'PCB DR', option: 1 },
      ],
    },

    5: {
      // review
      url: 'pba',
      appendFunc: appendPCBPBAForm(designattachmentstore),
      editId: stateObj.pbaId,
      attachmentTag: [{ id: 2, name: 'ETC' }],
    },
    6: {
      url: 'pcb',
      appendFunc: appendPCBForm,
      editId: params.id === 'new' ? '' : params.id,
      attachmentTag: [{ id: 1, name: 'ETC' }],
    },
    7: {
      // review
      url: 'pba',
      appendFunc: appendPCBPBAForm(designattachmentstore),
      editId: stateObj.pbaId,
      attachmentTag: [{ id: 2, name: 'ETC' }],
    },
  };

  const saveFunc = useSaveFunction(
    editIdObj[phase].url,
    editIdObj[phase].appendFunc,
    pcbstore,
    conditionByTypeObject[type].saveFuncType === 'put' || phase === 2 || phase === 6,
    editIdObj[phase].editId,
    reviseId
  );

  const saveTempFunc = useTempSaveFunction(
    editIdObj[phase].url,
    editIdObj[phase].appendFunc,
    pcbstore,
    conditionByTypeObject[type].saveFuncType === 'put' || phase === 2,
    editIdObj[phase].editId
  );

  //    ---BUSINESS LOGIC
  // condition
  const isRouteInit = conditionByTypeObject[type].routeSectionType === 'init' && phase < 2;
  // logic
  const getPCBData = useGetPCBData(params.id, pcbstore, designattachmentstore);

  useEffect(() => {
    setisRouteActive(conditionByTypeObject[type].isRouteActive);
    setid(params.id && params.id);
    if (params.id !== 'new') {
      getPCBData();
    }
    if (params.id === 'new') {
      setStateByKey('classificationId')({
        classId: searchParam.get('classId'),
        name: searchParam.get('classificationName'),
        // value: searchParam.get("classificationValue"),
      });
    }
    designattachmentstore.setattachmentTagOptionList(editIdObj[phase].attachmentTag);

    //  attachmentTagOptionList
    return () => {
      pcbstore.initDefaultStore();
      pcbstore.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);

  return (
    <PageStyle>
      <ScrollContainer
        isWideScroll={conditionByTypeObject[type].isScrollWide}
        scrollRefList={[
          [informationRef, 'Item Information'],
          [attachmentRef, 'Item Attachment'],
          [PCBInformationRef, 'PCB Information'],
          [routeRef, 'Route Information'],
        ]}
        searchBoxComponent={<SearchBoxGroup store={pcbstore} />}
        tempButtonTitle={params.type !== 'detail' && !reviseId ? 'save as draft' : undefined}
        tempButtonOnclick={saveTempFunc}
        additionalPartTop={reviseId && <TreeChartContainer treeData={treeData} />}
      >
        <PCBInformationSection readOnly={isRouteActive || phase > 1} />
        <LayerInnerozSection readOnly={isRouteActive || phase > 1} />
        <PCBInformationSection2 readOnly={isRouteActive || phase > 1} />

        {phase >= 2 && userData.position.includes('구매담당자') && (
          <PurchaseSection readOnly={isRouteActive || phase > 2} />
        )}

        <SectionGap />
        <AttachmentSection
          editMode={conditionByTypeObject[type].isAttachmentEdit}
          title="Item Attachment"
          readOnly={isRouteActive || phase > 1}
          moduleStore={pcbstore}
        />
        <SectionGap />

        {phase === 3 && <SchematicDesignUploadSection readOnly={isRouteActive} />}
        {phase >= 4 && <PCBDesignUploadSection readOnly={isRouteActive || phase > 4} />}

        {phase >= 4 && (
          <>
            <SectionGap />
            <AttachmentSection
              editMode={conditionByTypeObject[type].isAttachmentEdit}
              title="Design Attachment"
              readOnly={isRouteActive}
              moduleStore={designattachmentstore}
              noScrollRef
            />
          </>
        )}

        {phase >= 5 && (
          <>
            <SectionGap />
            <CompareSection />
          </>
        )}
        <SectionGap />

        {!isRouteActive && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveFunc}
            condition={
              pcbstore.getisConditionFullfill(phase) &&
              (phase < 4 || designattachmentstore.isAttachmentValid)
            }
          >
            Save and Route
          </Button>
        )}
        {isRouteActive &&
          (isRouteInit ? (
            <RouteInitSection
              activate
              routeType="Pcb"
              itemId={pcbstore.id}
              moduleStore={pcbstore}
              afterUrl="/item"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={pcbstore}
            />
          ))}
        <SectionGap />
      </ScrollContainer>
    </PageStyle>
  );
}
