import {
  appendAttachmentFormData,
  appendStateObj,
  deleteFormdataKey,
  setAttachmentArrays,
} from '../utility/Utility';
import useTokenAxios from '../utility/hooks/useTokenAxios';
import txt from '../Text';

export const appendPCBForm = (edit, PCBStore) => {
  const { stateObj } = PCBStore;
  const formData = new FormData();
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, [
    //  custom
    'classificationId',
    // "size",
    // "arraySize",
    'pcbProduceOrganizationId',
    'pcb-material',
    'psr-color',
    'making-color',
    'plating',
    // form제외항복
    'psrColor',
    'makingColor',
    'revision',
    'masterNumber',
    'buyerOrganizationId',
    'attachmentTagOptionList',
    'circuitDesign',
    'pcbDesign',
  ]);

  if (stateObj.classificationId) {
    formData.append(
      'classificationId',
      stateObj.classificationId.classId || stateObj.classificationId.id
    );
  }

  if (stateObj.pcbProduceOrganizationId) {
    formData.append(
      'pcbProduceOrganizationId',
      stateObj.pcbProduceOrganizationId.map((item) => item.id)
    );
  }

  if (stateObj.buyerOrganizationId) {
    formData.append('buyerOrganizationId', stateObj.buyerOrganizationId.id || '');
  }

  if (stateObj['pcb-material']) {
    formData.append('material', stateObj['pcb-material'].name || stateObj['pcb-material']);
  }

  if (stateObj['psr-color']) {
    formData.append('psrColor', stateObj['psr-color'].name || stateObj['psr-color']);
  }

  if (stateObj['making-color']) {
    formData.append('makingColor', stateObj['making-color'].name || stateObj['making-color']);
  }

  if (stateObj.plating) {
    formData.append('platingTreatment', stateObj.plating.name || stateObj.plating);
  }
  appendAttachmentFormData(formData, edit, PCBStore);

  return formData;
};

export const appendSchematicForm = (designattachmentstore) => (edit, PCBStore) => {
  const formData = new FormData();
  formData.append('pcbId', PCBStore.id);
  appendAttachmentFormData(formData, edit, designattachmentstore);

  return formData;
};
export const appendPCBPBAForm = (designattachmentstore) => (edit, PCBStore) => {
  const { stateObj } = PCBStore;
  const formData = new FormData();
  formData.append('pcbId', PCBStore.id);
  appendAttachmentFormData(formData, edit, designattachmentstore);

  return formData;
};
export const useGetPCBData = (id, PCBStore, designattachmentstore, callBack) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/pcb/${id}`);
    const { data } = response.data.result;
    const {
      setStateObjWithData,
      deleteStateByKey,
      setStateByKey,
      setrouteNumber,
      setisCircuitDesignValid,
      setisPcbDesignValid,
    } = PCBStore;
    // setstate
    setStateObjWithData(data);
    deleteStateByKey('circuitDesign');
    deleteStateByKey('pcbDesign');

    // custom'

    if (data.circuitDesign.originalDesignData) {
      const circuitObj = {
        designFileName: data.circuitDesign.originalDesignData.originName,
        designFileAddress: data.circuitDesign.originalDesignData.attachmentaddress,

        designDataName: data.circuitDesign.designData.originName,
        designDataAddress: data.circuitDesign.designData.attachmentaddress,

        partListName: data.circuitDesign.partList.originName,
        partListAddress: data.circuitDesign.partList.attachmentaddress,

        viewName: data.circuitDesign.lastData.originName,
        viewAddress: data.circuitDesign.lastData.attachmentaddress,

        partList: data.schematicPartList,
        errorType: data.schemeticDesignUploadError,
      };

      if (data.schematicErrorPartList) {
        circuitObj.error = data.schematicErrorPartList;
        setisCircuitDesignValid(false);
      } else {
        setisCircuitDesignValid(true);
      }
      setStateByKey('circuitDesign')(circuitObj);
    } else {
      setStateByKey('circuitDesign')('');
    }

    if (data.pcbDesign.originalDesignData) {
      const pcbObj = {
        designFileName: data.pcbDesign.originalDesignData.originName,
        designFileAddress: data.pcbDesign.originalDesignData.attachmentaddress,

        designDataName: data.pcbDesign.designData.originName,
        designDataAddress: data.pcbDesign.designData.attachmentaddress,

        partListName: data.pcbDesign.partList.originName,
        partListAddress: data.pcbDesign.partList.attachmentaddress,

        smtDataName: data.pcbDesign.lastData.originName,
        smtDataAddress: data.pcbDesign.lastData.attachmentaddress,

        gerberName: data.pcbDesign.finalData.originName,
        gerberAddress: data.pcbDesign.finalData.attachmentaddress,

        partList: data.pcbPartList,
        errorType: data.pcbDesignUploadError,
      };
      if (data.pcbPartErrorList) {
        pcbObj.error = data.pcbPartErrorList;
        setisPcbDesignValid(false);
      } else {
        setisPcbDesignValid(true);
      }
      setStateByKey('pcbDesign')(pcbObj);
    } else {
      setStateByKey('pcbDesign')('');
    }

    setStateByKey('pcb-material')(data.material);
    setStateByKey('plating')(data.platingTreatment);
    setStateByKey('psr-color')(data.psrColor);
    setStateByKey('making-color')(data.makingColor);
    //  design attachment
    setAttachmentArrays(data.itemAttachment, PCBStore);

    setAttachmentArrays(data.designAttachment, designattachmentstore);

    setrouteNumber(data.routeId);
    if (callBack) {
      callBack(data);
    }
  };
};
export const PCBTopInputList = [
  {
    inputType: 'DB',
    name: 'Item Classification',
    requestName: 'classificationId',
    option: 1,
    choiceFields: [],
    gray: false,
    readOnly: true,
    cell: 2,
  },
  {
    inputType: 'KEY_IN',
    name: 'PCB Part Number',
    requestName: 'partNumber',
    option: 0,
    choiceFields: [],

    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: 'PCB Name',
    requestName: 'name',
    option: 0,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: 'PCB Specification',
    requestName: 'specification',
    option: 1,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: 'PCB Revision',
    requestName: 'revision',
    option: 0,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: 'Master Main PCB Assy No',
    requestName: 'masterNumber',
    option: 1,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: '비고',
    requestName: 'note',
    option: 1,
    choiceFields: [],
    gray: false,
    readOnly: false,
    cell: 2,
  },

  {
    inputType: 'div',
    cell: 2,
  },
  {
    inputType: 'div',
    cell: 2,
  },
  {
    inputType: 'div',
    cell: 2,
  },
  {
    inputType: 'DB',
    name: txt.pcb.pcbMaterial,
    requestName: 'pcb-material',
    option: 1,
    choiceFields: [],
    searchType: 'KEY_IN',
    gray: false,
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: txt.pcb.pcbThickness,
    requestName: 'thickness',
    option: 1,
    choiceFields: [],
    gray: false,
    cell: 1,
  },
  {
    inputType: 'DB',
    name: txt.pcb.plating,
    requestName: 'plating',
    option: 1,
    choiceFields: [],
    searchType: 'KEY_IN',
    gray: false,
    cell: 1,
  },
  {
    inputType: 'DROP_DOWN',
    name: txt.pcb.outerOz,
    requestName: 'outerOz',
    option: 1,
    choiceFields: [
      { value: '0.5oz', name: '0.5oz' },
      { value: '1.0oz', name: '1.0oz' },
      { value: '2.0oz', name: '2.0oz' },
      { value: '3.0oz', name: '3.0oz' },
      { value: '4.0oz', name: '4.0oz' },
      { value: '5.0oz', name: '5.0oz' },
      { value: '6.0oz', name: '6.0oz' },
      { value: '7.0oz', name: '7.0oz' },
      { value: '8.0oz', name: '8.0oz' },
      { value: 'ETC', name: 'ETC' },
    ],
    gray: false,
    cell: 1,
  },

  {
    inputType: 'DB',
    name: txt.pcb.psr_color,
    requestName: 'psr-color',
    option: 1,
    choiceFields: [],
    searchType: 'KEY_IN',
    gray: false,
    cell: 1,
  },

  {
    inputType: 'DB',
    name: txt.pcb.making_color,
    requestName: 'making-color',
    option: 1,
    choiceFields: [],
    searchType: 'KEY_IN',
    gray: false,
    cell: 1,
  },
  {
    inputType: 'RANGE_KEY_IN', //  체크
    name: 'PCB Size',
    requestName: 'width',
    requestName2: 'height',
    option: 1,
    choiceFields: [],
    gray: false,
    cell: 2,
    title: 'PCB Size',
    unit1: 'mm',
    centerStr: 'x',
    placeholder1: txt.pcb.width,
    placeholder2: txt.pcb.height,
  },
  {
    inputType: 'RANGE_KEY_IN', //  체크
    name: 'Array Size',
    requestName: 'arrayWidth',
    requestName2: 'arrayHeight',
    option: 1,
    choiceFields: [],
    gray: false,
    cell: 2,
    title: 'Array Size',
    unit1: 'mm',
    unit2: 'mm',
    centerStr: 'x',
    placeholder1: txt.pcb.width,
    placeholder2: txt.pcb.height,
  },

  {
    inputType: 'KEY_IN',
    name: txt.pcb.arrayCnt,
    requestName: 'arrayNumber',
    option: 1,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: txt.pcb.special,
    requestName: 'specialSpecification',
    option: 1,
    choiceFields: [],
    gray: false,
    cell: 1,
  },
];

const a = [
  {
    inputType: 'DROP_DOWN',
    name: 'Layer',
    requestName: 'layer',
    option: 1,
    choiceFields: [
      { value: '1', name: '1' },
      { value: '2', name: '2' },
      { value: '4', name: '4' },
      { value: '6', name: '6' },
    ],
    gray: false,
    cell: 1,
  },
  {
    inputType: 'DROP_DOWN',
    name: txt.pcb.innerOz,
    requestName: 'innerOz',
    option: 1,
    choiceFields: [
      { value: '0.5oz', name: '0.5oz' },
      { value: '1.0oz', name: '1.0oz' },
      { value: '2.0oz', name: '2.0oz' },
      { value: '3.0oz', name: '3.0oz' },
      { value: '4.0oz', name: '4.0oz' },
      { value: '5.0oz', name: '5.0oz' },
      { value: '6.0oz', name: '6.0oz' },
      { value: '7.0oz', name: '7.0oz' },
      { value: '8.0oz', name: '8.0oz' },
      { value: 'ETC', name: 'ETC' },
    ],
    gray: false,
    cell: 1,
  },
];
export const purchasePartBottomInputList = [
  {
    inputType: 'DB',
    name: '구매처',
    requestName: 'buyerOrganizationId',
    option: 1,
    choiceFields: [],
    gray: false,
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: 'MOQ',
    requestName: 'moq',
    option: 1,
    choiceFields: [{ value: 'W', name: 'W' }],
    cell: 1,
  },
  {
    inputType: 'date', //  체크
    name: 'Lead Time',
    requestName: 'leadTime',
    option: 1,
    choiceFields: [],
    cell: 1,
    title: 'Array Size',
  },
  {
    inputType: 'DROP_DOWN',
    name: txt.elec.level,
    requestName: 'grade',
    option: 1,
    choiceFields: [
      { value: '1', name: txt.elec.extinct },
      { value: '2', name: txt.elec.extinctExpected },
      { value: '3', name: txt.elec.changing },
    ],
    cell: 1,
  },
  {
    inputType: 'UNIT_KEY_IN',
    name: txt.elec.truePrice,
    requestName: 'price',
    option: 1,
    choiceFields: [
      { value: '₩', name: '₩' },
      { value: '￥', name: '￥' },
      { value: '$', name: '$' },
    ],

    cell: 1,
  },
  {
    inputType: 'UNIT_KEY_IN',
    name: txt.elec.tentativePrice,
    requestName: 'tentativePrice',
    option: 0,
    choiceFields: [
      { value: '₩', name: '₩' },
      { value: '￥', name: '￥' },
      { value: '$', name: '$' },
    ],

    cell: 1,
  },
  {
    inputType: 'UNIT_KEY_IN',
    name: txt.elec.approvedPrice,
    requestName: 'approvedPrice',
    option: 0,
    choiceFields: [
      { value: '₩', name: '₩' },
      { value: '￥', name: '￥' },
      { value: '$', name: '$' },
    ],

    cell: 1,
  },
];
