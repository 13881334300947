import {
  appendAttachmentFormData,
  appendStateObj,
  deleteFormdataKey,
  setAttachmentArrays,
} from '../utility/Utility';
import useTokenAxios from '../utility/hooks/useTokenAxios';
import txt from '../Text';

export const appendProjectForm = (edit, store) => {
  const formData = new FormData();
  const { stateObj, products } = store;
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, ['buyerOrganizationId', 'produceOrganizationId', 'carTypeId']);
  formData.append('produceOrganizationId', stateObj.produceOrganizationId?.id || '');
  formData.append('buyerOrganizationId', stateObj.buyerOrganizationId?.id || '');
  formData.append('carTypeId', stateObj.carTypeId?.id || '');
  formData.append(
    'productId',
    products.map((item) => item.id)
  );
  appendAttachmentFormData(formData, edit, store);

  return formData;
};
export const useGetProjectData = (id, store) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/project/${id}`);
    const { data } = response.data.result;
    const { setStateObjWithData, deleteStateByKey, setStateByKey, setrouteNumber, setproducts } =
      store;
    // setstate
    setStateObjWithData(data);
    setproducts(data.products);
    setAttachmentArrays(data.projectAttachmentList, store);
    setrouteNumber(data.routeId);
  };
};
export const projectInputList = [
  {
    inputType: 'KEY_IN',
    name: 'Project Name',
    requestName: 'name',
    option: 0,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: 'KEY_IN',
    name: 'Project Number',
    requestName: 'number',
    option: 1,
    choiceFields: [],
    cell: 1,
    gray: true,
    readOnly: true,
  },
  {
    inputType: 'DROP_DOWN',
    name: txt.project.type,
    requestName: 'projectTypeId',
    option: 0,
    choiceFields: [
      { value: '1', name: txt.project.mass },
      { value: '2', name: txt.project.pro },
    ],
    cell: 1,
  },
];
export const projectInputList2 = [
  {
    inputType: 'DB',
    name: txt.project.producer,
    requestName: 'produceOrganizationId',
    option: 1,
    cell: 1,
  },
  {
    inputType: 'DB',
    name: txt.project.orderer,
    requestName: 'buyerOrganizationId',
    option: 1,
    cell: 1,
  },
];
export const projectMiddleInputList = [
  {
    inputType: 'DB',
    name: txt.project.carType,
    requestName: 'carTypeId',
    option: 0,
    cell: 1,
  },
];
