import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import TableIndexRow from '../../atom/TableIndexRow';
import ScrollController from '../../molecule/ScrollController';
import ModalBox from '../../organism/ModalBox';
import boardStore from '../../store/boardStore';
import { BlueButtonStyle, PageStyle, ScrollDefault, SectionGap } from '../../Style';
import BoardRow from '../../molecule/BoardRow';
import ChildRow from '../../molecule/ChildRow';
import DeleteButton from '../../atom/DeleteButton';
import BoardModal from '../organism/BoardModal';
import { useSaveBoard, useGetBoardBomData, useGetBoardData } from '../utility';
import RouteSection from '../../organism/RouteSection';
import { conditionByTypeObject } from '../../utility/Source';
import useTokenAxios from '../../utility/hooks/useTokenAxios';

const BoardBomPageStyle = styled(PageStyle)`
  display: flex;

  width: 100%;
  .mainPart {
    margin-left: 24px;
    height: calc(100vh - 280px);
    width: calc(100% - 100px);
    ${ScrollDefault}
    .flex {
      display: flex;
      flex-shrink: 1;
      .rightIndex {
        width: 55vw;
        flex-shrink: 0;
      }
    }
  }
  .childRows {
    position: relative;

    padding-left: 12px;
    ::before {
      content: '';
      display: block;
      position: absolute;
      top: -20px;
      height: 100%;
      border-left: 2px solid var(--textGray);
    }
    .newPBA {
      cursor: pointer;
    }
  }
  .routeBox {
    width: 45%;
  }
`;
export default function BoardBOMPage() {
  const tokenAxios = useTokenAxios();

  const store = boardStore();
  const params = useParams();
  const { type } = params;
  // VIEW
  const [isFold, setisFold] = useState(false);
  const formRef = useRef();
  const informationRef = useRef();
  const exitModalCallBack = () => {
    // isModal : false로 바뀌면 자동으로 실행
    store.setmodalTempCheckedList([]);
    store.setmodalCheckedItemList([]);
    store.setmodalItemList([]);
    store.setmodalSelectedItemClassification({});
  };
  //    Action
  const saveFunc = useSaveBoard(params.id, store);

  //  BUSINESS LOGIC
  const getModalList = async () => {
    const response = await tokenAxios.get(
      `board/${store.modalType}-page?size=${store.rowsPerPage}&page=${store.pageNum}&name=${store.searchText}`
    );
    store.setmodalItemList(response.data.content);
    store.settotalPage(response?.data?.totalPages || 1);
  };
  const getBoardData = useGetBoardData(params.id, store);
  const getBomData = useGetBoardBomData(params.id, store);
  useEffect(() => {
    if (store.modalType) {
      getModalList();
    }
  }, [store.modalType, store.pageNum, store.rowsPerPage, store.searchText]);
  useEffect(() => {
    store.setisRouteActive(conditionByTypeObject[type].isRouteActive);

    getBoardData();
    getBomData();
    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  return (
    <BoardBomPageStyle childNum={store.pbaRowList.length}>
      <ModalBox
        isActivate={store.isModal}
        setisActivate={store.setisModal}
        //   onExit={exitModalCallBack}
      >
        <BoardModal
          onCheckState={store.onCheckModalItem}
          applyLv2={store.applyModalLv2}
          applyLv3={store.applyModalLv3}
          cancelFunc={store.cancelModal}
          onDeleteModalAddedItems={store.onDeleteModalAddedItems}
        />
      </ModalBox>

      <ScrollController
        width="100px"
        buttonArray={[
          [informationRef, 'BOM Information'],
          [store.routeRef, 'Route Information'],
        ]}
        scrollRef={formRef}
      />
      <div className="mainPart" ref={formRef}>
        <div className="flex">
          <TableIndexRow
            widthArray={[6, 6]}
            itemArray={['', 'Item Number']}
            backgroundColor="white"
          />
          <div className="rightIndex">
            <TableIndexRow
              widthArray={[6, 6, 7, 8]}
              itemArray={['Item Name', 'Item Classification', 'Specification', 'Revision']}
              backgroundColor="white"
            />
          </div>
        </div>

        <BoardRow
          isFold={isFold}
          setisFold={setisFold}
          leftItemArray={['Board', store.stateObj.partNumber]}
          rightItemArray={[
            store.stateObj.name,
            store.stateObj.classificationId?.value || store.stateObj.classificationId,
            store.stateObj.specification,
            store.stateObj.revision,
          ]}
        />
        {!isFold && (
          <div className="childRows">
            {store.pbaRowList.map((pbaObj, i) => (
              <ChildRow
                widthArray={[7, 7, 8, 7, 3]}
                isAddButton={false}
                onClick={() => store.openModal('pba')}
                isFirst={i === 0}
                key={i}
                border={!pbaObj.id && 'solid 3px var(--textGray)'}
                backgroundColor={
                  !pbaObj.id || store.isRouteActive ? 'white' : 'rgba(253, 191, 77, 0.3)'
                }
                readOnly={type === 'detail' || store.isRouteActive}
                leftItemArray={
                  pbaObj?.id
                    ? ['PBA', pbaObj.number]
                    : [
                        <span
                          className={type !== 'detail' && 'newPBA'}
                          onClick={() => type !== 'detail' && store.openModal('pba')}
                        >
                          {type !== 'detail' ? 'Click to add PBA' : 'no item'}
                        </span>,
                      ]
                }
                rightItemArray={
                  pbaObj?.id
                    ? [
                        pbaObj.name,
                        pbaObj.classification,
                        pbaObj.specification,
                        pbaObj.revision,
                        !store.isRouteActive && (
                          <DeleteButton
                            onDelete={() => {
                              // pba는 하나만 추가 가능
                              store.setpbaRowList([1]);
                              store.setaddedPbaRowList([]);
                            }}
                            color="var(--textDarkGray)"
                            height="50px"
                          />
                        ),
                      ]
                    : ''
                }
                treeData={pbaObj.children}
              />
            ))}
            {store.firmwareRowList.map((firmwareObj, i) => (
              <ChildRow
                isAddButton={i === 0}
                onClick={() => store.openModal('firmware')}
                isFirst={i === 0}
                key={i}
                border={!firmwareObj.id && 'solid 3px var(--textGray)'}
                backgroundColor={
                  !firmwareObj.id || store.isRouteActive ? 'white' : 'rgba(253, 191, 77, 0.3)'
                }
                readOnly={type === 'detail' || store.isRouteActive}
                widthArray={[7, 7, 8, 7, 3]}
                rightWidth="800px"
                leftItemArray={
                  firmwareObj?.id
                    ? ['Firmware', firmwareObj.number]
                    : [
                        <span
                          className={type !== 'detail' && 'newPBA'}
                          onClick={() => type !== 'detail' && store.openModal('firmware')}
                        >
                          {type !== 'detail' ? 'Click to add Firmware' : 'no item'}
                        </span>,
                      ]
                }
                rightItemArray={
                  firmwareObj?.id
                    ? [
                        firmwareObj.name,
                        firmwareObj.classification,
                        firmwareObj.specification,
                        firmwareObj.revision,
                        !store.isRouteActive && (
                          <DeleteButton
                            onDelete={() => {
                              store.deleteFirmware(firmwareObj);
                            }}
                            color="var(--textDarkGray)"
                            height="50px"
                          />
                        ),
                      ]
                    : ''
                }
                isLastChild={store.firmwareRowList.length - 1 === i}
              />
            ))}
          </div>
        )}
        {!store.isRouteActive && (
          <BlueButtonStyle
            height="36px"
            width="400px"
            onClick={saveFunc}
            css="justify-content:center; margin-top:80px;"
          >
            Save & route
          </BlueButtonStyle>
        )}
        <SectionGap />
        <div className="routeBox">
          {store.isRouteActive && (
            <RouteSection activate readOnly={type === 'detail'} moduleStore={store} />
          )}
        </div>
      </div>
    </BoardBomPageStyle>
  );
}
