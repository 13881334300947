import React from 'react';
import styled from 'styled-components';
import { BoldBlueTextStyle } from '../Style';

const DesignFileListStyle = styled.div`
  .deleteInput {
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: white;
    padding: 0 10px;
    div {
      color: var(--eciBlue);
      font-size: 14px;
    }
    .placeHolder {
      position: absolute;
      top: -10px;
      font-size: 11px;
    }
    .delete {
      cursor: pointer;
      color: var(--textGray);
    }
  }
  .flexContainer {
    display: flex;
    justify-content: space-between;
    .right {
      transform: translate(0, -2px);
      width: 70%;
    }
  }
`;
const FileRowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--eciBlue);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 12px;

  .white {
    height: 32px;
    line-height: 32px;
    border-radius: 5px;

    background-color: white;
    padding: 0 10px;
    min-width: 120px;
    a {
      text-decoration: none;
      color: var(--eciBlue);
      font-size: 13px;
      cursor: pointer;
    }
  }
`;
export default function DesignFileList({
  topFileName,
  fileObjectArray,
  onDelete,
  placeholder,
  title,
  readOnly,
}) {
  const fileList = fileObjectArray.map((object, i) => {
    return (
      <FileRowStyle key={i}>
        {object.title}{' '}
        <div className="white">
          <a
            href={process.env.REACT_APP_BASE_URL + (object.url || '')}
            target="_blank"
            rel="noreferrer"
          >
            {object.fileName}
          </a>
        </div>{' '}
      </FileRowStyle>
    );
  });
  return (
    <DesignFileListStyle>
      <div className="deleteInput">
        <div className="placeHolder">{placeholder}</div>
        <div className="fileName">{topFileName}</div>
        {!readOnly && (
          <span className="material-icons delete" onClick={onDelete}>
            delete
          </span>
        )}
      </div>
      <br />
      <br />
      <div className="flexContainer">
        <BoldBlueTextStyle fontSize="14px"> {title}</BoldBlueTextStyle>
        <div className="right">{fileList}</div>
      </div>
    </DesignFileListStyle>
  );
}
