import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import LineTitle from '../../atom/LineTitle';
import InputGrid from '../../organism/InputGrid';
import productStore from '../../store/productStore';
import { PageStyle } from '../../Style';
import { productInputList } from '../utility';

const FlexStyle = styled.div`
  display: flex;
  width: 100%;
  .halfFlex {
    width: 50%;
  }
`;
export default function ProductInformationSection({ readOnly }) {
  const store = productStore();
  const informationRef = useRef();

  useEffect(() => {
    store.setinformationRef(informationRef);
  }, []);
  return (
    <PageStyle ref={informationRef}>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        Item Information
      </LineTitle>{' '}
      <InputGrid readOnly={readOnly} modulestore={store} inPutList={productInputList} />
    </PageStyle>
  );
}
