import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import LineTitle from '../../atom/LineTitle';
import AnimationInput from '../../molecule/AnimationInput';
import AnimationSearchInput from '../../molecule/AnimationSearchInput';
import InputGrid from '../../organism/InputGrid';
import electronicPartStore from '../../store/electronicPartStore';
import { MultiSearchInputStyle, PageStyle } from '../../Style';
import { electronicPartTopInputList, electronicPartTopInputNoRouteList } from '../utility';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import txt from '../../Text';

const FlexStyle = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  position: relative;

  .halfFlex {
    width: 50%;
  }
`;
const ChangeButtonStyle = styled.div`
  height: 40px;
  width: calc(50% - 15px);
  background-color: var(--eciBlue);
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 80px;
  right: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export default function ElectronicPartInformationSection({ readOnly, noRoute, onSwitchButton }) {
  const electronicPartstore = electronicPartStore();
  const {
    stateObj,
    setStateByKey,
    setsearchBoxTargetIndex,
    setsearchBoxType,
    searchBoxType,
    searchBoxTargetIndex,
  } = electronicPartStore();
  const informationRef = useRef();
  const tokenAxios = useTokenAxios();
  //    제조사 인풋 관련
  const suppllierInputList =
    stateObj.produceOrganizationId &&
    stateObj.produceOrganizationId.map((item, i) => {
      return (
        <FlexStyle key={i}>
          <div className="halfFlex">
            <MultiSearchInputStyle key={i}>
              {!readOnly &&
                (i === stateObj.produceOrganizationId.length - 1 ? (
                  <span
                    className="plus"
                    onClick={() => {
                      if (
                        stateObj.produceOrganizationId[stateObj.produceOrganizationId.length - 1]
                          .id !== ''
                      ) {
                        setStateByKey('produceOrganizationId')([
                          ...stateObj.produceOrganizationId,
                          { id: '', name: '' },
                        ]);
                      }
                    }}
                  >
                    +
                  </span>
                ) : (
                  <span className="plus"> </span>
                ))}
              <AnimationSearchInput
                readOnly={readOnly}
                required
                width="100%"
                height="40px"
                placeholder={txt.elec.produceOrganizationId}
                state={item.name || item.name2 || item.code1 || item.code2}
                onClick={() => {
                  setsearchBoxType('produceOrganizationId');
                  setsearchBoxTargetIndex(i);
                }}
                isNow={searchBoxType === 'produceOrganizationId' && searchBoxTargetIndex === i}
              />
            </MultiSearchInputStyle>
          </div>
          <div className="halfFlex">
            <AnimationInput
              required
              readOnly={readOnly}
              width="100%"
              height="40px"
              placeholder={txt.elec.produceOrganizationNumber}
              state={item.partNumber}
              setState={(val) => {
                const tmpArr = structuredClone(stateObj.produceOrganizationId);

                tmpArr[i].partNumber = val;
                setStateByKey('produceOrganizationId')(tmpArr);
              }}
            />
          </div>
        </FlexStyle>
      );
    });
  useEffect(() => {
    electronicPartstore.setinformationRef(informationRef);
  }, []);

  return (
    <PageStyle ref={informationRef} style={{ position: 'relative' }}>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        Item Information
      </LineTitle>
      {!electronicPartstore.stateObj.tmpNumber && electronicPartstore.isSwitchable && (
        <ChangeButtonStyle onClick={onSwitchButton}>
          {txt.elec.substituteToFormal}{' '}
        </ChangeButtonStyle>
      )}
      <InputGrid
        readOnly={readOnly}
        modulestore={electronicPartstore}
        inPutList={
          noRoute
            ? electronicPartTopInputNoRouteList
            : electronicPartTopInputList(
                !!electronicPartstore.stateObj.tmpNumber,
                !!electronicPartstore.isSwitching,
                !!electronicPartstore.isTestPoint,
                !!electronicPartstore.isMech
              )
        }
      />
      {suppllierInputList}
    </PageStyle>
  );
}
