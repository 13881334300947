import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { IoIosFolderOpen } from 'react-icons/io';

import ClassificationSelectTree from './ClassificationSelectTree';
import { ScrollDefault, WidthHeightStyle } from '../Style';

const ClassificationTreeStyle = styled.div`
  ${WidthHeightStyle}
  .scrol {
    height: 100%;
    ${ScrollDefault}
  }
  .titleBoxx {
    color: var(--eciBlue);
    font-weight: 600;
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    border-bottom: solid 2px var(--eciBlue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }
  .closeAllBtn {
    border: none;
    padding: 8px;
    cursor: pointer;
    color: var(--eciBlue);
    font-size: 18px;
    display: flex;
    align-items: center;
  }
`;

const saveTreeState = (state) => {
  localStorage.setItem('classificationTreeState', JSON.stringify(state));
};

const loadTreeState = () => {
  const savedState = localStorage.getItem('classificationTreeState');
  return savedState ? JSON.parse(savedState) : {};
};

const saveSelectedState = (selected) => {
  localStorage.setItem('selectedTreeState', JSON.stringify(selected));
};

const loadSelectedState = () => {
  const savedSelected = localStorage.getItem('selectedTreeState');
  return savedSelected ? JSON.parse(savedSelected) : null;
};

export default function ClassificationTreeSelector({
  width,
  height = '100%',
  state,
  setstate,
  treeData,
  title,
}) {
  const [treeState, setTreeState] = useState(loadTreeState());
  const [selectedState, setSelectedState] = useState(loadSelectedState());

  const setIsOpen = (id, isOpen) => {
    setTreeState((prevState) => {
      const newState = { ...prevState, [id]: isOpen };
      saveTreeState(newState);
      return newState;
    });
  };

  const closeAll = () => {
    const newState = {};
    // eslint-disable-next-line guard-for-in
    for (const key in treeState) {
      newState[key] = false;
    }
    setTreeState(newState);
    saveTreeState(newState);
  };

  useEffect(() => {
    setTreeState(loadTreeState());
    const savedSelected = loadSelectedState();
    if (savedSelected) {
      setstate(savedSelected);
    }
  }, [setstate]);

  const handleClick = (data) => {
    if (data.last) {
      setstate(data);
      saveSelectedState(data);
    }
  };

  const tree =
    Array.isArray(treeData) &&
    treeData.map((item, i) => {
      return (
        <ClassificationSelectTree
          key={i}
          child={item.children}
          title={item.name}
          last={item.last}
          id={item.id}
          data={item}
          depth={0}
          treeId={`${item.id}`}
          backgroundColor="var(--eciBlue)"
          boxWidth={`${width} / 2 - 20px`}
          onClick={handleClick}
          currentState={state}
          isOpen={treeState}
          setIsOpen={setIsOpen}
        />
      );
    });

  return (
    <ClassificationTreeStyle width={width} height={height}>
      <div className="titleBoxx">
        {title}
        <div className="closeAllBtn" onClick={closeAll}>
          <IoIosFolderOpen />
        </div>
      </div>
      <div className="scrol">{tree}</div>
    </ClassificationTreeStyle>
  );
}
