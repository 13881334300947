import React from 'react';
import styled from 'styled-components';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import { ScrollDefault } from '../../Style';
import txt from '../../Text';

const CompareTableStyle = styled.div`
  border: solid 1px var(--eciBlue);
  background-color: white;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  .leftPart {
    width: 60%;
    ${ScrollDefault}
  }
  .rightPart {
    width: 40%;
    padding-top: 10px;
  }
  .BlueBoxContainer {
    display: flex;
    .BlueBox {
      height: 40px;
      line-height: 40px;
      width: 100%;
      border-top: solid 1px white;
      background-color: var(--eciBlue);
      text-align: center;
      color: white;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;
export default function CompareTable({ compareData, name, compareName }) {
  return (
    <CompareTableStyle>
      <div className="leftPart">
        <TableIndexRow
          widthArray={[6, 3, 6, 4, 4, 6, 5, 5]}
          itemArray={txt.pcb.compareTableIndex}
        />
        {compareData.map((itemObj) => (
          <TableRow
            key={itemObj.id}
            widthArray={[6, 3, 6, 4, 4, 6, 5, 5]}
            itemArray={[
              itemObj.classification,
              itemObj.supplier,
              itemObj.supplierNumber,
              itemObj.price,
              itemObj.usage,
              itemObj.name,
              itemObj.footPrint,
              itemObj.symbol,
            ]}
          />
        ))}
      </div>
      <div className="rightPart">
        <div className="BlueBoxContainer">
          <div className="BlueBox">{name}</div>
          <div className="BlueBox">{compareName}</div>
        </div>
        {compareData.map((item) => (
          <div className="BlueBoxContainer">
            <div className="BlueBox">{item.exist * 1 === 1 ? 'O' : 'X'}</div>
            <div className="BlueBox">{item.exist * 1 === 2 ? 'O' : 'X'}</div>
          </div>
        ))}
      </div>
    </CompareTableStyle>
  );
}
