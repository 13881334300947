import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import TableIndexRow from '../../atom/TableIndexRow';
import ScrollController from '../../molecule/ScrollController';
import ModalBox from '../../organism/ModalBox';
import productStore from '../../store/productStore';
import {
  BlueButtonStyle,
  PageStyle,
  ScrollDefault,
  SectionGap,
  WhitePlusButton,
} from '../../Style';
import BoardRow from '../../molecule/BoardRow';
import { useSaveProduct, useGetProductBomData, useGetProductData } from '../utility';
import RouteSection from '../../organism/RouteSection';
import ProductModal from '../organism/ProductModal';
import { conditionByTypeObject } from '../../utility/Source';
import DeleteButton from '../../atom/DeleteButton';
import PriceModal from '../organism/PriceModal';
import AttachmentWarningSign from '../../molecule/AttachmentWarningSign';
import Button from '../../atom/Button';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import txt from '../../Text';
import ProductChildRow from '../../molecule/ProductChildRow';
import ExportExcelButton from '../../organism/ExportExcelButton';

export const ProductBomPageStyle = styled(PageStyle)`
  display: flex;

  width: 100%;
  .mainPart {
    margin-left: 24px;
    height: calc(100vh - 300px);
    width: calc(100% - 100px);
    ${ScrollDefault}
    .flex {
      display: flex;
      flex-shrink: 1;
      .rightIndex {
        width: 55vw;
        flex-shrink: 0;
      }
    }
  }
  .searchSection {
    display: flex;
    justify-content: space-between;
  }
  .childRows {
    position: relative;

    padding-left: 12px;
    ::before {
      content: '';
      display: block;
      position: absolute;
      top: -20px;
      height: 100%;
      border-left: 1px solid var(--textDarkGray);
    }
    .newPBA {
      cursor: pointer;
    }
  }
  .routeBox {
    width: 45%;
  }
  .gap {
    height: 20px;
  }
`;
export default function ProductBOMPage() {
  const tokenAxios = useTokenAxios();
  const store = productStore();
  const params = useParams();
  const { type } = params;
  // VIEW
  const [isFold, setisFold] = useState(false);
  const formRef = useRef();
  const informationRef = useRef();

  //    Action
  const saveFunc = useSaveProduct(params.id, store);
  const tempSaveFunc = useSaveProduct(params.id, store, true);

  //  BUSINESS LOGIC
  /**
   * 아래 4개 종류만 보이게 할 것
   *
   *   Assembly>Electronic Assembly>Board
   *   Part>Mechanical Part
   *   부자재
   *   소모재
   */

  const getClassificationTree = async () => {
    const response = await tokenAxios.get('classification-tree');
    const { data } = response.data.result;
    store.setmodalClassificationTree([
      ...findItemsByPath(data, ['Assembly', 'Electronic Assembly', 'Board']),
      ...findItemsByPath(data, ['Part', 'Mechanical Part']),
      ...data.filter((item) => item.name === '부자재' || item.name === '소모재'),
    ]);
  };
  function findItemsByPath(data, path) {
    const results = [];

    function recurse(currentData, currentPath) {
      if (currentData.name === path[path.length - 1] && currentPath.join('/') === path.join('/')) {
        results.push(currentData);
      }

      if (currentData.children && currentData.children.length > 0) {
        currentData.children.forEach((child) => {
          recurse(child, currentPath.concat([child.name]));
        });
      }
    }

    data.forEach((item) => {
      recurse(item, [item.name]);
    });

    return results;
  }

  const getModalList = async () => {
    let response = [];
    if (store.modalSelectedItemClassification.classificationId) {
      response = await tokenAxios.get(
        `product/item-classification/${store.modalSelectedItemClassification.classificationId}?size=${store.rowsPerPage}&page=${store.pageNum}&name=${store.searchText}`
      );
    }
    store.setmodalItemList(response.data?.result.data.content || []);
    store.settotalPage(response?.data?.totalPages || 1);
  };
  const getPriceData = async () => {
    const response = await tokenAxios.get(`product-price/${params.id}`);
    store.initChangedPriceObj(response.data.result.data || []);
  };
  const getBomData = useGetProductBomData(params.id, store);
  useEffect(() => {
    store.setisRouteActive(conditionByTypeObject[type].isRouteActive);
    store.setid(params.id);
    if (type !== 'detail') {
      getClassificationTree();
    }
    getBomData();
    getPriceData();
    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  useEffect(() => {
    getModalList();
  }, [store.modalSelectedItemClassification, store.rowsPerPage, store.pageNum, store.searchText]);

  const formatBomData = (productChildList) => {
    const data = [];

    data.push({
      Level: 0,
      Type: 'Product',
      'Item Number': store.stateObj.partNumber || '-',
      'Item Name': store.stateObj.name || '-',
      Specification: store.stateObj.specification || '-',
      Revision: store.stateObj.revision || '-',
    });

    const traverseBom = (item, level = 1) => {
      if (!item) return;

      data.push({
        Level: level,
        Type: item.type || '-',
        'Item Number': item.number || '-',
        'Item Name': item.name || '-',
        Specification: item.specification || '-',
        Revision: item.revision || '-',
      });

      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => traverseBom(child, level + 1));
      }
    };

    productChildList.forEach((item) => traverseBom(item));
    return data;
  };

  return (
    <ProductBomPageStyle childNum={store.productChildList.length}>
      <ModalBox
        isActivate={!!store.modalType}
        setisActivate={store.closeModal}
        onExit={store.exitModalCallBack}
      >
        {store.modalType === 'BOM' && (
          <ProductModal
            onCheckState={store.onCheckModalItem}
            applyLv2={store.applyModalLv2}
            applyLv3={store.applyModalLv3}
            cancelFunc={store.cancelModal}
            onDeleteModalAddedItems={store.onDeleteModalAddedItems}
          />
        )}
        {store.modalType === 'price' && <PriceModal cancelFunc={store.cancelModal} />}
      </ModalBox>

      <ScrollController
        width="100px"
        buttonArray={[
          [informationRef, 'BOM Information'],
          [store.routeRef, 'RouteInformation'],
        ]}
        scrollRef={formRef}
      />
      <div className="mainPart" ref={formRef}>
        <div className="searchSection">
          <WhitePlusButton onClick={store.openPriceModal}>{txt.product.calcPrice}</WhitePlusButton>
          <div style={{ display: 'flex', gap: '10px' }}>
            <ExportExcelButton
              data={formatBomData(store.productChildList)}
              title={`${store.stateObj.partNumber}-BOM.xlsx`}
            />
            <Button
              backgroundColor="transparent"
              onClick={tempSaveFunc}
              fontSize="12px"
              color="var(--textDarkGray)"
              condition
            >
              Save as Draft
            </Button>
          </div>
        </div>
        <div className="gap" />
        {type !== 'detail' && <AttachmentWarningSign name="" text={txt.product.bomWarning} />}
        <div className="gap" />

        <div className="flex">
          <TableIndexRow
            widthArray={[6, 6]}
            itemArray={['', 'Item Number']}
            backgroundColor="white"
          />
          <div className="rightIndex">
            <TableIndexRow
              widthArray={[7, 10, 10]}
              itemArray={['Item Name', 'Specification', 'Revision']}
              backgroundColor="white"
            />
          </div>
        </div>
        {/* <TableIndexRow
          widthArray={[5, 7, 7, 10, 10]}
          itemArray={[
            "",
            "Item Number",
            "Item Name",
            "Specification",
            "Revision",
          ]}
          backgroundColor="white"
        /> */}
        <BoardRow
          isFold={isFold}
          setisFold={setisFold}
          leftItemArray={['Product', store.stateObj.partNumber]}
          rightItemArray={[
            store.stateObj.name,
            store.stateObj.specification,
            store.stateObj.revision,
          ]}
          rightWidthArray={[7, 10, 10]}
        />
        {!isFold && (
          <div className="childRows">
            {store.productChildList.map((childObj, i) => (
              <ProductChildRow
                widthArray={[7, 10, 7, 3]}
                leftItemArray={
                  childObj?.id
                    ? [childObj.type, childObj.number]
                    : [
                        <span
                          className={type !== 'detail' ? 'newPBA' : ''}
                          onClick={() => type !== 'detail' && store.openBOMModal()}
                        >
                          {type !== 'detail' ? 'Click to add Item' : 'no item'}
                        </span>,
                      ]
                }
                rightItemArray={
                  childObj?.id
                    ? [
                        childObj.name,
                        '',
                        childObj.revision,
                        !store.isRouteActive && (
                          <DeleteButton
                            onDelete={() => {
                              store.deleteProductChild(childObj);
                            }}
                            color="var(--textDarkGray)"
                            height="50px"
                          />
                        ),
                      ]
                    : ''
                }
                treeData={childObj?.children}
                onClick={() => {
                  store.openBOMModal();
                }}
                isFirst={i === 0}
                key={i}
                border={!childObj?.id && 'solid 3px var(--textGray)'}
                backgroundColor={
                  !childObj?.id || store.isRouteActive ? 'white' : 'rgba(253, 191, 77, 0.3)'
                }
                readOnly={type === 'detail' || store.isRouteActive}
                isLastChild={store.productChildList.length - 1 === i}
              />
            ))}
          </div>
        )}
        {!store.isRouteActive && (
          <BlueButtonStyle
            height="36px"
            width="400px"
            onClick={saveFunc}
            css="justify-content:center; margin-top:80px;"
          >
            Save & route
          </BlueButtonStyle>
        )}
        <SectionGap />
        <div className="routeBox">
          {store.isRouteActive && (
            <RouteSection activate readOnly={type === 'detail'} moduleStore={store} />
          )}
        </div>
      </div>
    </ProductBomPageStyle>
  );
}
