import React from 'react';
import styled from 'styled-components';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import ModuleTopNavigation from '../molecule/ModuleTopNavigation';
import { ModuleStyle } from '../Style';

import productStore from '../store/productStore';

const BoardBomStyle = styled(ModuleStyle)`
  .con {
    display: flex;
    gap: 12px;
    align-items: center;
    transform: translateY(-24px);
  }
  .grayBox {
    font-size: 14px;
    border-radius: 50px;
    height: 32px;
    line-height: 32px;
    padding: 0 18px;
    background-color: rgb(10, 0, 85, 0.3);
    color: white;
    font-weight: 600;
    cursor: pointer;
  }
  .blueText {
    color: var(--eciBlue);
    font-size: 14px;
    font-weight: 600;
  }
`;
export default function ProductBomModule() {
  const store = productStore();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const generatePath = (str) => {
    const pathArr = location.pathname.split('/');
    pathArr[3] = str;
    return pathArr.join('/');
  };
  return (
    <BoardBomStyle>
      <ModuleTopNavigation
        title="BOM"
        navList={[
          {
            name: 'SR BOM',
            to: generatePath('sr'),
          },
          {
            name: 'Development BOM',
            to: generatePath('dev'),
          },
          {
            name: 'Compare BOM',
            to: generatePath('compare'),
          },
        ]}
      >
        <div className="con">
          <div className="grayBox" onClick={() => navigate(`/item/product/detail/${params.id}`)}>
            {store.stateObj.partNumber}
          </div>
          <span className="blueText">{store.stateObj.name}</span>
        </div>
      </ModuleTopNavigation>
      <Outlet />
    </BoardBomStyle>
  );
}
