import React from 'react';
import styled from 'styled-components';

const BoldTextStyle = styled.span`
  color: ${(props) => props.color};
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => `${props.fontSize}`};
  line-height: calc(${(props) => props.fontSize} + 4px);
  cursor: ${(props) => (props.onClick ? 'pointer' : '')};
  ${(props) => props.cssStyle}
`;

export default function BoldText({ children, color, fontSize, onClick, cssStyle }) {
  return (
    <BoldTextStyle
      onClick={onClick}
      color={color}
      fontSize={fontSize}
      cssStyle={cssStyle}
      title={children}
    >
      {children}
    </BoldTextStyle>
  );
}
