import create from 'zustand';
import { produce } from 'immer';

import { defaultStoreSeed, stateObjSeed } from './defaultStore';

const releaseStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    releaseNumber: '',
    product: [],
    releaseTitle: '',
    releaseContent: '',
    releaseOrganizationId: [],
  },
  setsearchBoxProperty: (type, item) => {
    const { stateObj, setStateByKey } = get();
    //  array에 추가하는 경우 대비
    if (type === 'releaseOrganizationId') {
      const tmpSet = [...get().stateObj.releaseOrganizationId, item];
      setStateByKey('releaseOrganizationId')(tmpSet);
    } else {
      let tmpArr;
      const i = get().searchBoxTargetIndex;
      if (typeof i === 'number') {
        tmpArr = structuredClone(stateObj[type]);
        tmpArr[i] = item;
      }
      set(() => ({
        stateObj: produce(stateObj, (draft) => {
          if (typeof i === 'number') {
            draft[type] = tmpArr;
          } else {
            draft[type] = item;
          }
        }),
      }));
    }
  },
  deleteReleaseOrganizationId: (orgObj) => {
    const { stateObj, setStateByKey } = get();
    const tmpOrgList = [...stateObj.releaseOrganizationId];
    const i = tmpOrgList.findIndex((obj) => obj.id === orgObj.id);

    if (i > -1) {
      tmpOrgList.splice(i, 1);
    }
    setStateByKey('releaseOrganizationId')(tmpOrgList);
  },
  deleteproduct: (obj) => {
    const { stateObj, setStateByKey } = get();

    const tmpList = [...stateObj.product].filter((item) => item.id !== obj.id);
    setStateByKey('product')(tmpList);
  },
  // modal
  isModal: false,
  setisModal: (val) => {
    set(() => ({ isModal: val }));
  },

  // init
  initModuleStore: () => {
    set(() => ({
      stateObj: {
        id: '',
        releaseNumber: '',
        product: [],
        releaseTitle: '',
        releaseContent: '',
        releaseOrganizationId: [],
      },

      isModal: false,
    }));
  },

  getisConditionFullfill: () => {
    let condition = true;
    const { stateObj, isAttachmentValid } = get();
    condition =
      !!isAttachmentValid &&
      !!stateObj.releaseTitle &&
      !!stateObj.releaseContent &&
      !!stateObj.product.length > 0;

    return condition;
  },
  attachmentTagOptionList: [{ id: 2, name: 'ETC' }],
}));
export default releaseStore;
