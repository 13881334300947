import create from 'zustand';
import { defaultStoreSeed, stateObjSeed } from './defaultStore';

const PBAStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    id: '',
    classificationId: '',
    partNumber: '',
    name: '',
    pbaProduceOrganizationId: [{ id: '', name: '' }],
    revision: '',
    specification: '',
    mpba: {
      id: '',
      name: '',
      number: '',
      revision: '',
    },
  },
  pbaRowList: [],
  setpbaRowList: (val) => {
    set(() => ({ pbaRowList: val }));
  },

  //  design attachment
  // attachment
  attachmentTagOptionList: [
    //  체크
    { id: 2, name: 'ETC' },
  ], // add default
  setattachmentTagOptionList: (val) => {
    set(() => ({ attachmentTagOptionList: val }));
  },

  relatedMPBARef: '',
  setrelatedMPBARef: (val) => {
    set(() => ({ relatedMPBARef: val }));
  },

  getisConditionFullfill: () => {
    let condition = true;
    const { stateObj, isAttachmentValid } = get();
    condition =
      !!isAttachmentValid &&
      !!stateObj.name &&
      !!stateObj.partNumber &&
      !!(stateObj.mpba.id || stateObj.mpba.id === 0);
    return condition;
  },

  initModuleStore: () => {
    set(() => ({
      stateObj: {
        id: '',
        classificationId: '',
        partNumber: '',
        name: '',
        pbaProduceOrganizationId: [{ id: '', name: '' }],
        mpba: {
          id: '',
          name: '',
          partNumber: '',
          revision: '',
          productName: '',
        },
      },
      relatedMPBRef: '',
    }));
  },
}));
export default PBAStore;
