import React from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import BoldText from '../../atom/BoldText';
import ModuleCountBox from '../molecule/ModuleCountBox';
import dashboardStore, {
  moduleList,
  routeByTodoNameObj,
  typeByRoute,
} from '../../store/dashboardStore';
import TodoCard from '../molecule/TodoCard';
import ScrollColumn from '../molecule/ScrollColumn';
import TodoListContainer from '../molecule/TodoListContainer';

const TodoSectionStyle = styled.div`
  .topPart {
    display: flex;
    /* height: 210px; */
    width: 100%;
    justify-content: space-between;
    .todoSection {
      width: 75%;
      .moduleBoxContainer {
        display: flex;
        justify-content: space-between;
      }
      .arrowContainer {
        opacity: ${(props) => (props.currentModuleIndex === 0 || props.currentModuleIndex ? 1 : 0)};
        width: 100%;
        display: inline-block;
        transition: all 0.4s;
        transform: translateX(calc(${(props) => props.currentModuleIndex * (100 / 12)}% + 18px));
        ::before {
          content: ' ';
          border: 12px solid transparent;
          border-bottom: 20px solid var(--eciBlue);
        }
      }
    }
    .alertSection {
      width: 10%;
    }
    .noticeSection {
      width: 10%;
    }
  }
`;
export default function TodoSection() {
  const store = dashboardStore();
  const navigate = useNavigate();
  return (
    <TodoSectionStyle>
      <div className="topPart">
        <div className="todoSection">
          <BoldText color="var(--eciBlue)" fontSize="24px">
            To do
          </BoldText>
          <br /> <br />
          <div className="moduleBoxContainer">
            {moduleList.map((title, i) => (
              <ModuleCountBox
                key={i}
                title={title}
                count={store.todoDataObj[title]?.lists?.reduce((acc, curObj) => {
                  return acc + curObj.toDoList.length;
                }, 0)}
                onClick={() => store.setcurrentModuleIndex(i)}
              />
            ))}
            <div className="divForFlexGap" />
          </div>
          <div className="arrowContainer" />
        </div>
        <div className="alertSection">
          <BoldText color="var(--eciBlue)" fontSize="24px">
            Alert
          </BoldText>
        </div>
        <div className="noticeSection">
          <BoldText color="var(--eciBlue)" fontSize="24px">
            Notice
          </BoldText>
        </div>
      </div>

      {(store.currentModuleIndex === 0 || store.currentModuleIndex) && (
        <TodoListContainer>
          {store.todoDataObj[moduleList[store.currentModuleIndex || 0]]?.lists.map(
            (routeObj, routeObjIndex) => (
              <ScrollColumn
                key={routeObjIndex}
                title={routeObj.category}
                count={routeObj.toDoList?.length}
              >
                {routeObj.toDoList.map((todoCardObj) => (
                  <TodoCard
                    key={todoCardObj.id}
                    onClick={() => {
                      navigate(
                        `/${
                          routeByTodoNameObj[
                            Object.keys(routeByTodoNameObj)[store.currentModuleIndex]
                          ]
                        }/${
                          store.todoDataObj[moduleList[store.currentModuleIndex || 0]].lists
                            .length -
                            1 ===
                          routeObjIndex
                            ? 'review'
                            : typeByRoute[todoCardObj.status] || 'add'
                        }/${todoCardObj.id}?phase=${routeObjIndex + 1}${
                          todoCardObj.status === 'revise' ? `&reviseId=${todoCardObj.reviseId}` : ''
                        }`
                      );
                    }}
                    number={todoCardObj.number}
                    title={todoCardObj.name}
                    state={todoCardObj.status}
                    color={
                      todoCardObj.status === 'rejected'
                        ? 'var(--todoRed)'
                        : todoCardObj.status === 'tempsave1' || todoCardObj.status === 'tempsave2'
                          ? 'var(--todoYellow)'
                          : todoCardObj.status === 'revise'
                            ? 'purple'
                            : 'var(--eciBlue)'
                    }
                    projectType={
                      moduleList[store.currentModuleIndex] === 'Project' && todoCardObj.type
                    }
                  />
                ))}
              </ScrollColumn>
            )
          )}
        </TodoListContainer>
      )}
    </TodoSectionStyle>
  );
}
