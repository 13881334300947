import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ListPageStyle } from '../../Style';

import TableIndexRow from '../../atom/TableIndexRow';
import PaginationTable from '../../molecule/PaginationTable';
import SearchBar from '../../molecule/SearchBar';
import ClassificationTreeSelector from '../../organism/ClassificationTreeSelector';
import TableRow from '../../atom/TableRow';
import usePageConditionList from '../../utility/hooks/usePageConditionList';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import { getTargetURI } from '../../utility/Utility';

const EditListPageStyle = styled(ListPageStyle)`
  display: flex;
  justify-content: space-between;

  .right {
    width: 78%;
  }
`;
const EditButtonStyle = styled.div`
  cursor: pointer;
  height: 40px;
  line-height: 40px;
`;
export default function EditListPage() {
  /// ---CONSt
  const tokenAxios = useTokenAxios();
  const navigate = useNavigate();
  /// ---MODEL
  const [classificationObject, setclassificationObject] = useState({
    id: '',
    name: '',
    value: '',
  });
  const [treeData, settreeData] = useState([]);

  //  리스트 가져오기
  const [pageCondition, setpageCondition, itemListData] = usePageConditionList(
    `editable-item-classification`,
    'searchText',
    'classId'
  );
  /// ---ACTION
  /// ---VIEW

  const indexArray = ['Item Number', 'Item Name', 'Item Classification', 'Specification', 'Edit'];

  const widthArray = [5, 5, 5, 7, 4];
  const itemRowList = itemListData?.map((item, i) => {
    return (
      <TableRow
        key={i}
        onClick={() => {
          navigate(
            `/${getTargetURI(
              classificationObject.value,
              classificationObject.classification
            )}/editD/${item.id}?phase=4&classificationValue=${
              classificationObject.classification
            }&classificationName=${classificationObject.value}&classId=${
              classificationObject.classificationId
            }&noRoute=true`
          );
        }}
        itemArray={[
          item.number,
          item.name,
          item.classification,
          item.specification,
          <EditButtonStyle className="material-icons">edit</EditButtonStyle>,
        ]}
        widthArray={widthArray}
      />
    );
  });
  /// ---BL
  const getTreeData = async () => {
    const response = await tokenAxios.get('classification-tree');
    const tmpTree = response.data.result.data.filter(
      (obj) =>
        obj.classification?.indexOf('3') === 0 || // mech
        obj.classification?.indexOf('4') === 0 || // 부자재
        obj.classification?.indexOf('5') === 0 // 소모재
    );
    tmpTree[tmpTree.findIndex((obj) => obj.classification === '3')].children = tmpTree[
      tmpTree.findIndex((obj) => obj.classification === '3')
    ].children.filter((obj) => obj.classification !== '3/8');
    settreeData(tmpTree);
  };
  useEffect(() => {
    setpageCondition('classId', classificationObject.classificationId);
  }, [classificationObject]);

  useEffect(() => {
    getTreeData();
  }, []);
  return (
    <EditListPageStyle>
      <ClassificationTreeSelector
        treeData={treeData}
        width="220px"
        height="60vh + 10px"
        title="Classification"
        state={classificationObject}
        setstate={setclassificationObject}
      />
      <div className="right">
        <div className="searchSection">
          <SearchBar
            width="280px"
            height="24px"
            placeholder="Search Items"
            state={pageCondition.searchText}
            setState={(val) => {
              setpageCondition('searchText', val);
            }}
          />
        </div>

        <PaginationTable
          width="100%"
          height="60vh"
          minHeight="300px"
          innerWidth="100%"
          totalPage={pageCondition.totalPage}
          rowsPerPageDefault={10}
          pageNum={pageCondition.pageNum}
          setRowsPerPage={(val) => {
            setpageCondition('rowsPerPage', val);
          }}
          rowsPerPage={pageCondition.rowsPerPage}
          setpageNum={(val) => {
            setpageCondition('pageNum', val);
          }}
          noshadow
        >
          <TableIndexRow itemArray={indexArray} widthArray={widthArray} width="100%" />
          {itemRowList}
        </PaginationTable>
      </div>
    </EditListPageStyle>
  );
}
