import React, { useState } from 'react';
import styled from 'styled-components';

import SelectBox from '../../atom/SelectBox';
import { GrayButton } from '../../Style';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import txt from '../../Text';
import SelectBoxForClassification from '../../atom/SelectBoxForClassification';

const ClassificationPropRowStyle = styled.div`
  width: 100%;
  border-top: solid 1px var(--textGray);
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(${(props) => props.height} + ${(props) => (props.thick ? props.height : '0px')});
  /* min-width: calc(${(props) => props.minWidth}); */

  .propList {
    display: flex;
    /* min-width: calc(${(props) => props.minWidth}); */
  }
  .Nothing {
    width: 100px;
    border: solid red;
  }
`;
const SelectBoxContainerStyle = styled.div`
  margin-right: 20px;
  height: 56px;
  display: flex;
  align-items: center;
`;
const NullBoxStyle = styled.div`
  padding-left: 10px;
  border-radius: 5px;
  font-size: 13px;
  color: var(--eciBlue);
  background-color: var(--textDarkGray);
  width: 100%;
  height: 34px;
  line-height: 34px;
  font-weight: 600;
  width: 100px;
`;
export default function ClassificationPropRowForCad({
  propIndex,
  height = '50px',
  minWidth = '400px',
  classId,
  propertyArr, // 현재 분류가 가지고 있는 속성만 포함한 배열
  thick = false,
  newOptionTitle = 'show',
  sendRequest,
}) {
  const tokenAxios = useTokenAxios();

  const [propertyIdArr, setpropertyIdArr] = useState(propIndex && propIndex.map((item) => item.id));
  const originalIdArray =
    propIndex &&
    propIndex.map((item) => {
      const targetI = propertyArr.findIndex((propObj) => propObj.id === item.id);
      if (targetI > -1) {
        return propertyArr[targetI][newOptionTitle] * 1 === 1 ? '2' : propertyArr[targetI].option;
      }
      if (item.id === -1) {
        // manufacture number
        return '0';
      }
      return -1;
    });

  const [optionIdArray, setoptionIdArray] = useState(originalIdArray);

  const setTwoArrayState = (i) => {
    const func = async (val) => {
      const formData = new FormData();
      if (sendRequest) {
        await sendRequest(classId, propertyIdArr[i], formData);
      } else {
        const response = await tokenAxios.put(`show/${classId}/${propertyIdArr[i]}`, formData);
      }

      setoptionIdArray((arr) => {
        const tempArr = [...arr];
        tempArr[i] = val;
        return tempArr;
      });
    };
    return func;
  };

  //    selectBoxlist
  const selectBoxList =
    propIndex &&
    propIndex.map((propObj, i) => {
      if (optionIdArray[i] * 1 === 0 || optionIdArray[i] * 1 === 2) {
        return (
          <SelectBoxContainerStyle key={i}>
            <SelectBoxForClassification
              fontWeight="600"
              color="var(--eciBlue)"
              width="100px"
              height={`${height} - 16px`}
              fontSize="13px"
              value={optionIdArray[i]}
              setState={setTwoArrayState(i)}
              backgroundColor={optionIdArray[i] * 1 === 0 ? 'var(--skyBlue)' : 'orange'}
            >
              <option value="2">{newOptionTitle}</option>
              <option value="0">required</option>
            </SelectBoxForClassification>
          </SelectBoxContainerStyle>
        );
      }
      return (
        <SelectBoxContainerStyle>
          <NullBoxStyle>{optionIdArray[i] * 1 * 1 === -1 ? 'no' : 'option'}</NullBoxStyle>
        </SelectBoxContainerStyle>
      );
    });
  return (
    <ClassificationPropRowStyle height={height} minWidth={minWidth} thick={thick}>
      <div className="propList">{selectBoxList}</div>
    </ClassificationPropRowStyle>
  );
}
