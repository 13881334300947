import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ScrollContainer from '../../organism/ScrollContainer';
import AttachmentSection from '../../organism/AttachmentSection';
import Button from '../../atom/Button';
import RouteInitSection from '../../organism/RouteInitSection';

import RouteSection from '../../organism/RouteSection';

import { BoldBlueTextStyle, PageStyle, SectionGap } from '../../Style';
import { conditionByTypeObject } from '../../utility/Source';

import productStore from '../../store/productStore';
import ProductInformationSection from '../organism/ProductInformation';
import { appendProductForm, useGetProductData } from '../utility';
import TreeChartContainer from '../../organism/TreeChartContainer';
import useGetReviseData from '../../utility/hooks/useGetReviseData';
import useSaveFunction from '../../utility/hooks/useSaveFunction';
import useTempSaveFunction from '../../utility/hooks/useTempSaveFunction';
import SearchBoxGroup from '../../organism/SearchBoxGroup';

export default function ProductPage() {
  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const navigate = useNavigate();
  //    ---MODEL
  const store = productStore();
  const phase = searchParam.get('phase');
  const reviseId = searchParam.get('reviseId') || '';
  const { type } = params;
  //    ---VIEW

  //    ---ACTION
  const saveFunc = useSaveFunction(
    'product',
    appendProductForm,
    store,
    conditionByTypeObject[type].saveFuncType === 'put' || phase === '2',
    params.id,
    reviseId
  );
  const saveTempFunc = useTempSaveFunction(
    'product',
    appendProductForm,
    store,
    conditionByTypeObject[type].saveFuncType === 'put',
    params.id
  );
  //  ---BUSINESS LOGIC
  const getData = useGetProductData(params.id, store);

  const [treeData] = useGetReviseData(`product-tree/${params.id}`, reviseId, store);
  useEffect(() => {
    store.setisRouteActive(conditionByTypeObject[type].isRouteActive);
    if (params.id === 'new') {
      store.setStateByKey('classificationId')({
        classId: searchParam.get('classId'),
        name: searchParam.get('classificationName'),
      });
    }
    if (params.id !== 'new') {
      getData();
    }
    if (phase === '2') {
      navigate(`/product-bom/bom/dev/${type}/${params.id}`);
    }
    if (phase === '4' && type !== 'detail') {
      navigate(`/unit-cost/${type}/${params.id}`);
    }
    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  return (
    <PageStyle>
      <ScrollContainer
        isWideScroll={conditionByTypeObject[type].isScrollWide}
        scrollRefList={[
          [store.informationRef, 'Item Information'],
          [store.attachmentRef, 'Item Attachment'],
          [store.routeRef, 'Route Information'],
        ]}
        searchBoxComponent={<SearchBoxGroup store={store} />}
        tempButtonTitle={params.type !== 'detail' && !reviseId ? 'save as draft' : undefined}
        tempButtonOnclick={saveTempFunc}
        additionalPartTop={reviseId && <TreeChartContainer treeData={treeData} />}
      >
        <ProductInformationSection readOnly={store.isRouteActive} />
        <SectionGap />
        {phase >= 3 && (
          <>
            <BoldBlueTextStyle
              fontSize="20px"
              onClick={() => {
                navigate(`/product-bom/bom/dev/detail/${params.id}`);
              }}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Product BOM <span className="material-icons">open_in_new</span>
            </BoldBlueTextStyle>
            <SectionGap />
          </>
        )}
        {phase >= 4 && (
          <>
            <BoldBlueTextStyle
              fontSize="20px"
              onClick={() => {
                navigate(`/unit-cost/${type}/${params.id}`);
              }}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Unit Costs <span className="material-icons">open_in_new</span>
            </BoldBlueTextStyle>
            <SectionGap />
          </>
        )}
        <AttachmentSection
          editMode={conditionByTypeObject[type].isAttachmentEdit}
          title="Item Attachment"
          readOnly={store.isRouteActive}
          moduleStore={store}
        />
        <SectionGap />
        {!store.isRouteActive && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveFunc}
            condition={store.getisConditionFullfill()}
          >
            Save and Route
          </Button>
        )}
        {store.isRouteActive &&
          (conditionByTypeObject[type].routeSectionType === 'init' && phase < 2 ? (
            <RouteInitSection
              activate
              routeType="Product"
              itemId={store.id}
              moduleStore={store}
              afterUrl="/item"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={store}
            />
          ))}
        <SectionGap />
      </ScrollContainer>
    </PageStyle>
  );
}
