import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import LineTitle from '../atom/LineTitle';
import AnimationSearchInput from '../molecule/AnimationSearchInput';
import { PageStyle } from '../Style';

const LabelRowStyle = styled.div`
  display: flex;
  height: ${(props) => (props.height ? props.height : '40px')};
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 12px;
    font-weight: 600;
    color: var(--eciBlue);
  }
  .dataContent {
    width: 30%;
    font-size: 12px;
    color: var(--eciBlue);
  }
`;
export default function PCBInformationSection({
  readOnly,
  state,
  onSearchClick,
  dataArray,
  setsectionRef,
  placeholder,
  title,
  required,
}) {
  const dataRowList =
    dataArray &&
    dataArray.map((item) => {
      return (
        <LabelRowStyle>
          <div className="title">{item.title}</div>
          <div className="dataContent">{item.content}</div>
        </LabelRowStyle>
      );
    });
  const sectionRef = useRef();
  useEffect(() => {
    if (setsectionRef) {
      setsectionRef(sectionRef);
    }
  }, []);
  return (
    <PageStyle ref={sectionRef}>
      <LineTitle fontSize="16px" color="var(--eciBlue)" width="100%" height="60px">
        {title}{' '}
      </LineTitle>
      <AnimationSearchInput
        readOnly={readOnly}
        width="100%"
        height="40px"
        placeholder={placeholder}
        state={state}
        onClick={onSearchClick}
        required={required}
      />
      {state && dataRowList}
    </PageStyle>
  );
}
