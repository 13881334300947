import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ScrollContainer from '../../organism/ScrollContainer';
import AttachmentSection from '../../organism/AttachmentSection';
import Button from '../../atom/Button';
import RouteInitSection from '../../organism/RouteInitSection';

import RouteSection from '../../organism/RouteSection';

import { PageStyle, SectionGap } from '../../Style';
import { conditionByTypeObject } from '../../utility/Source';

import { appendSubsidiaryForm, useGetSubsidiaryData } from '../utility';
import subsidiaryStore from '../../store/subsidiaryStore';
import SubsidiaryInformationSection from '../organism/SubsidiaryInformationSection';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import useSaveFunction from '../../utility/hooks/useSaveFunction';
import useTempSaveFunction from '../../utility/hooks/useTempSaveFunction';
import SearchBoxGroup from '../../organism/SearchBoxGroup';

export default function SubsidiaryPage() {
  const tokenAxios = useTokenAxios();
  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const navigate = useNavigate();
  const noRoute = searchParam.get('noRoute') || false;

  //    ---MODEL
  const store = subsidiaryStore();
  const phase =
    searchParam.get('phase') > 10 ? searchParam.get('phase') - 10 : searchParam.get('phase'); // phase 11,12일 경우 선행개발 -> 1,2와 동일
  const { type } = params;
  const reviseId = searchParam.get('reviseId') || '';

  //    ---VIEW
  const isInfoSectionReadOnly = store.isRouteActive && !noRoute;
  const isAttachmentSectionReadOnly = store.isRouteActive && !noRoute;
  const isAttachmentSectionEditMode =
    conditionByTypeObject[type].isAttachmentEdit || phase > 2 || !noRoute;

  //    ---ACTION
  const saveFunc = useSaveFunction(
    'item',
    appendSubsidiaryForm,
    store,
    conditionByTypeObject[type].saveFuncType === 'put' || phase * 1 > 1,
    params.id,
    reviseId
  );
  const saveTempFunc = useTempSaveFunction(
    'item',
    appendSubsidiaryForm,
    store,
    conditionByTypeObject[type].saveFuncType === 'put',
    params.id
  );

  const saveFuncForNoRoute = useSaveFunction('item', appendSubsidiaryForm, store, true, params.id);
  const saveWithNoRoute = async () => {
    await saveFuncForNoRoute();
    store.setisRouteActive(false);
    alert('done!');
    navigate('/');
  };
  //  ---BUSINESS LOGIC

  const getJsonData = async () => {
    const classId = store.stateObj.classificationId?.id || searchParam.get('classId');
    const response = await tokenAxios.get(`attributes/${classId}`);
    store.setStateByKey('property')(response.data.result.data);
  };
  const getData = useGetSubsidiaryData(params.id, store, getJsonData);

  useEffect(() => {
    store.setisRouteActive(conditionByTypeObject[type].isRouteActive);
    store.setid(params.id && params.id);
    if (params.id === 'new') {
      store.setStateByKey('classificationId')({
        classId: searchParam.get('classId'),
        name: searchParam.get('classificationName'),
      });
      getJsonData();
    }
    if (params.id !== 'new') {
      getData();
    }

    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  // useEffect(() => {
  //   if (store.modalSelectedItemClassification.id) getModalList(store.mode);
  // }, [
  //   store.modalSelectedItemClassification,
  //   store.pageNum,
  //   store.rowsPerPage,
  //   store.searchText,
  // ]);
  return (
    <PageStyle>
      <ScrollContainer
        isWideScroll={conditionByTypeObject[type].isScrollWide}
        scrollRefList={[
          [store.informationRef, 'Item Information'],
          [store.attachmentRef, 'Item Attachment'],
          [store.routeRef, 'Route Information'],
        ]}
        searchBoxComponent={<SearchBoxGroup store={store} />}
        tempButtonTitle={!reviseId && 'save as draft'}
        tempButtonOnclick={saveTempFunc}
      >
        <SubsidiaryInformationSection readOnly={isInfoSectionReadOnly} noRoute={noRoute} />
        <SectionGap />

        <AttachmentSection
          editMode={isAttachmentSectionEditMode}
          title="Item Attachment"
          readOnly={isAttachmentSectionReadOnly}
          moduleStore={store}
        />
        <SectionGap />
        {!store.isRouteActive && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={noRoute ? saveWithNoRoute : saveFunc}
            condition={store.getisConditionFullfill()}
          >
            {noRoute ? 'Save' : 'Save and Route'}
          </Button>
        )}
        {store.isRouteActive &&
          (conditionByTypeObject[type].routeSectionType === 'init' ? (
            <RouteInitSection
              activate
              routeType="Subsidiary"
              itemId={store.id || params.id}
              moduleStore={store}
              afterUrl="/"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={store}
            />
          ))}
      </ScrollContainer>
    </PageStyle>
  );
}
