import { produce } from 'immer';

export const defaultStoreSeed = (set, get) => ({
  ...attachmentSeed(set, get),
  ...routeSeed(set, get),
  ...searchBoxSeed(set, get),
  ...paginationSeed(set, get),
  id: '',
  setid: (val) => {
    set(() => ({ id: val }));
  },

  // common section ref
  informationRef: '',
  setinformationRef: (val) => {
    set(() => ({ informationRef: val }));
  },
  attachmentRef: '',
  setattachmentRef: (val) => {
    set(() => ({ attachmentRef: val }));
  },
  routeRef: '',
  setrouteRef: (val) => {
    set(() => ({ routeRef: val }));
  },
  // init
  initDefaultStore: () => {
    set(() => ({
      id: '',
      isLoading: false,
      informationRef: '',
      attachmentRef: '',
      routeRef: '',

      ...attachmentSeed(set, get),
      ...routeSeed(set, get),
      ...searchBoxSeed(set, get),
      ...paginationSeed(set, get),
    }));
  },
});
export const stateObjSeed = (set, get) => ({
  setStateByKey: (key) => {
    //  하나의 속성을 관리하는 setState함수를 반환.
    //  인풋에 setState함수를 콜백으로 넘길 때 setStateByKey("carType")
    const { stateObj } = get();
    const setState = (val) => {
      //  already has key or not
      set(() => ({
        stateObj: produce(stateObj, (draft) => {
          draft[key] = val;
        }),
      }));
    };
    return setState;
  },
  deleteStateByKey: (key) => {
    //  하나의 속성을 관리하는 setState함수를 반환.
    //  인풋에 setState함수를 콜백으로 넘길 때 deleteStateByKey("carType")
    const { stateObj } = get();
    const setState = () => {
      //  already has key or not

      set(() => ({
        stateObj: produce(stateObj, (draft) => {
          delete draft[key];
        }),
      }));
    };
    setState();
    return setState;
  },

  setStateObj: (val, add = 'add') => {
    //  val은 obj
    const { stateObj } = get();
    if (add === 'add') {
      //  추가. 중복된 키는 덮어씀
      set(() => ({
        stateObj: { ...stateObj, ...val },
      }));
    } else {
      // add==="init"
      //  아예 기존거 무시하고 덮어쓰기

      set(() => ({
        stateObj: val,
      }));
    }
  },
  setStateObjWithData: (data) => {
    // dataObj를 통째로 받고, 사전에 stateObj에 정의한 키값에 맞는 변수들만 업데이트.
    const { stateObj } = get();
    const tmpObj = { ...stateObj };
    Object.keys(tmpObj).forEach((key) => {
      if (data) {
        if (data[key] || data[key] === 0) {
          tmpObj[key] = data[key];
        } else if (data[key.split('Id')[0]]) {
          // 백엔드와 키워드 통일 실패로 Id가 뒤에 붙는 경우 존재.
          tmpObj[key] = data[key.split('Id')[0]];
        }
      }
    });
    set(() => ({
      stateObj: tmpObj,
    }));
  },
  initStateObj: (sourceObjArr) => {
    //  sourceObj에서 키값들을 빼와 stateObj의 기본 키값 초기화
    //  기존에 있는 키값에 새로운 키값을 더함(inputGrid 여러개 사용 대응)
    const { stateObj } = get();
    const newObj = { ...stateObj };
    sourceObjArr.forEach((obj) => {
      if (obj?.requestName) {
        newObj[obj?.requestName] = newObj[obj?.requestName] || '';
      }
      if (obj?.requestName2) {
        newObj[obj?.requestName2] = newObj[obj?.requestName2] || '';
      }
    });

    set(() => ({
      stateObj: newObj,
    }));
  },
});

const attachmentSeed = (set, get) => ({
  isAttachmentValid: true,
  setisAttachmentValid: (val) => {
    set(() => ({ isAttachmentValid: val }));
  },
  attachmentFileArray: [],
  setattachmentFileArray: (val) => {
    set(() => ({ attachmentFileArray: val }));
  },
  attachmentOriginArray: [],
  setattachmentOriginArray: (val) => {
    set(() => ({
      attachmentOriginArray: val,
    }));
  },
  attachmentTagArray: [],
  setattachmentTagArray: (val) => {
    set(() => ({ attachmentTagArray: val }));
  },
  attachmentCommentArray: [],
  setattachmentCommentArray: (val) => {
    set(() => ({ attachmentCommentArray: val }));
  },
  attachmentUploaderArray: [],
  setattachmentUploaderArray: (val) => {
    set(() => ({ attachmentUploaderArray: val }));
  },
  attachmentDateArray: [],
  setattachmentDateArray: (val) => {
    set(() => ({ attachmentDateArray: val }));
  },
  attachmentIdArray: [],
  setattachmentIdArray: (val) => {
    set(() => ({ attachmentIdArray: val }));
  },
  attachmentModifiedAtArray: [],
  setattachmentModifiedAtArray: (val) => {
    set(() => ({ attachmentModifiedAtArray: val }));
  },
  // edit attachment
  deletedFileIdArray: [],
  setdeletedFileIdArray: (val) => {
    set(() => ({ deletedFileIdArray: val }));
  },
  addedAttachmentArray: [],
  setaddedAttachmentArray: (val) => {
    set(() => ({ addedAttachmentArray: val }));
  },
  deletedAttachmentArray: [],
  setdeletedAttachmentArray: (val) => {
    set(() => ({ deletedAttachmentArray: val }));
  },
});
const routeSeed = (set, get) => ({
  isRouteActive: false,
  setisRouteActive: (val) => {
    set(() => ({ isRouteActive: val }));
  },
  routeNumber: '',
  setrouteNumber: (val) => {
    set(() => ({ routeNumber: val }));
  },
  routeData: [],
  setrouteData: (val) => {
    set(() => ({ routeData: val }));
  },

  // route init

  members: [],
  fullMemberLength: 0,
  setrouteMemberArr: (arr) => {
    set(() => ({ members: arr }));
  },
  setmembers: (val, index) => {
    const tmpmember = get().members;
    const fullLength = get().fullMemberLength;
    if (index === 'ALL') {
      for (let i = 0; i < fullLength; i += 1) {
        const arr = tmpmember[i];
        const tmpArr = arr ? [...arr, val] : [val];
        tmpmember[i] = tmpArr;
      }
    }
    if (index !== 'ALL') {
      tmpmember[index] = tmpmember[index] ? [...tmpmember[index], val] : [val];
    }
    set(() => ({ members: tmpmember }));
  },
  targetMember: '',
  settargetMember: (val, fullLength) => {
    set(() => ({ targetMember: val, fullMemberLength: fullLength || 0 }));
  },
  deletemember: (id, index) => {
    const tmpmemberArr = [...get().members];
    if (index === 'ALL') {
      tmpmemberArr.forEach((memArr, i) => {
        tmpmemberArr[i] = memArr.filter((mem) => mem.id !== id);
      });
    } else {
      const newArr = tmpmemberArr[index] && tmpmemberArr[index].filter((mem) => mem.id !== id);
      tmpmemberArr[index] = newArr;
    }

    set(() => ({ members: tmpmemberArr }));
  },
});
const searchBoxSeed = (set, get) => ({
  searchBoxType: '',
  setsearchBoxType: (val) => {
    set(() => ({
      searchBoxType: val,
      searchBoxTargetIndex: '',
      isMemberSearchBox: false,
      // dataSearchBoxType: "",
      categorySearchBoxType: '',
    }));
  },
  isMemberSearchBox: false,
  openMemberSearchBox: () => {
    set(() => ({ isMemberSearchBox: true, searchBoxType: '' }));
  },
  searchBoxTargetIndex: '',
  setsearchBoxTargetIndex: (val) => {
    if (typeof val === 'function') {
      set((state) => ({ searchBoxTargetIndex: val(state) }));
    } else {
      set(() => ({ searchBoxTargetIndex: val }));
    }
  },
  setsearchBoxProperty: (type, item) => {
    const { stateObj } = get();
    //  array에 추가하는 경우 대비
    let tmpArr;
    const i = get().searchBoxTargetIndex;
    if (typeof i === 'number') {
      tmpArr = structuredClone(stateObj[type]);
      tmpArr[i] = item;
    }
    set(() => ({
      stateObj: produce(stateObj, (draft) => {
        if (typeof i === 'number') {
          draft[type] = tmpArr;
        } else {
          draft[type] = item;
        }
      }),
    }));
  },

  // dataSearchBoxType: "",
  // setdataSearchBoxType: (val) => {
  //   set(() => ({
  //     searchBoxType: "",
  //     dataSearchBoxType: val,
  //     searchBoxTargetIndex: "",
  //     categorySearchBoxType: "",
  //   }));
  // },
  categorySearchBoxType: '',
  setcategorySearchBoxType: (val) => {
    set(() => ({
      searchBoxType: '',
      // dataSearchBoxType: "",
      categorySearchBoxType: val,
      searchBoxTargetIndex: '',
    }));
  },
});

const paginationSeed = (set, get) => ({
  totalPage: '',
  settotalPage: (val) => {
    set(() => ({ totalPage: val }));
  },
  searchText: '',
  setsearchText: (val) => {
    set(() => ({ searchText: val }));
  },
  pageNum: 0,
  setpageNum: (val) => {
    set(() => ({ pageNum: val }));
  },
  rowsPerPage: 6,
  setrowsPerPage: (val) => {
    set(() => ({ rowsPerPage: val }));
  },
});
