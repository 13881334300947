import React, { useState } from 'react';
import styled from 'styled-components';

import RouterButton from '../../molecule/RouterButton';
import GraphProject from './GraphProject';
import GraphProduct from './GraphProduct';
import GraphChange from './GraphChange';
import GraphRelease from './GraphRelease';
import txt from '../../Text';

const ListSectionStyle = styled.div`
  .router {
    display: flex;
    width: 100%;
    border-bottom: solid 1px var(--textGray);
  }
`;

export default function ListSection() {
  const [uriIndex, seturiIndex] = useState(0);
  const uriList = txt.dashBoard.chartTypeList;

  const graphObj = {
    0: <GraphProject />,
    1: <GraphProduct />,
    2: <GraphChange />,
    3: <GraphRelease />,
  };
  return (
    <ListSectionStyle>
      <div className="router">
        {uriList.map((title, i) => (
          <RouterButton
            key={i}
            text={title}
            onClick={() => seturiIndex(i)}
            isActive={uriList[uriIndex] === title}
          />
        ))}
      </div>
      {graphObj[uriIndex]}
    </ListSectionStyle>
  );
}
