import { useEffect, useState } from 'react';
import useTokenAxios from './useTokenAxios';

export default function useGetReviseData(reviseTreeUrl, reviseId, store) {
  const [treeData, settreeData] = useState([]);
  const tokenAxios = useTokenAxios();

  const getTreeData = async () => {
    const response = await tokenAxios.get(reviseTreeUrl);
    settreeData(response.data.result.data || []);
  };
  const setRouteMember = async () => {
    const routeResponse = await tokenAxios.get(`/route/members/${store.routeNumber}`);
    store.setrouteMemberArr(['', ...(routeResponse.data?.result?.data || [])]);
  };
  useEffect(() => {
    if (reviseId) {
      getTreeData();
      if (store.routeNumber) {
        setRouteMember();
      }
    }
  }, [store.routeNumber]);
  return [treeData];
}
