import React from 'react';
import styled from 'styled-components';
import { ScrollDefault } from '../../Style';
import BoldText from '../../atom/BoldText';

const InsertPopupStyle = styled.div`
  width: 320px;
  padding: 16px 0;
  max-height: 600px;
  ${ScrollDefault}
  overflow-x: hidden;
  border-radius: 8px;

  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(${(props) => props.left - 330}px, ${(props) => props.top + 12}px);
  text-align: left;
  padding: 20px 16px;

  .line {
    border-bottom: solid var(--textDarkGray) 1px;
    width: 100%;
  }
`;
const HistoryRowStyle = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  color: var(--textDarkGray);
  .footprintName {
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
  border-bottom: solid var(--textGray);
`;

const InsertButtonStyle = styled.div`
  display: block;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: var(--eciBlue);
  border: solid 1px var(--eciBlue);
  border-radius: 4px;
  background-color: #f2f4f8;
  cursor: pointer;
  color: var(--eciBlue);
  text-decoration: none;
  width: 30%;
`;
export default function InsertPopup({ top, left, footprintList = [], onInsert = () => {} }) {
  return (
    <InsertPopupStyle
      top={top}
      left={left}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <BoldText fontSize="14px" color="var(--textDarkGray)">
        Select Footprint
      </BoldText>

      <div className="line" />

      {footprintList.map((footprint, i) => (
        <HistoryRowStyle key={i}>
          <div className="footprintName">{footprintList[i]}</div>

          <InsertButtonStyle onClick={() => onInsert(footprint)}>INSERT</InsertButtonStyle>
        </HistoryRowStyle>
      ))}
    </InsertPopupStyle>
  );
}
