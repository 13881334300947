import {
  appendAttachmentFormData,
  appendStateObj,
  deleteFormdataKey,
  setAttachmentArrays,
} from '../utility/Utility';
import useTokenAxios from '../utility/hooks/useTokenAxios';

export const appendReleaseForm = (edit, store) => {
  const formData = new FormData();
  const { stateObj } = store;
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, ['releaseOrganizationId', 'product']);

  formData.append(
    'releaseOrganizationId',
    stateObj.releaseOrganizationId.map((item) => item.id)
  );
  formData.append(
    'releaseProductId',
    stateObj.product.map((item) => item.id)
  );
  appendAttachmentFormData(formData, edit, store);

  return formData;
};
export const useGetReleaseData = (id, store) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/release/${id}`);
    const { data } = response.data.result;
    const { setStateObjWithData, deleteStateByKey, setStateByKey, setrouteNumber } = store;
    // setstate
    setStateObjWithData(data);
    setAttachmentArrays(data.productAttachments, store);
    setrouteNumber(data.routeId);
  };
};
