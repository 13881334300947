import React from 'react';
import styled from 'styled-components';

const RouterButtonStyle = styled.div`
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding: 0 36px;
  border-bottom: ${(props) => props.isActive && 'solid'} var(--eciBlue) 3px;
  color: ${(props) => (props.isActive ? 'var(--eciBlue)' : 'var(--textGray)')};
  cursor: pointer;
  transition: ease-in 0.1s;
`;
export default function RouterButton({ text, isActive, onClick }) {
  return (
    <RouterButtonStyle onClick={onClick} isActive={isActive}>
      {text}
    </RouterButtonStyle>
  );
}
