import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import CategorizedTree from '../../molecule/CategorizedTree';
import PaginationTable from '../../molecule/PaginationTable';
import SearchBar from '../../molecule/SearchBar';

import { PageStyle } from '../../Style';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import ImagePopupSymbol from '../../molecule/ImagePopupSymbol';
import txt from '../../Text';
import { getSymbolSource } from '../../utility/Utility';

const SymbolManagePageStyle = styled(PageStyle)`
  display: flex;
  .right {
    margin-left: 24px;
    width: 100%;
    height: 80%;
    .searchPart {
      display: flex;
      width: 100%;
      .gap {
        width: 40px;
      }
    }
  }
  .rowIcon {
    color: var(--textDarkGray);
    font-size: 20px;
    padding-top: 16px;
    cursor: pointer;
  }
`;
export default function SymbolManagePage() {
  const navigte = useNavigate();
  const tokenAxios = useTokenAxios();

  const [searchText, setsearchText] = useState('');
  const [date, setdate] = useState('');
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState('0');
  const [rowsPerPage, setrowsPerPage] = useState(7);
  const [dataList, setdataList] = useState([]);
  const widthArray = [4, 5, 3, 5, 4, 4];

  const [rerenderFlag, setrerenderFlag] = useState(false);
  //  ACTION
  const sendDelete = async (id) => {
    await tokenAxios.delete(`symbol/${id}`);
    setrerenderFlag((flag) => !flag);
  };
  const itemRowList = dataList?.map((item, i) => {
    return (
      <TableRow
        key={i}
        widthArray={widthArray}
        itemArray={[
          item.name,
          item.cadAddress,
          item.createdAt,
          item.comment,
          <ImagePopupSymbol
            width="100px"
            height="100px"
            imgSourceArray={[getSymbolSource(item.image)]}
          />,
          <span className="material-icons delete rowIcon" onClick={() => sendDelete(item.id)}>
            delete
          </span>,
        ]}
      />
    );
  });

  const getItemList = async () => {
    const response = await tokenAxios.get(
      `symbol?size=${rowsPerPage}&page=${pageNum}${
        searchText ? `&name=${searchText}` : ''
      }${date ? `&date=${date}` : ''}`
    );
    const data = response.data?.result?.data || response.data;
    setdataList([...data.content]);
    setTotalPage(data.totalPages);
  };

  useEffect(() => {
    //  init
    setsearchText('');
    setdate('');
    setdataList([]);

    getItemList();
  }, []);
  useEffect(() => {
    getItemList();
  }, [searchText, date, rerenderFlag, pageNum, rowsPerPage]);
  return (
    <SymbolManagePageStyle>
      <div className="right">
        <div className="searchPart">
          <SearchBar
            width="280px"
            height="30px"
            placeholder="Search Items"
            state={searchText}
            setState={setsearchText}
          />
          <div className="gap" />
          <br />
        </div>
        <br />
        <PaginationTable
          height="50vh"
          noshadow
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setPageNum}
        >
          <TableIndexRow widthArray={widthArray} itemArray={txt.admin.symbolPageIndex} />
          {itemRowList}
        </PaginationTable>
      </div>
    </SymbolManagePageStyle>
  );
}
