import React from 'react';
import SearchBox from './SearchBox';
import MemberSearchBox from './MemberSearchBox';
import DataSearchBox from './DataSearchBox';

export default function SearchBoxGroup({ store, type, setproperty, additionalBoxArr = [] }) {
  const setProperty =
    store.searchBoxType.lastIndexOf('-J-') > -1
      ? store.setsearchBoxPropertyForJson
      : store.setsearchBoxProperty;

  const searchboxType =
    store.searchBoxType.lastIndexOf('-J-') > -1
      ? store.searchBoxType.replace('-J-', '')
      : store.searchBoxType;
  return [
    store.searchBoxType !== 'members' && (
      <SearchBox
        key={0}
        width="100%"
        height="100vh - 218px"
        type={type || searchboxType}
        setproperty={setproperty || setProperty}
        propertyIndex={store.targetMember}
        isActive={store.searchBoxType !== 'members'}
      />
    ),
    store.isMemberSearchBox && (
      <MemberSearchBox
        key={1}
        width="100%"
        height="100vh - 218px"
        isActive={store.isMemberSearchBox}
        moduleStore={store}
      />
    ),
    // <DataSearchBox
    //   key={2}
    //   width="100%"
    //   setstate={(val) => {
    //     store.setdataSearchBoxProperty(store.dataSearchBoxType, val);
    //   }}
    //   type={store.dataSearchBoxType}
    // />,
    ...additionalBoxArr,
  ];
}
