import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import AnimationDateInput from '../../molecule/AnimationDateInput';
import ImagePopupSymbol from '../../molecule/ImagePopupSymbol';
import PaginationTable from '../../molecule/PaginationTable';
import SearchBar from '../../molecule/SearchBar';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import txt from '../../Text';
import { getFootprintSource, getSymbolSource } from '../../utility/Utility';

const CategorySearchBoxStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});

  padding-top: 24px;
  display: flex;
  display: ${(props) => (props.activate ? '' : 'none')};

  .right {
    margin-left: 24px;
    width: 100%;
    height: 80%;
    .searchPart {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
`;
function CategorySearchBox({ width, height, setstate, document, type, dataType, targetState }) {
  const tokenAxios = useTokenAxios();
  const [searchText, setsearchText] = useState('');
  const [date, setdate] = useState('');
  const [targetClassification, settargetClassification] = useState('');
  const [totalPage, setTotalPage] = useState(1);
  const [pageNum, setPageNum] = useState('0');
  const [rowsPerPage, setrowsPerPage] = useState(7);
  const [dataList, setdataList] = useState([]);
  const widthArray = [1, 4, 4, 4];
  const itemRowList = dataList?.map((item, i) => {
    const itemArrayFunc = (data) => {
      if (type === 'symbol') {
        return [
          <input
            type="checkbox"
            checked={targetState?.find((obj) => obj.id === data.id)}
            onChange={() => {}}
            defaultValue="1"
          />,
          data.name,
          data.createdAt,
          <ImagePopupSymbol
            width="140px"
            height="140px"
            key={-1}
            imgSourceArray={([data.image] || []).map((originStr) =>
              getSymbolSource(Array.isArray(originStr) ? originStr[0] : originStr)
            )}
          />,
        ];
      }
      if (type === 'footPrint') {
        return [
          <input type="checkbox" checked={targetState?.find((obj) => obj.id === item.id)} />,
          data.name,
          data.createdAt,
          <ImagePopupSymbol
            width="140px"
            height="140px"
            imgSourceArray={
              Array.isArray(data.image)
                ? getFootprintSource(data.image)
                : [getFootprintSource(data.image)]
            }
          />,
        ];
      }
      return [];
    };

    return (
      <TableRow
        style={{ position: 'relative' }}
        key={i}
        onClick={() => {
          setstate(item);
        }}
        widthArray={widthArray}
        itemArray={itemArrayFunc(item)}
      />
    );
  });

  const getItemList = async () => {
    const response = await tokenAxios.get(
      `${type}?size=${rowsPerPage}&page=${pageNum}${searchText ? `&name=${searchText}` : ''}
      ${date && `&date=${date}`}`
    );
    const data = response.data?.result?.data || response.data;
    setdataList([...data.content]);
    setTotalPage(data.totalPages);
  };
  useEffect(() => {
    //  init
    setsearchText('');
    settargetClassification('');
    setdate('');
    setdataList([]);
    if (dataType || type) {
      getItemList();
    }
  }, [type]);
  useEffect(() => {
    if (dataType || type) {
      getItemList();
    }
  }, [searchText, date, rowsPerPage, pageNum]);

  return (
    <CategorySearchBoxStyle width={width} height={height} activate={type}>
      <div className="right">
        <div className="searchPart">
          <SearchBar
            width="150px"
            height="30px"
            placeholder="Search Items"
            state={searchText}
            setState={setsearchText}
          />
          {/* <AnimationDateInput
            width="100px"
            height="30px"
            placeholder={txt.elec.registerDate}
            state={date}
            setState={setdate}
          /> */}
          <br />
        </div>
        <br />
        <PaginationTable
          height="100%"
          noshadow
          totalPage={totalPage}
          setRowsPerPage={setrowsPerPage}
          rowsPerPageDefault={7}
          pageNum={pageNum}
          rowsPerPage={rowsPerPage}
          setpageNum={setPageNum}
        >
          <TableIndexRow
            widthArray={widthArray}
            itemArray={
              type === 'symbol'
                ? txt.elec.symbolSearchBoxSymbolIndex
                : txt.elec.footprintSearchBoxFootprintIndex
            }
          />
          {itemRowList}
        </PaginationTable>
      </div>
    </CategorySearchBoxStyle>
  );
}
export default React.memo(CategorySearchBox);
