import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ScrollController from '../../molecule/ScrollController';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import ModalBox from '../../organism/ModalBox';
import Input from '../../atom/Input';
import RouteSection from '../../organism/RouteSection';
import mPBAStore from '../../store/mPBAStore';
import {
  BlueButtonStyle,
  GrayButton,
  LightGrayInputContainer,
  PageStyle,
  ScrollDefault,
} from '../../Style';

import PBAColumn from '../molecule/PBAColumn';
import MPBAModal from '../organism/MPBAModal';
import { conditionByTypeObject } from '../../utility/Source';
import MPBASearchSection from '../organism/MPBASearchSection';
import DeleteButton from '../../atom/DeleteButton';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import txt from '../../Text';

const MPBAPageStyle = styled(PageStyle)`
  display: flex;
  width: 100%;
  .PBAColumnTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.isHighLight ? 'white' : 'var(--textDarkGray)')};
    font-size: 12px;
    font-weight: 600;
    height: calc(${(props) => props.titleHeight});
    background-color: var(--eciBlue);
    height: 50px;
    width: 100%;
  }
  .gap {
    width: 20px;
    flex-shrink: 0;
  }
  .mainPart {
    ${ScrollDefault}
    height: calc(100vh - 260px);
    width: calc(100% - 100px);
    .tableSection {
      height: calc(100vh - 320px);

      .tableFlex {
        .indexContainer {
          display: flex;

          .rightIndexContainer {
            display: flex;
            width: max-content;
            max-width: calc(40vw + 240px);
            justify-content: space-between;
            .titleBox {
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .flex {
              width: 100%;
            }

            justify-content: space-between;
            ::-webkit-scrollbar {
              width: 0px;
              height: 7px;
            }
            overflow: hidden;
          }
        }
        .body {
          display: flex;
          ${ScrollDefault}
          max-height: 45vh;
        }
        .table {
          flex-grow: 0;
          flex-shrink: 0;
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
          background-color: white;
          height: max-content;

          .tableScroll {
          }
          .leftTableScroll {
            ::-webkit-scrollbar {
              width: 0px;
              height: 7px;
            }
          }
          .rightTableScroll {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }
        }

        .leftTable {
          width: calc(28% + 1px);
        }

        .rightTable {
          position: relative;
          width: max-content;
          max-width: calc(70% - 8px);
          ${ScrollDefault}
          overflow-x: scroll;
          overflow-y: visible;
          .rightTableContent {
            width: max-content;
            /* min-width: 100%; */
            overflow-x: hidden;
          }
          .columnContainer {
            margin-left: 1px;
            display: flex;
            height: 100%;
          }
          .tableBox {
            width: 40vw;
            border-left: dotted var(--textGray);
          }
        }
      }
    }
  }
  .flex {
    display: flex;
  }
  .controllerMain {
    padding-left: 80px;
    display: flex;
    align-items: center;

    .radio {
      flex-shrink: 0;

      height: 40px;
      display: flex;
      align-items: center;
      label {
        font-size: 12px;
      }
      margin-right: 24px;
    }
    .blank {
      width: 200px;
    }
  }
  .cancelApply {
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-top: solid var(--textGray) 1px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .actionButton {
      cursor: pointer;
      padding: 4px 20px;
      font-weight: 600;
      color: var(--textDarkGray);
    }
    .cancel {
      border-right: 3px solid var(--textGray);
    }
    .apply {
      color: var(--eciBlue);
    }
  }
  .pbaChild {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
  }
  .routeBox {
    width: 50%;
  }
  .pbaLink {
    text-decoration: none;
    color: var(--textDarkGray);
  }
`;
const PBAAddingBoxStyle = styled.div`
  height: 160px;
  width: calc(${(props) => props.width});
  background-color: #f5f5f5;
  transform: translate(${(props) => props.scrollX}px, 0);
`;
const IndexTitleBox = styled.div`
  width: ${(props) => props.width || '120px'};
  height: ${(props) => props.height || '50px'};
  background-color: ${(props) => (props.isHighLight ? 'var(--eciBlue)' : 'white')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isHighLight ? 'white' : 'var(--textDarkGray)')};
  font-size: 12px;
  background-color: ${(props) => props.isHighLight && 'var(--eciBlue)'};
  border-bottom: solid 1px var(--eciBlue);
  flex-shrink: 0;
  text-decoration: underline;
  :hover {
    font-weight: 600;
    transition: 0.2s all;
    cursor: pointer;
  }
`;
export default function MPBAPage() {
  const tokenAxios = useTokenAxios();

  const mpbastore = mPBAStore();
  const params = useParams();
  const { type } = params;
  const navigate = useNavigate();
  //    -----VIEW
  //  component stat
  const pbaColumnActiveStatObj = {
    0: { isHighLight: true, isCheckBox: true },
    1: { isHighLight: false, isCheckBox: false },
    2: { isHighLight: true, isCheckBox: false, isButton: true },
  };
  const mpbaSaveButtonStat = !!mpbastore.newItemList[0] || !!mpbastore.deletedMPBAList[0];
  const pbaSaveButtonStat = !!Object.keys(mpbastore.changedPBAColumnObject)[0];
  const defaultSaveButtonStat = !!mpbastore.defaultCheckedPBAColumnList[0];
  const isAddPartButton = mpbastore.mode === '1' && mpbastore.isOneByOne === '1';
  //  scroll
  const formRef = useRef();
  const informationRef = useRef();

  const rightIndexHorizonScrollRef = useRef();
  const rightTableHorizonScrollRef = useRef();
  const tableBodyRef = useRef();
  const [righTableScrollX, setrighTableScrollX] = useState(0);

  const onTableScroll = (targetRef, otherRef) => {
    return (e) => {
      setrighTableScrollX(e.target.scrollLeft);

      if (otherRef.current.scrollLeft !== targetRef.current.scrollLeft) {
        otherRef.current.scrollLeft = targetRef.current.scrollLeft;
      }
    };
  };
  //    -----ACTION
  //  mpba
  const openMPBAModal = () => {
    mpbastore.setisModal(true);
  };
  const checkTempArray = (value, arr) => {
    const tmpArr = [...arr];
    const targetIndex = tmpArr.findIndex((itemObj) => itemObj.id === value.id);
    if (targetIndex > -1) {
      tmpArr.splice(targetIndex, 1);
    } else {
      tmpArr.push(value);
    }
    return tmpArr;
  };
  const onCheckModalItem = (val) => {
    mpbastore.setmodalTempCheckedList(checkTempArray(val, mpbastore.modalTempCheckedList));
  };
  const exitModalCallBack = () => {
    // isModal : false로 바뀌면 자동으로 실행
    mpbastore.setmodalTempCheckedList([]);
    mpbastore.setmodalCheckedItemList([]);
    mpbastore.setmodalItemList([]);
    mpbastore.setmodalSelectedItemClassification({});
  };
  const applyMPBAModalLv2 = () => {
    mpbastore.setmodalCheckedItemList(
      mpbastore.modalTempCheckedList.map((obj) => ({ ...obj, cnt: 1 }))
    );
  };

  const applyMPBAModalLv3 = () => {
    //  mpbalist마지막에 push(여러개)
    // 기존 mpbaList에 존재하던 애라면, 새로 추가하지 않고 수량만 늘려준다.
    const nonDuplicateArr = [];
    const tmpNewItemList = structuredClone(mpbastore.newItemList);
    const tmpMPBAList = structuredClone(mpbastore.MPBAList);
    mpbastore.modalCheckedItemList.forEach((obj) => {
      let isDup = false;
      // MPBAList에서 중복된 아이템 추가시 수량 추가
      tmpMPBAList.forEach((MPBAObj, j) => {
        if (MPBAObj.id === obj.id) {
          isDup = true;
          tmpMPBAList[j].cnt = `${tmpMPBAList[j].cnt * 1 + obj.cnt * 1}`;
        }
      });
      // 추가된 수량을 newItemList에도 반영
      tmpNewItemList.forEach((newItemObj, j) => {
        if (newItemObj.id === obj.id) {
          tmpNewItemList[j].cnt = `${tmpNewItemList[j].cnt * 1 + obj.cnt * 1}`;
        }
      });
      if (!isDup) {
        nonDuplicateArr.push(obj);
        tmpNewItemList.push(obj); // MPBAList에서 중복이면 newItemList에서도 중복.
        // 백엔드에서 기존 MPBAList에 존재하는 애들은 제외하고 모달에 리스트를 주기 때문에
        // newItemList와 MPBAList에서의 중복 체크 결과는 동일하다.
      }
    });
    mpbastore.setMPBAList(tmpMPBAList);
    mpbastore.setnewItemlist(tmpNewItemList);

    mpbastore.appendMPBAList(
      nonDuplicateArr.map((item) => ({
        ...item,
        isYellow: true,
        ref: 'new',
        unit: 'ea',
        deletable: true, // check
      }))
    );
    mpbastore.appendPBAColumnList(
      nonDuplicateArr.map((item) => ({
        id: 'new',
        partNumber: '-2',
        // used: 12134,
      }))
    );
    //  yellosIndexArray에 인덱스추가
    mpbastore.appendYellowIndexArray(
      nonDuplicateArr.map((item, i) => mpbastore.MPBAList.length + i)
    );

    //  창 닫기
    mpbastore.setisModal(false);
    //  스크롤 당기기
    setTimeout(() => {
      tableBodyRef.current.scrollTo({ top: 10000, behavior: 'smooth' });
    }, 150);
  };
  const onModalCountInputChange = (val, i) => {
    mpbastore.changeCheckedItemListCnt(val, i);
  };
  const onDeleteModalAddedItems = (id) => {
    //

    mpbastore.setmodalTempCheckedList(
      mpbastore.modalTempCheckedList.filter((itemObj) => itemObj.id !== id)
    );
    mpbastore.setmodalCheckedItemList(
      mpbastore.modalCheckedItemList.filter((itemObj) => itemObj.id !== id)
    );
  };
  const saveMPBA = async () => {
    const formData = new FormData();
    formData.append(
      'cnt',
      mpbastore.newItemList.map((itemObj) => itemObj.cnt)
    );
    formData.append(
      'itemIds',
      mpbastore.newItemList.map((itemObj) => itemObj.id)
    );
    formData.append(
      'deletedIds',
      mpbastore.deletedMPBAList.map((itemObj) => itemObj.id)
    );
    //  modifierID추가
    const response = await tokenAxios.put(`mpba/${params.id}`, formData);
    window.location.reload();
  };
  // pba
  const onClickPBAColumnButton = (valObj, index) => {
    mpbastore.insertMPBAList({ ...valObj, id: 'PBAAdd', ref: 'new!' }, index + 1);
    mpbastore.changeRowYellow(index);

    mpbastore.insertPBAColumnList({ id: 'PBAAdd', partNumber: 'PBAAdd' }, index + 1);

    mpbastore.setisWriting(true);
    mpbastore.setnewPBAOption(valObj.used === '-1' || valObj.used === '-2' ? valObj.used : '0');
    mpbastore.setnewPBAPartNumber(valObj.used !== '-1' && valObj.used !== '-2' && valObj.used);
    mpbastore.settargetPart(valObj);
  };

  const onClickPBASearchButton = () => {
    mpbastore.setisModal(true);
  };
  const onCheckOnlyOneModalItem = (val) => {
    mpbastore.setmodalTempCheckedList([val]);
  };
  const applyPBAModalLv2 = applyMPBAModalLv2;
  const applyPBAModalLv3 = () => {
    mpbastore.setnewItemlist([...mpbastore.modalCheckedItemList]);

    mpbastore.setnewPBAPartNumber(mpbastore.modalCheckedItemList[0].number);
    //  창 닫기
    mpbastore.setisModal(false);
  };
  const closePBAController = () => {
    mpbastore.filterMPBAListByKey('PBAAdd');
    mpbastore.filterPBAColumnListByKey('PBAAdd');
    mpbastore.setnewPBAPartNumber('');
    mpbastore.settargetPart({});
    mpbastore.setisWriting(false);
  };
  const handleRadioButton = (val) => {
    mpbastore.setnewPBAOption(val);
  };
  const applyPBAController = () => {
    //  PBAColumn 업데이트
    if (mpbastore.newPBAOption === '0' && !mpbastore.newPBAPartNumber) {
      alert('choose new item');
    } else {
      const tmppbaColumn = structuredClone(mpbastore.PBAColumnList);
      tmppbaColumn.forEach((columnObj) => {
        columnObj.items.forEach((item) => {
          if (item.id === mpbastore.targetPart.id) {
            item.used = mpbastore.newPBAOption;

            item.partNumber =
              mpbastore.newPBAOption * 1 < 0
                ? mpbastore.newPBAOption === '-2'
                  ? 'X'
                  : 'O'
                : mpbastore.newPBAPartNumber;
          }
        });
      });
      mpbastore.setPBAColumnList(tmppbaColumn);
      mpbastore.appendChangedPBAColumnObject(
        mpbastore.targetPart.id,
        mpbastore.newPBAOption * 1 < 0 ? mpbastore.newPBAOption : mpbastore.newPBAPartNumber
      );
      closePBAController();
    }
  };
  const cancelPBAController = () => {
    closePBAController();

    mpbastore.dismissRowYellow(mpbastore.yellowIndexArray[mpbastore.yellowIndexArray.length - 1]);
  };
  const savePBA = async () => {
    const formData = new FormData();
    formData.append('ids', Object.keys(mpbastore.changedPBAColumnObject));

    formData.append('options', Object.values(mpbastore.changedPBAColumnObject));
    //  modifierID추가
    const response = await tokenAxios.put(`pba-bom`, formData);
    window.location.reload();
  };
  //  default
  const checkPBAColumn = (val) => {
    mpbastore.setdefaultCheckedPBAColumnList(
      checkTempArray(val, mpbastore.defaultCheckedPBAColumnList)
    );
  };
  const saveDefault = () => {
    mpbastore.setisRouteActive(true);
    setTimeout(() => {
      formRef.current.scrollTo({ top: 10000, behavior: 'smooth' });
    }, 150);
  };
  //    -----BUSINIESLOGIC
  //  componentStat
  const actionFunctionByModeObj = {
    0: {},
    1: {
      applyModalLv2: applyMPBAModalLv2,
      applyModalLv3: applyMPBAModalLv3,
      itemtreeUrl: '',
      saveFunc: saveMPBA,
      onCheckModalItem,
    },
    2: {
      applyModalLv2: applyPBAModalLv2,
      applyModalLv3: applyPBAModalLv3,
      itemtreeUrl: '',
      saveFunc: savePBA,
      onCheckModalItem: onCheckOnlyOneModalItem,
    },
  };
  //  modal
  const getClassificationTree = async () => {
    const response = await tokenAxios.get('classification-tree');
    mpbastore.setmodalClassificationTree(
      response.data.result?.data.filter((obj) => obj.id * 1 >= 3)
    );
  };
  const getmodalSelectedItemList = async (mode) => {
    let response = [];
    if (mode === '1') {
      response = await tokenAxios.get(
        `item-replace-page/${params.id}/${
          mpbastore.modalSelectedItemClassification.classificationId || -1
        }?&size=${mpbastore.rowsPerPage}&page=${mpbastore.pageNum}&name=${mpbastore.searchText}`
      );
    } else if (mode === '2') {
      response = await tokenAxios.get(
        `item-replace-page/${
          mpbastore.modalSelectedItemClassification.classificationId || -1
        }?&size=${mpbastore.rowsPerPage}&page=${mpbastore.pageNum}&name=${mpbastore.searchText}`
      );
    }
    mpbastore.setmodalItemList(response.data.content);
    mpbastore.settotalPage(response.data.totalPages);
  };
  const getMPBAList = async (isOneByOne) => {
    const response = await tokenAxios.get(`mpba-${isOneByOne ? 'one' : 'many'}/${params.id}`);
    mpbastore.setMPBAList(response.data.result.data.mpba);
    mpbastore.setPBAColumnList(response.data.result.data.pba);
    mpbastore.setmodeListByUser(response.data.result.data.mode);
    mpbastore.setMPBAName(response.data.result.data.name);
    mpbastore.setMPBANumber(response.data.result.data.number);
  };
  useEffect(() => {
    getClassificationTree();
  }, []);

  //  init on mode change
  useEffect(() => {
    closePBAController();
    mpbastore.setdefaultCheckedPBAColumnList([]);
    mpbastore.setisRouteActive(false);
    mpbastore.initTempVariables();
    getMPBAList(mpbastore.isOneByOne === '1');
  }, [mpbastore.isOneByOne, mpbastore.mode]);
  useEffect(() => {
    if (mpbastore.modalSelectedItemClassification.id) getmodalSelectedItemList(mpbastore.mode);
  }, [
    mpbastore.modalSelectedItemClassification,
    mpbastore.pageNum,
    mpbastore.rowsPerPage,
    mpbastore.searchText,
  ]);
  return (
    <MPBAPageStyle>
      {mpbastore.isModal && (
        <ModalBox
          isActivate={mpbastore.isModal}
          setisActivate={mpbastore.setisModal}
          onExit={exitModalCallBack}
        >
          <MPBAModal
            onCheckState={actionFunctionByModeObj[mpbastore.mode].onCheckModalItem}
            applyLv2={actionFunctionByModeObj[mpbastore.mode].applyModalLv2}
            applyLv3={actionFunctionByModeObj[mpbastore.mode].applyModalLv3}
            cancelFunc={() => mpbastore.setisModal(false)}
            onDeleteModalAddedItems={onDeleteModalAddedItems}
            onModalCountInputChange={onModalCountInputChange}
          />
        </ModalBox>
      )}
      <ScrollController
        width="100px"
        buttonArray={
          mpbastore.mode === '0'
            ? [
                [informationRef, 'BOM Information'],
                [mpbastore.routeRef, 'RouteInformation'],
              ]
            : [[informationRef, 'BOM Information']]
        }
        scrollRef={formRef}
      />
      <div className="gap" />

      <div className="mainPart" ref={formRef}>
        <div className="tableSection">
          <MPBASearchSection openMPBAModal={openMPBAModal} />
          <div className="tableFlex">
            <div className="indexContainer">
              <TableIndexRow
                width="28%"
                widthArray={[3, 3, 3, 5]}
                itemArray={['', 'Level', 'Ref No.', 'Part Number']}
                backgroundColor="white"
                cssStyle="flex-grow: 0;
                flex-shrink: 0;"
              />
              <div className="gap" />
              <div className="rightIndexContainer" ref={rightIndexHorizonScrollRef}>
                <div className="flex">
                  {mpbastore.PBAColumnList.map((item, i) => (
                    <IndexTitleBox
                      isHighLight={
                        pbaColumnActiveStatObj[mpbastore.mode].isHighLight && item.editable
                      }
                      key={i}
                    >
                      {pbaColumnActiveStatObj[mpbastore.mode].isCheckBox && item.editable ? (
                        <input
                          type="checkbox"
                          checked={
                            mpbastore.defaultCheckedPBAColumnList.findIndex(
                              (obj) => obj.id === item.id
                            ) > -1
                          }
                          onChange={() => checkPBAColumn(item)}
                        />
                      ) : (
                        <div className="blank" />
                      )}
                      <div className="titleBox" title={item.title}>
                        <a className="pbaLink" href={`/item/pba/detail/${item.id}`} target="blank">
                          {item.title}
                        </a>
                      </div>
                    </IndexTitleBox>
                  ))}
                  <TableIndexRow
                    backgroundColor="white"
                    width="40vw"
                    widthArray={[1, 1, 3, 2, 5]}
                    cssStyle="flex-shrink:0;min-width:40vw;"
                    itemArray={txt.mpba.mpbaModalIndex}
                  />
                </div>
              </div>
            </div>
            <div className="body" ref={tableBodyRef}>
              <div className="leftTable table">
                <div className="tableScroll leftTableScroll">
                  {mpbastore.MPBAList.map((partObject, i) => {
                    if (partObject.id === 'PBAAdd') {
                      return (
                        <PBAAddingBoxStyle key={i} scrollX={0}>
                          <TableIndexRow widthArray={[4, 4, 5]} itemArray={['', '', '']} />
                          <TableRow widthArray={[4, 4, 5]} itemArray={['', '', '']} />
                        </PBAAddingBoxStyle>
                      );
                    }
                    return (
                      <TableRow
                        key={i}
                        widthArray={[3, 3, 3, 5]}
                        itemArray={[
                          mpbastore.isOneByOne && partObject.deletable && mpbastore.mode === '1' ? (
                            <DeleteButton
                              onDelete={() => {
                                mpbastore.deleteMPBAList(partObject);
                              }}
                            />
                          ) : (
                            ''
                          ),
                          partObject.level,
                          partObject.ref,
                          partObject.partNumber,
                        ]}
                        backgroundColor={partObject.isYellow && 'var(--backgroundYellow)'}
                      />
                    );
                  })}
                </div>
              </div>

              <div className="gap" />
              <div
                className="rightTable table"
                ref={rightTableHorizonScrollRef}
                onScroll={onTableScroll(rightTableHorizonScrollRef, rightIndexHorizonScrollRef)}
              >
                <div className="rightTableContent">
                  <div className="tableScroll rightTableScroll">
                    <div className="columnContainer">
                      {mpbastore.PBAColumnList.map((columnObj, columnIndex) => {
                        return (
                          <PBAColumn
                            key={columnIndex}
                            isHighLight={
                              pbaColumnActiveStatObj[mpbastore.mode].isHighLight &&
                              columnObj.editable
                            }
                            childrenArray={columnObj.items.map((rowItem, rowIndex) => {
                              if (rowItem.id === 'PBAAdd') {
                                return '4';
                              }
                              return pbaColumnActiveStatObj[mpbastore.mode].isButton &&
                                mpbastore.isOneByOne === '1' &&
                                columnObj.editable &&
                                rowItem.type > 0 ? (
                                <GrayButton
                                  key={rowIndex}
                                  width="90%"
                                  height="40px"
                                  styleCss="border-radius:4px; font-weight:500;"
                                  onClick={() => {
                                    if (!mpbastore.isWriting) {
                                      onClickPBAColumnButton(rowItem, rowIndex);
                                    } else {
                                      alert('finish before');
                                    }
                                  }}
                                  title={
                                    rowItem.used === '-1'
                                      ? 'O'
                                      : rowItem.used === '-2'
                                        ? 'X'
                                        : rowItem.partNumber
                                  }
                                >
                                  {rowItem.used === '-1'
                                    ? 'O'
                                    : rowItem.used === '-2'
                                      ? 'X'
                                      : rowItem.partNumber}
                                </GrayButton>
                              ) : (
                                <div
                                  className="pbaChild"
                                  title={
                                    rowItem.used === '-1'
                                      ? 'O'
                                      : rowItem.used === '-2'
                                        ? 'X'
                                        : rowItem.partNumber
                                  }
                                >
                                  {rowItem.used === '-1'
                                    ? 'O'
                                    : rowItem.used === '-2'
                                      ? 'X'
                                      : rowItem.partNumber}
                                </div>
                              );
                            })}
                            title={columnObj.title}
                            yellowIndexArray={mpbastore.yellowIndexArray}
                          />
                        );
                      })}
                    </div>
                    <div className="tableBox">
                      {mpbastore.MPBAList.map((partObject, i) => {
                        if (partObject.id === 'PBAAdd') {
                          return (
                            <PBAAddingBoxStyle
                              key={i}
                              width="200%"
                              scrollX={righTableScrollX - mpbastore.PBAColumnList.length * 120 - 20}
                            >
                              <TableIndexRow widthArray={[1, 12]} itemArray={['', txt.mpba.edit]} />
                              <div className="controllerMain flex">
                                {mpbastore.targetPart.type * 1 === 1
                                  ? [
                                      {
                                        placeHolder: txt.mpba.use,
                                        value: '-1',
                                      },
                                      {
                                        placeHolder: txt.mpba.notUse,
                                        value: '-2',
                                      },
                                      {
                                        placeHolder: txt.mpba.pickOther,
                                        value: '0',
                                      },
                                    ]
                                  : [
                                      {
                                        placeHolder: txt.mpba.use,
                                        value: '-1',
                                      },
                                      {
                                        placeHolder: txt.mpba.notUse,
                                        value: '-2',
                                      },
                                    ].map((item, ind) => (
                                      <div className="radio" key={ind}>
                                        <input
                                          type="radio"
                                          id={item.value}
                                          name={item.value}
                                          value={item.value}
                                          onChange={(e) => {
                                            handleRadioButton(e.target.value);
                                          }}
                                          checked={mpbastore.newPBAOption === item.value}
                                        />
                                        <label htmlFor={item.value}>{item.placeHolder}</label>
                                      </div>
                                    ))}
                                {mpbastore.newPBAOption === '0' &&
                                mpbastore.targetPart.type * 1 === 1 ? (
                                  <LightGrayInputContainer
                                    height="30px"
                                    width="200px"
                                    onClick={onClickPBASearchButton}
                                    styleCss="cursor:pointer;background-color:white; .search{color:var(--textDarkGray);}"
                                  >
                                    <Input width="80%" state={mpbastore.newPBAPartNumber} />
                                    <div className="material-icons search">search</div>
                                  </LightGrayInputContainer>
                                ) : (
                                  <div className="blank" />
                                )}
                              </div>
                              <div className="cancelApply ">
                                <div className="cancel actionButton" onClick={cancelPBAController}>
                                  cancel
                                </div>
                                <div className="apply actionButton" onClick={applyPBAController}>
                                  apply
                                </div>
                              </div>
                            </PBAAddingBoxStyle>
                          );
                        }
                        return (
                          <TableRow
                            width="40vw"
                            cssStyle="flex-shrink:0;min-width:40vw;"
                            key={i}
                            widthArray={[1, 1, 3, 2, 5]}
                            itemArray={[
                              partObject.unit,
                              partObject.cnt,
                              partObject.classification, // obj로 오면 name붙이기
                              partObject.produceOrganizationId[0]?.code2,
                              partObject.produceOrganizationId[0]?.partNumber,
                            ]}
                            backgroundColor={partObject.isYellow && 'var(--backgroundYellow)'}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {mpbastore.mode === '0' && !mpbastore.isRouteActive && (
          <BlueButtonStyle
            height="36px"
            width="200px"
            onClick={defaultSaveButtonStat ? saveDefault : () => {}}
            css="justify-content:center; margin:0 auto;"
            backgroundColor={defaultSaveButtonStat ? 'var(--eciBlue)' : 'var(--disableGray)'}
          >
            Save & Open Route
          </BlueButtonStyle>
        )}
        {mpbastore.mode === '1' && (
          <BlueButtonStyle
            height="36px"
            width="200px"
            onClick={mpbaSaveButtonStat ? saveMPBA : () => {}}
            css="justify-content:center; margin:0 auto;"
            backgroundColor={mpbaSaveButtonStat ? 'var(--eciBlue)' : 'var(--disableGray)'}
          >
            Save
          </BlueButtonStyle>
        )}
        {mpbastore.mode === '2' && (
          <BlueButtonStyle
            height="36px"
            width="200px"
            onClick={pbaSaveButtonStat ? savePBA : () => {}}
            css="justify-content:center; margin:0 auto;"
            backgroundColor={pbaSaveButtonStat ? 'var(--eciBlue)' : 'var(--disableGray)'}
          >
            Save
          </BlueButtonStyle>
        )}
        <div className="routeBox">
          {mpbastore.isRouteActive && (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={mpbastore}
              multiRouteNumbers={mpbastore.defaultCheckedPBAColumnList.map((item) => item.routeId)}
            />
          )}
        </div>
      </div>
    </MPBAPageStyle>
  );
}
