import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ScrollDefault } from '../Style';

const ModalBoxStyle = styled.div`
  display: ${(props) => (props.isActivate ? '' : 'none')};
  width: 100vw;
  height: calc(100vh - 80px);
  margin: 0 auto;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 10;
  background-color: rgba(4, 8, 15, 0.5);

  .xButton {
    position: absolute;
    right: 40px;
    top: 10px;
    font-size: 30px;
    font-weight: 600;
    color: var(--textDarkGray);
    z-index: 10;
    cursor: pointer;
  }
  .container {
    margin: 40px auto;
    width: max-content;
    max-width: 90vw;
    height: max-content;
    max-height: 80vh;
    padding-right: 12px;

    ${ScrollDefault}
    ::-webkit-scrollbar-thumb {
      background: white;
    }
  }
`;
export default function ModalBox({
  isActivate,

  setisActivate,
  children,
  onExit = () => {},
}) {
  useEffect(() => {
    return () => {
      onExit();
    };
  }, []);
  return (
    <ModalBoxStyle
      isActivate={isActivate}
      onClick={() => {
        setisActivate(false);
        onExit();
      }}
    >
      <div
        className="container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="xButton"
          onClick={() => {
            setisActivate(false);
            onExit();
          }}
        >
          x
        </div>
        {children}
      </div>
    </ModalBoxStyle>
  );
}
