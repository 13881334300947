import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FaMinus, FaPlus } from 'react-icons/fa';
import { PageStyle, ListPageStyle, BlueButtonStyle } from '../../Style';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';

import PaginationTable from '../../molecule/PaginationTable';
import SearchBar from '../../molecule/SearchBar';
// eslint-disable-next-line import/no-unresolved
import DropDown from '../../atom/DropDown'; // Assuming you have a DropDown component

import ClassificationTreeSelector from '../../organism/ClassificationTreeSelector';
import StatusTag from '../molecule/StatusTag';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import usePageConditionList from '../../utility/hooks/usePageConditionList';
import { getTargetURI } from '../../utility/Utility';
import InsertButton from '../molecule/InsertButton';
import HistoryButton from '../../x-admin/molecule/HistoryButton';

const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  background-color: var(--eciBlue);
  color: white;
  cursor: pointer;
  &:hover {
    background-color: var(--eciBlueHover);
  }
`;
const ItemListPageStyle = styled(ListPageStyle)`
  display: flex;
  justify-content: space-between;
  .right {
    width: 78%;
  }
`;

const SearchSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const SearchAndCreateButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;

export default function ItemListPage() {
  /// ---CONSt
  const tokenAxios = useTokenAxios();
  const navigate = useNavigate();
  /// ---MODEL
  const [classificationObject, setclassificationObject] = useState({
    id: '',
    search: '',
    value: '',
  });
  const [treeData, settreeData] = useState([]);
  const [status, setStatus] = useState('all'); // New state for status filter

  const [expandedItems, setExpandedItems] = useState({});
  //  리스트 가져오기
  const [pageCondition, setpageCondition, itemListData] = usePageConditionList(
    'item-classification',
    'searchText',
    'classId',
    'status' // Add status to the list of conditions
  );

  /// ---ACTION
  const onInsert = () => {};
  /// ---VIEW
  const isInsertButton = classificationObject.classification?.indexOf('3/7') === 0;
  const isRevision = !(
    (
      classificationObject.classification?.indexOf('3/6') === 0 || // mech
      classificationObject.classification?.indexOf('3/7') === 0 || // etc
      classificationObject.classification?.indexOf('3/13') === 0 || // elec
      classificationObject.classification?.indexOf('4/') === 0 || // 부자재
      classificationObject.classification?.indexOf('5/') === 0
    ) // 소모재
  );

  const indexArray = [
    '',
    'Item Number',
    'Item Name',
    'Item Classification',
    '제조사',
    '제조사부품번호',
    '임시/정식',
    '비고',
    'Revision', // Added Revision column
    'Insert',
    '',
    'Status',
  ];

  const widthArray = [
    2,
    5,
    5,
    8,
    5,
    5,
    5,
    5,
    5, // Width for Revision column
    isInsertButton ? 5 : 0,
    isInsertButton ? 4 : 0,
    4,
  ];
  const indexWidthArray = [
    2,
    5,
    5,
    8,
    5,
    5,
    5,
    5,
    5, // Width for Revision column
    isInsertButton ? 5 : 0,
    isInsertButton ? 4 : 0,
    4,
  ];

  const toggleExpand = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const itemRowList = itemListData?.map((item, i) => {
    const manufacturer = item.produceOrganizationId[0]?.code1 || '';
    const manufacturerPartNumber = item.produceOrganizationId[0]?.partNumber || '';

    return (
      <React.Fragment key={i}>
        <TableRow
          key={i}
          onClick={(e) => {
            if (e.target.className === 'gridItem') {
              navigate(
                `/${getTargetURI(
                  classificationObject.value,
                  classificationObject.classification
                )}/detail/${item.id}?phase=4&classificationValue=${
                  classificationObject.classification
                }&classificationName=${classificationObject.value}&classId=${
                  classificationObject.classificationId
                }`
              );
            }
          }}
          itemArray={[
            item.anotherRevisionItems.length > 0 && (
              <ExpandButton onClick={() => toggleExpand(item.id)}>
                {expandedItems[item.id] ? <FaMinus /> : <FaPlus />}
              </ExpandButton>
            ),
            item.number,
            item.name,
            item.classification,
            manufacturer,
            manufacturerPartNumber,
            item.isTemp,
            item.note,
            item.revision, // Added Revision data
            <InsertButton data={item} active={!item.lock && item.status === 'COMPLETE'} />,
            <HistoryButton
              isLock={item.lock}
              historyList={item.lockReason?.map((reason, j) => ({
                lockReason: reason,
                lockTime: item.lockTime[j],
              }))}
            />,
            <StatusTag status={item.status} />,
          ]}
          widthArray={widthArray}
        />
        {expandedItems[item.id] &&
          item.anotherRevisionItems.map((revItem, j) => (
            <TableRow
              key={`${i}-${j}`}
              onClick={(e) => {
                if (e.target.className === 'gridItem') {
                  navigate(
                    `/${getTargetURI(
                      classificationObject.value,
                      classificationObject.classification
                    )}/detail/${revItem.id}?phase=4&classificationValue=${
                      classificationObject.classification
                    }&classificationName=${
                      classificationObject.value
                    }&classId=${classificationObject.classificationId}`
                  );
                }
              }}
              itemArray={[
                '',
                revItem.number,
                revItem.name,
                revItem.classification,
                manufacturer,
                manufacturerPartNumber,
                revItem.isTemp,
                revItem.note,
                revItem.revision,
                '',
                '',
                <StatusTag status={revItem.status} />,
              ]}
              widthArray={widthArray}
              backgroundColor="var(--textGray)"
            />
          ))}
      </React.Fragment>
    );
  });
  /// ---BL
  const getTreeData = async () => {
    const response = await tokenAxios.get('classification-tree');

    settreeData(response.data.result.data.filter((obj) => obj.name !== ' '));
  };
  useEffect(() => {
    setpageCondition('classId', classificationObject.classificationId);
  }, [classificationObject]);

  useEffect(() => {
    getTreeData();
  }, []);

  // Update pageCondition when status changes
  useEffect(() => {
    setpageCondition('status', status);
  }, [status]);

  return (
    <PageStyle>
      <ItemListPageStyle>
        <ClassificationTreeSelector
          treeData={treeData}
          width="220px"
          height="60vh + 10px"
          title="Classification"
          state={classificationObject}
          setstate={setclassificationObject}
        />
        <div className="right">
          <SearchAndCreateButtonWrapper>
            <SearchSection>
              <SearchBar
                width="280px"
                height="28px"
                placeholder="Search Items"
                state={pageCondition.searchText}
                setState={(val) => {
                  setpageCondition('searchText', val);
                }}
              />
              <div>
                Status :
                <DropDown
                  options={['all', 'WORKING', 'COMPLETE']}
                  value={status}
                  onChange={(val) => setStatus(val)}
                />
              </div>
            </SearchSection>
            {classificationObject.name &&
              classificationObject.name !== 'Master PBA' && ( // mpba의 경우 제외. 하드코딩
                <BlueButtonStyle
                  height="max-content"
                  width="200px"
                  css="padding:5px 10px"
                  onClick={() => {
                    navigate(
                      `/${getTargetURI(
                        classificationObject.value,
                        classificationObject.classification
                      )}/add/new?phase=1&classificationValue=${
                        classificationObject.classification
                      }&classificationName=${
                        classificationObject.value
                      }&classId=${classificationObject.classificationId}`
                    );
                  }}
                >
                  <span className="material-icons">add</span>
                  Create New {classificationObject.name}
                </BlueButtonStyle>
              )}
          </SearchAndCreateButtonWrapper>
          <PaginationTable
            width="100%"
            height="60vh"
            minHeight="300px"
            innerWidth="100%"
            totalPage={pageCondition.totalPage}
            rowsPerPageDefault={10}
            pageNum={pageCondition.pageNum}
            setRowsPerPage={(val) => {
              setpageCondition('rowsPerPage', val);
            }}
            rowsPerPage={pageCondition.rowsPerPage}
            setpageNum={(val) => {
              setpageCondition('pageNum', val);
            }}
            noshadow
          >
            <TableIndexRow itemArray={indexArray} widthArray={indexWidthArray} width="100%" />
            {itemRowList}
          </PaginationTable>
        </div>
      </ItemListPageStyle>
    </PageStyle>
  );
}
