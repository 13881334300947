import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import SignupModal from '../organism/SignupModal';
import { BlueButtonStyle } from '../../Style';
import TeamSearchUi from '../organism/TeamSearchUi';
import TableIndexRow from '../../atom/TableIndexRow';
import TableRow from '../../atom/TableRow';
import PaginationTable from '../../molecule/PaginationTable';
import useTokenAxios from '../../utility/hooks/useTokenAxios';
import ModalBox from '../../organism/ModalBox';
import SearchBar from '../../molecule/SearchBar';
import txt from '../../Text';

const MemberPageStyle = styled.div`
  .buttonSection {
    display: flex;
    justify-content: flex-end;
    height: 40px;
  }
  .con {
    display: flex;
    gap: 20px;
    .right {
      padding: 20px;
      padding-bottom: 0;
      width: 100%;
      background-color: white;
      border-radius: 8px;
    }
  }
`;
const NameLinkStyle = styled.div`
  text-decoration: underline;
  color: var(--eciBlue);
  font-weight: 600;
  cursor: pointer;
`;
export default function MemberPage() {
  // ---CONSt
  const navigate = useNavigate();
  const tokenAxios = useTokenAxios();
  // ---VIEW

  // ---MODEL
  const [isModal, setisModal] = useState(false);
  const [memberObj, setmemberObj] = useState({});
  const [isModalReadOnly, setisModalReadOnly] = useState(false);
  const [teamTreeData, setteamTreeData] = useState([]);
  const [targetTeamObj, settargetTeamObj] = useState({});
  // pagination
  const [totalPage, settotalPage] = useState('0');
  const [rowsPerPage, setrowsPerPage] = useState('10');
  const [pageNum, setpageNum] = useState('0');
  const [searchText, setsearchText] = useState('');
  const [memberListData, setmemberListData] = useState([]);

  // ---ACTION
  const openMember = (obj) => {
    setisModal(true);
    setmemberObj(obj);
    setisModalReadOnly(true);
  };
  // ---BL

  const getTeamTreeData = async () => {
    const response = await tokenAxios.get('departments');
    setteamTreeData(response.data.result.data);
  };
  useEffect(() => {
    getTeamTreeData();
  }, []);
  const getMemberListData = async () => {
    const response = await tokenAxios.get(
      `members/page?size=${rowsPerPage}&page=${pageNum}&name=${searchText}&keyWord=${targetTeamObj.classification}`
    );
    setmemberListData(response.data.contents || []);
    settotalPage(response.data.totalPages);
  };
  useEffect(() => {
    getMemberListData();
  }, [rowsPerPage, pageNum, searchText, targetTeamObj.classification]);
  return (
    <MemberPageStyle>
      {isModal && (
        <ModalBox isActivate={isModal} setisActivate={setisModal} onExit={() => setmemberObj('')}>
          <SignupModal readOnly={isModalReadOnly} memberObj={memberObj} setisModal={setisModal} />
        </ModalBox>
      )}
      <div className="buttonSection">
        <BlueButtonStyle
          width="200px"
          height="30px"
          css="padding:5px 10px"
          onClick={() => {
            setisModal(true);
            setisModalReadOnly(false);
          }}
        >
          <span className="material-icons">add</span>
          Add New Member
        </BlueButtonStyle>
      </div>

      <div className="con">
        <TeamSearchUi
          width="22%"
          height="58vh + 60px"
          teamTreeData={teamTreeData}
          state={targetTeamObj}
          setstate={settargetTeamObj}
        />
        <div className="right">
          <SearchBar
            state={searchText}
            setState={setsearchText}
            height="28px"
            width="200px"
            placeholder="Search Member"
          />
          <PaginationTable
            width="100%"
            minHeight="300px"
            height="58vh"
            totalPage={totalPage}
            rowsPerPageDefault={10}
            pageNum={pageNum}
            setRowsPerPage={setrowsPerPage}
            rowsPerPage={rowsPerPage}
            setpageNum={setpageNum}
            noshadow
          >
            <TableIndexRow widthArray={[3, 5, 5, 4, 6, 5]} itemArray={txt.admin.memberPageIndex} />
            {memberListData.map((obj, i) => (
              <TableRow
                key={i}
                widthArray={[3, 5, 5, 4, 6, 5]}
                itemArray={[
                  <NameLinkStyle
                    onClick={() => {
                      openMember(obj);
                    }}
                  >
                    {obj.username}
                  </NameLinkStyle>,
                  obj.department,
                  obj.position.join(','),
                  obj.admin ? 'Y' : 'N',
                  obj.email,
                  obj.createdAt,
                ]}
              />
            ))}
          </PaginationTable>
        </div>
      </div>
    </MemberPageStyle>
  );
}
